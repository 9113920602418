import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Clock, Book, User, Bell, Download, Printer, Info } from "lucide-react";

const classes = ["9", "10", "11", "12"];
const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const periods = ["1", "2", "3", "4", "5", "6", "7", "8"];

const subjects = {
  9: [
    "Mathematics",
    "Science",
    "English",
    "Hindi",
    "Social Studies",
    "Computer Science",
    "Physical Education",
    "Art",
  ],
  10: [
    "Mathematics",
    "Science",
    "English",
    "Hindi",
    "Social Studies",
    "Computer Science",
    "Physical Education",
    "Music",
  ],
  11: [
    "Physics",
    "Chemistry",
    "Mathematics",
    "English",
    "Computer Science",
    "Physical Education",
    "Economics",
    "Psychology",
  ],
  12: [
    "Physics",
    "Chemistry",
    "Mathematics",
    "English",
    "Computer Science",
    "Physical Education",
    "Biology",
    "Political Science",
  ],
};

const teachers = [
  "Dr. Sharma",
  "Mrs. Gupta",
  "Mr. Patel",
  "Ms. Singh",
  "Mr. Kumar",
  "Mrs. Reddy",
  "Dr. Joshi",
  "Mr. Verma",
  "Mrs. Desai",
  "Ms. Choudhury",
  "Mr. Banerjee",
  "Dr. Kapoor",
];

const generateTimeTable = (classNum) => {
  const timeTable = {};
  days.forEach((day) => {
    timeTable[day] = periods.map(() => {
      const subject =
        subjects[classNum][
          Math.floor(Math.random() * subjects[classNum].length)
        ];
      const teacher = teachers[Math.floor(Math.random() * teachers.length)];
      const room = `Room ${Math.floor(Math.random() * 20) + 101}`;
      return { subject, teacher, room };
    });
  });
  return timeTable;
};

const timeTables = {
  9: generateTimeTable("9"),
  10: generateTimeTable("10"),
  11: generateTimeTable("11"),
  12: generateTimeTable("12"),
};

const subjectColors = {
  Mathematics: "bg-red-100 text-red-800 border-red-200",
  Science: "bg-green-100 text-green-800 border-green-200",
  English: "bg-blue-100 text-blue-800 border-blue-200",
  Hindi: "bg-yellow-100 text-yellow-800 border-yellow-200",
  "Social Studies": "bg-purple-100 text-purple-800 border-purple-200",
  "Computer Science": "bg-indigo-100 text-indigo-800 border-indigo-200",
  "Physical Education": "bg-orange-100 text-orange-800 border-orange-200",
  Art: "bg-pink-100 text-pink-800 border-pink-200",
  Music: "bg-teal-100 text-teal-800 border-teal-200",
  Physics: "bg-cyan-100 text-cyan-800 border-cyan-200",
  Chemistry: "bg-lime-100 text-lime-800 border-lime-200",
  Biology: "bg-emerald-100 text-emerald-800 border-emerald-200",
  Economics: "bg-fuchsia-100 text-fuchsia-800 border-fuchsia-200",
  Psychology: "bg-rose-100 text-rose-800 border-rose-200",
  "Political Science": "bg-sky-100 text-sky-800 border-sky-200",
};

const TimeTable = () => {
  const [selectedClass, setSelectedClass] = useState("9");
  const [filterSubject, setFilterSubject] = useState("");
  const [filterTeacher, setFilterTeacher] = useState("");
  const [showLegend, setShowLegend] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Simulating notifications
    const notificationTimer = setInterval(() => {
      const newNotification = {
        id: Date.now(),
        message: `New update for Class ${
          Math.floor(Math.random() * 4) + 9
        } schedule`,
        time: new Date().toLocaleTimeString(),
      };
      setNotifications((prev) => [newNotification, ...prev.slice(0, 4)]);
    }, 30000);
    return () => clearInterval(notificationTimer);
  }, []);

  const filteredTimeTable = React.useMemo(() => {
    const timeTable = timeTables[selectedClass];
    if (!filterSubject && !filterTeacher) return timeTable;

    const filtered = {};
    Object.entries(timeTable).forEach(([day, periods]) => {
      filtered[day] = periods.filter(
        (period) =>
          (!filterSubject ||
            period.subject
              .toLowerCase()
              .includes(filterSubject.toLowerCase())) &&
          (!filterTeacher ||
            period.teacher.toLowerCase().includes(filterTeacher.toLowerCase()))
      );
    });
    return filtered;
  }, [selectedClass, filterSubject, filterTeacher]);

  const handlePrint = () => {
    window.print();
  };

  const handleDownload = () => {
    // Implement CSV download logic here
    console.log("Downloading CSV...");
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-pink-50 p-4">
      <div className="max-w-7xl mx-auto">
        {/* Header Section with improved mobile layout */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-800">
            Class Timetables
          </h1>
          <div className="flex flex-wrap items-center gap-3">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              className="bg-white rounded-full px-3 py-1.5 shadow-md text-gray-700 font-semibold text-sm sm:text-base"
            >
              <Clock className="inline-block mr-2 h-4 w-4 sm:h-5 sm:w-5 text-indigo-600" />
              {currentTime.toLocaleTimeString()}
            </motion.div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-indigo-600 text-white rounded-full px-3 py-1.5 text-sm sm:text-base shadow-md hover:bg-indigo-700 transition-colors duration-300"
              onClick={() => setShowLegend(!showLegend)}
            >
              {showLegend ? "Hide" : "Show"} Legend
            </motion.button>
          </div>
        </div>

        {/* Filter Section with improved mobile layout */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-6">
          <div className="p-4 sm:p-6 bg-gradient-to-r from-indigo-600 to-purple-600">
            <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
              {/* Class Selection Buttons */}
              <div className="flex flex-wrap gap-2">
                {classes.map((classNum) => (
                  <motion.button
                    key={classNum}
                    onClick={() => setSelectedClass(classNum)}
                    className={`px-3 py-1.5 rounded-full text-sm font-medium transition-all duration-300 ${
                      selectedClass === classNum
                        ? "bg-white text-indigo-600"
                        : "bg-indigo-500 text-white hover:bg-indigo-400"
                    }`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Class {classNum}
                  </motion.button>
                ))}
              </div>

              {/* Filter Inputs */}
              <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                <div className="relative flex-1 sm:flex-none">
                  <input
                    type="text"
                    placeholder="Filter by subject"
                    value={filterSubject}
                    onChange={(e) => setFilterSubject(e.target.value)}
                    className="w-full sm:w-auto !pl-9 !mb-0 pr-4 py-1.5 rounded-full bg-white/10 text-white placeholder-indigo-200 border border-indigo-400 focus:outline-none focus:ring-2 focus:ring-white transition-all duration-300 text-sm"
                  />
                  <Book className="absolute left-3 sm:top-2 top-3 h-4 w-4 text-indigo-200" />
                </div>
                <div className="relative flex-1 sm:flex-none">
                  <input
                    type="text"
                    placeholder="Filter by teacher"
                    value={filterTeacher}
                    onChange={(e) => setFilterTeacher(e.target.value)}
                    className="w-full sm:w-auto !pl-9 !mb-0 pr-4 py-1.5 rounded-full bg-white/10 text-white placeholder-indigo-200 border border-indigo-400 focus:outline-none focus:ring-2 focus:ring-white transition-all duration-300 text-sm"
                  />
                  <User className="absolute left-3 sm:top-2 top-3 h-4 w-4 text-indigo-200" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-6">
          <div className="lg:col-span-3">
            <AnimatePresence mode="wait">
              <motion.div
                key={selectedClass}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="bg-white rounded-2xl shadow-lg overflow-hidden"
              >
                <div className="p-6">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Class {selectedClass} Timetable
                  </h2>
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-50">
                          <th className="px-4 py-2 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider">
                            Day/Period
                          </th>
                          {periods.map((period) => (
                            <th
                              key={period}
                              className="px-4 py-2 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider"
                            >
                              Period {period}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {days.map((day) => (
                          <tr
                            key={day}
                            className="border-t border-gray-200 hover:bg-gray-50 transition-colors duration-300"
                          >
                            <td className="px-4 py-3 text-sm font-medium text-gray-900">
                              {day}
                            </td>
                            {filteredTimeTable[day].map((period, index) => (
                              <td key={index} className="px-4 py-3">
                                <motion.div
                                  initial={{ opacity: 0, scale: 0.9 }}
                                  animate={{ opacity: 1, scale: 1 }}
                                  transition={{
                                    duration: 0.3,
                                    delay: index * 0.05,
                                  }}
                                  className={`rounded-lg p-2 text-xs border ${
                                    subjectColors[period.subject]
                                  }`}
                                >
                                  <div className="font-semibold">
                                    {period.subject}
                                  </div>
                                  <div className="text-gray-600">
                                    {period.teacher}
                                  </div>
                                  <div className="text-gray-500 text-xs">
                                    {period.room}
                                  </div>
                                </motion.div>
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="bg-gray-50 px-6 py-4 flex justify-between items-center flex-col sm:flex-row gap-4 sm:gap-0">
                  <div className="text-sm text-gray-500">
                    Last updated: {new Date().toLocaleDateString()}{" "}
                    {new Date().toLocaleTimeString()}
                  </div>
                  <div className="flex space-x-2">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-indigo-100 text-indigo-600 rounded-full px-4 py-2 text-sm font-medium hover:bg-indigo-200 transition-colors duration-300 flex items-center"
                      onClick={handlePrint}
                    >
                      <Printer className="w-4 h-4 mr-2" />
                      Print
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-green-100 text-green-600 rounded-full px-4 py-2 text-sm font-medium hover:bg-green-200 transition-colors duration-300 flex items-center text-nowrap"
                      onClick={handleDownload}
                    >
                      <Download className="w-4 h-4 mr-2" />
                      Download CSV
                    </motion.button>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          <div className="lg:col-span-1">
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden"
            >
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                  <Bell className="w-5 h-5 mr-2 text-indigo-600" />
                  Notifications
                </h3>
                <div className="space-y-4">
                  {notifications.map((notification) => (
                    <motion.div
                      key={notification.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 }}
                      className="bg-indigo-50 rounded-lg p-3 text-sm"
                    >
                      <div className="font-medium text-indigo-800">
                        {notification.message}
                      </div>
                      <div className="text-indigo-600 text-xs mt-1">
                        {notification.time}
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        <AnimatePresence>
          {showLegend && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden mt-6"
            >
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                  <Info className="w-5 h-5 mr-2 text-indigo-600" />
                  Subject Legend
                </h3>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {subjects[selectedClass].map((subject, index) => (
                    <motion.div
                      key={subject}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.05 }}
                      className={`flex items-center space-x-2 p-2 rounded-lg ${subjectColors[subject]}`}
                    >
                      <div
                        className={`w-4 h-4 rounded-full bg-${subjectColors[
                          subject
                        ]
                          .split(" ")[0]
                          .replace("bg-", "")}-500`}
                      ></div>
                      <span className="text-sm">{subject}</span>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default TimeTable;
