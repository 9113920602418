import React, { useState } from 'react';
import "../cssTeacher/AssignmentsTeacher.css";

function AssignmentsTeacher() {

  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [studentCount, setStudentCount] = useState('');
  const [assignmentTopic, setAssignmentTopic] = useState('');

 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Assignment Submitted:", {
      class: selectedClass,
      section: selectedSection,
      students: studentCount,
      topic: assignmentTopic,
    });
    alert("Assignment submitted successfully!");

    setSelectedClass('');
    setSelectedSection('');
    setStudentCount('');
    setAssignmentTopic('');
  };

  return (
    <div className="assignments-teacher-container">
      <h2 className="title">Class Assignment Submission</h2>

      <form onSubmit={handleSubmit} className="assignment-form">
        <div className="form-group">
          <label htmlFor="classSelect">Class:</label>
          <select
            id="classSelect"
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
            required
          >
            <option value="">Select Class</option>
            <option value="10">Class 10</option>
            <option value="11">Class 11</option>
            <option value="12">Class 12</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="sectionSelect">Section:</label>
          <select
            id="sectionSelect"
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
            required
          >
            <option value="">Select Section</option>
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="studentCount">Number of Students:</label>
          <input
            type="number"
            id="studentCount"
            value={studentCount}
            onChange={(e) => setStudentCount(e.target.value)}
            required
            min="1"
          />
        </div>

        <div className="form-group">
          <label htmlFor="assignmentTopic">Assignment Topic:</label>
          <input
            type="text"
            id="assignmentTopic"
            value={assignmentTopic}
            onChange={(e) => setAssignmentTopic(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="submit-button">Submit Assignment</button>
      </form>
    </div>
  );
}

export default AssignmentsTeacher;
