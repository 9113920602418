export const initialStockItems = [
  {
    id: 1,
    name: "Notebooks",
    category: "Stationery",
    quantity: 500,
    reorderLevel: 100,
    lastRestocked: "2024-03-15",
    supplier: "Office Depot",
    price: 2.99,
  },
  {
    id: 2,
    name: "Pencils",
    category: "Stationery",
    quantity: 1000,
    reorderLevel: 200,
    lastRestocked: "2024-03-10",
    supplier: "Staples",
    price: 0.99,
  },
  {
    id: 3,
    name: "Whiteboards",
    category: "Classroom",
    quantity: 50,
    reorderLevel: 10,
    lastRestocked: "2024-02-20",
    supplier: "School Supplies Co.",
    price: 29.99,
  },
  {
    id: 4,
    name: "Laptops",
    category: "Electronics",
    quantity: 100,
    reorderLevel: 20,
    lastRestocked: "2024-01-01",
    supplier: "Dell",
    price: 899.99,
  },
  {
    id: 5,
    name: "Textbooks",
    category: "Books",
    quantity: 300,
    reorderLevel: 50,
    lastRestocked: "2024-03-01",
    supplier: "Pearson",
    price: 79.99,
  },
];
