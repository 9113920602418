import React, { useState } from 'react';

import "../../cssTeacher/AttendanceTeacher.css";

function StudentAttendanceByTeacher() {
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [students, setStudents] = useState([
    { id: 1, name: 'Student 1', present: true },
    { id: 2, name: 'Student 2', present: true },
    { id: 3, name: 'Student 3', present: true },
    { id: 4, name: 'Student 4', present: true },
  ]);

  const [allSelected, setAllSelected] = useState(true);


  const toggleStudentAttendance = (index) => {
    setStudents((prevStudents) =>
      prevStudents.map((student, i) =>
        i === index ? { ...student, present: !student.present } : student
      )
    );
  };


  const toggleAllAttendance = () => {
    setAllSelected(!allSelected);
    setStudents((prevStudents) =>
      prevStudents.map((student) => ({ ...student, present: !allSelected }))
    );
  };

 
  const handleStudentAttendanceSubmit = () => {
    alert('Student attendance submitted successfully');
  };

  return (
    <div className="student-attendance-teacher">
      <h2>Student Attendance</h2>
      <label>
        Class:
        <input
          type="text"
          value={selectedClass}
          onChange={(e) => setSelectedClass(e.target.value)}
          placeholder="Enter class"
        />
      </label>
      <label>
        Section:
        <input
          type="text"
          value={selectedSection}
          onChange={(e) => setSelectedSection(e.target.value)}
          placeholder="Enter section"
        />
      </label>

      <button onClick={toggleAllAttendance}>
        {allSelected ? "Deselect All" : "Select All"}
      </button>

      <table className="student-table">
        <thead>
          <tr>
            <th>Student Name</th>
            <th>Present</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr key={student.id}>
              <td>{student.name}</td>
              <td>
                <input
                  type="checkbox"
                  checked={student.present}
                  onChange={() => toggleStudentAttendance(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="submit-button" onClick={handleStudentAttendanceSubmit}>
        Submit Attendance
      </button>
    </div>
  );
}

export default StudentAttendanceByTeacher;
