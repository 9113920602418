import Home from "../pages/Home";
import About from "../pages/About";
import Academics from "../pages/Academics";
import Admissions from "../pages/Admissions";
import Curriculum from "../pages/Curriculum";
import Gallery from "../pages/Gallery";
import Infrastructure from "../pages/Infrastructure";
import Careers from "../pages/Careers";
import Contact from "../pages/Contact";
import Login from "../pages/Login/Login";
import StudentLogin from "../Components/StudentLogin";
import TeacherLogin from "../Components/TeacherLogin";
import AdminLogin from "../Components/AdminLogin";

export const mainRoutes = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/admissions", element: <Admissions /> },
  { path: "/academics", element: <Academics /> },
  { path: "/curriculum", element: <Curriculum /> },
  { path: "/gallery", element: <Gallery /> },
  { path: "/infrastructure", element: <Infrastructure /> },
  { path: "/careers", element: <Careers /> },
  { path: "/contact", element: <Contact /> },
  { path: "/login", element: <Login /> },
  { path: "/login/student", element: <StudentLogin /> },
  { path: "/login/teacher", element: <TeacherLogin /> },
  { path: "/login/admin", element: <AdminLogin /> },
];
