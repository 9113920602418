import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  Select,
} from '@chakra-ui/react';

const timetableData = {
  daily: [
    { time: '8:00 AM - 9:00 AM', subject: 'Mathematics', activity: 'Lecture' },
    { time: '9:00 AM - 10:00 AM', subject: 'Physics', activity: 'Lecture' },
    { time: '10:00 AM - 11:00 AM', subject: 'Chemistry', activity: 'Practical' },
    { time: '11:00 AM - 12:00 PM', subject: 'Computer Science', activity: 'Lecture' },
  ],
  weekly: [
    { day: 'Monday', subjects: ['Mathematics', 'Physics', 'Chemistry'] },
    { day: 'Tuesday', subjects: ['Computer Science', 'Mathematics', 'Biology'] },
    { day: 'Wednesday', subjects: ['Physics', 'Chemistry', 'Computer Science'] },
    { day: 'Thursday', subjects: ['Mathematics', 'Chemistry', 'Computer Science'] },
    { day: 'Friday', subjects: ['Physics', 'Biology', 'Computer Science'] },
  ],
  monthly: [
    { date: '1st September', subjects: ['Mathematics', 'Physics', 'Chemistry'] },
    { date: '5th September', subjects: ['Computer Science', 'Mathematics', 'Biology'] },
    { date: '10th September', subjects: ['Physics', 'Chemistry', 'Computer Science'] },
    { date: '15th September', subjects: ['Mathematics', 'Chemistry', 'Computer Science'] },
    { date: '20th September', subjects: ['Physics', 'Biology', 'Computer Science'] },
  ],
};

const TimetablePage = () => {
  const [view, setView] = useState('daily');
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const toast = useToast();

  const handleViewChange = (e) => {
    setView(e.target.value);
  };

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
    toast({
      title: 'Day Changed',
      description: `Showing timetable for ${e.target.value}`,
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
    toast({
      title: 'Month Changed',
      description: `Showing timetable for ${e.target.value}`,
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  // Call hooks at the top level (unconditionally)
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' }) || 'lg';
  const tableBgColor = useColorModeValue('gray.100', 'gray.700');
  const headingColor = useColorModeValue('teal.600', 'teal.300');

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading size={headingSize} textAlign="center" color={headingColor}>
          Student Timetable
        </Heading>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          p={5}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow="md"
        >
          <Select
            value={view}
            onChange={handleViewChange}
            mb={4}
            colorScheme="teal"
            variant="outline"
          >
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Select>

          {/* View Rendering */}
          {view === 'daily' && (
            <Box>
              <Heading size="md" color={headingColor}>
                Today's Timetable
              </Heading>
              <SimpleGrid columns={1} spacing={4} mt={4}>
                {timetableData?.daily?.map((item, index) => (
                  <Box
                    key={index}
                    borderWidth="1px"
                    borderRadius="md"
                    p={4}
                    bg={tableBgColor}
                  >
                    <Text fontSize="lg" fontWeight="bold">
                      {item?.time}
                    </Text>
                    <Text fontSize="md">{item?.subject}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {item?.activity}
                    </Text>
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          )}

          {view === 'weekly' && (
            <Box>
              <Heading size="md" color={headingColor}>
                Weekly Timetable
              </Heading>
              <Select
                placeholder="Select Day"
                onChange={handleDayChange}
                colorScheme="teal"
                mt={4}
              >
                {timetableData?.weekly?.map((item, index) => (
                  <option key={index} value={item?.day}>
                    {item?.day}
                  </option>
                ))}
              </Select>

              {selectedDay && (
                <SimpleGrid columns={1} spacing={4} mt={4}>
                  {timetableData?.weekly
                    ?.filter((item) => item?.day === selectedDay)
                    .map((item, index) => (
                      <Box
                        key={index}
                        borderWidth="1px"
                        borderRadius="md"
                        p={4}
                        bg={tableBgColor}
                      >
                        <Text fontSize="lg" fontWeight="bold">
                          {item?.day}
                        </Text>
                        {item?.subjects?.map((subject, idx) => (
                          <Text key={idx} fontSize="md">
                            {subject}
                          </Text>
                        ))}
                      </Box>
                    ))}
                </SimpleGrid>
              )}
            </Box>
          )}

          {view === 'monthly' && (
            <Box>
              <Heading size="md" color={headingColor}>
                Monthly Timetable
              </Heading>
              <Select
                placeholder="Select Month"
                onChange={handleMonthChange}
                colorScheme="teal"
                mt={4}
              >
                {['September', 'October', 'November', 'December'].map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </Select>

              {selectedMonth && (
                <SimpleGrid columns={1} spacing={4} mt={4}>
                  {timetableData?.monthly
                    ?.filter((item) => item?.date.includes(selectedMonth))
                    .map((item, index) => (
                      <Box
                        key={index}
                        borderWidth="1px"
                        borderRadius="md"
                        p={4}
                        bg={tableBgColor}
                      >
                        <Text fontSize="lg" fontWeight="bold">
                          {item?.date}
                        </Text>
                        {item?.subjects?.map((subject, idx) => (
                          <Text key={idx} fontSize="md">
                            {subject}
                          </Text>
                        ))}
                      </Box>
                    ))}
                </SimpleGrid>
              )}
            </Box>
          )}
        </Box>
      </VStack>
    </Container>
  );
};

export default TimetablePage;
