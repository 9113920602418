/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  FaSearch,
  FaFilter,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaEye,
  FaTimes,
  FaEnvelope,
  FaPhone,
  FaCalendarAlt,
  FaUser,
  FaGraduationCap,
  FaMapMarkerAlt,
} from "react-icons/fa";

export default function Component() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("all");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });

  const students = [
    {
      id: 1,
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeOlwb6YnVU55H4BNqtLm7T8b7thI-HiQBrg&s",
      name: "Aarav Sharma",
      guardianName: "Rajesh Sharma",
      email: "aarav.sharma@example.com",
      phone: "9876543210",
      address: "B-45, Sector 21",
      pin: "122018",
      cityOrVillage: "Gurgaon",
      state: "Haryana",
      gender: "male",
      bloodGroup: "A+",
      class: "10th Grade",
      admissionDate: "2024-03-15",
      status: "Active",
    },
    {
      id: 2,
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeOlwb6YnVU55H4BNqtLm7T8b7thI-HiQBrg&s",
      name: "Diya Patel",
      guardianName: "Anita Patel",
      email: "diya.patel@example.com",
      phone: "9876543211",
      address: "2nd Floor, 12B, M.G. Road",
      pin: "400002",
      cityOrVillage: "Mumbai",
      state: "Maharashtra",
      gender: "female",
      bloodGroup: "B+",
      class: "11th Grade",
      admissionDate: "2024-02-20",
      status: "Inactive",
    },
  ];

  const grades = [
    "All Grades",
    "9th Grade",
    "10th Grade",
    "11th Grade",
    "12th Grade",
  ];

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const filteredStudents = [...students]
    .filter((student) => {
      const matchesSearch =
        student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        student.email.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesClass =
        selectedClass === "all" || student.class === selectedClass;
      return matchesSearch && matchesClass;
    })
    .sort((a, b) => {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
      }
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    });

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) return <FaSort className="text-gray-300" />;
    return sortConfig.direction === "asc" ? (
      <FaSortUp className="text-purple-500" />
    ) : (
      <FaSortDown className="text-purple-500" />
    );
  };

  const handleStudentClick = (student) => {
    setSelectedStudent(student);
  };

  const closeProfileModal = () => {
    setSelectedStudent(null);
  };

  const getStatusColor = (status) => {
    return status === "Active"
      ? "bg-green-500"
      : status === "Inactive"
      ? "bg-red-500"
      : "bg-yellow-500";
  };

  const StudentProfileModal = ({ student, closeProfileModal }) => (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-40 p-4">
      <div className="relative bg-white rounded-2xl shadow-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="sm:text-2xl text-lg font-semibold text-gray-800">
            {student.name}&apos;s Profile
          </h2>
          <button
            onClick={closeProfileModal}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <FaTimes className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Left Column - Photo and Basic Info */}
            <div className="flex flex-col items-center space-y-4">
              <div className="relative">
                <img
                  src={student.photo}
                  alt={student.name}
                  className="w-32 h-32 rounded-full object-cover ring-4 ring-purple-100"
                />
                <div
                  className={`absolute bottom-2 right-2 w-4 h-4 rounded-full ${getStatusColor(
                    student.status
                  )} ring-2 ring-white`}
                  aria-label={`${student.status} status`}
                />
              </div>
              <h3 className="text-xl font-bold text-gray-800 text-center">
                {student.name}
              </h3>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
                {student.class}
              </span>
            </div>

            {/* Middle Column - Contact Details */}
            <div className="space-y-4 md:col-span-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <FaEnvelope className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Email</p>
                      <p className="text-sm font-medium">{student.email}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaPhone className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Phone</p>
                      <p className="text-sm font-medium">{student.phone}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaCalendarAlt className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Admission Date</p>
                      <p className="text-sm font-medium">
                        {new Date(student.admissionDate).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <FaUser className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">State</p>
                      <p className="text-sm font-medium">{student.state}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaGraduationCap className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Blood Group</p>
                      <p className="text-sm font-medium">
                        {student.bloodGroup}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaMapMarkerAlt className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Address</p>
                      <p className="text-sm font-medium">
                        {student.address}, {student.cityOrVillage},{" "}
                        {student.state}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="flex items-center gap-4 mb-4 md:mb-0">
            <h1 className="sm:text-2xl text-lg font-bold text-gray-800">
              Student Directory
            </h1>
            <span className="px-3 py-1 bg-purple-100 text-purple-800 rounded-full text-sm text-nowrap">
              {filteredStudents.length} Students
            </span>
          </div>

          {/* Search and Filter Section */}
          <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="text-gray-600" />
              </div>
              <input
                type="text"
                className="block w-full !mb-0 !pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:border-transparent placeholder:text-gray-700"
                required
                placeholder="Search Student..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="relative">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                <FaFilter className="text-gray-500" />
                <span>Filter</span>
              </button>

              {isFilterOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 z-10">
                  {grades.map((grade, index) => (
                    <button
                      key={index}
                      className={`w-full text-center px-4 py-2 hover:bg-gray-50 ${
                        (grade === "All Grades" ? "all" : grade) ===
                        selectedClass
                          ? "bg-purple-50 text-purple-600"
                          : "text-gray-700"
                      }`}
                      onClick={() => {
                        setSelectedClass(
                          grade === "All Grades" ? "all" : grade
                        );
                        setIsFilterOpen(false);
                      }}
                    >
                      {grade}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Table Section */}
        <div className="bg-white rounded-xl shadow-sm overflow-hidden p-0">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider w-12">
                    Photo
                  </th>
                  <th
                    className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    <div className="flex items-center justify-center gap-2">
                      Name
                      <SortIcon column="name" />
                    </div>
                  </th>
                  <th
                    className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("class")}
                  >
                    <div className="flex items-center justify-center gap-2">
                      Class
                      <SortIcon column="class" />
                    </div>
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider hidden sm:table-cell">
                    Contact
                  </th>
                  <th
                    className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer hidden sm:table-cell"
                    onClick={() => handleSort("status")}
                  >
                    <div className="flex items-center justify-center gap-2">
                      Status
                      <SortIcon column="status" />
                    </div>
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredStudents.map((student) => (
                  <tr key={student.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <img
                        className="h-10 w-10 rounded-full object-cover mx-auto"
                        src={student.photo}
                        alt={student.name}
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex flex-col items-center">
                        <div className="text-sm font-medium text-gray-900">
                          {student.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {student.email}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                        {student.class}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center hidden sm:table-cell">
                      <div className="text-sm text-gray-900">
                        {student.phone}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center hidden sm:table-cell">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          student.status === "Active"
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {student.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                      <button
                        onClick={() => handleStudentClick(student)}
                        className="text-purple-600 hover:text-purple-900"
                      >
                        <FaEye className="inline-block mr-1" /> View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedStudent && (
        <StudentProfileModal
          student={selectedStudent}
          closeProfileModal={closeProfileModal}
        />
      )}
    </div>
  );
}
