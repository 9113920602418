import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  AlertCircle,
  Users,
  Calendar,
  GraduationCap,
  Trophy,
  IndianRupee,
  Plus,
  X,
} from "lucide-react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Area,
  AreaChart,
} from "recharts";
import Sidebar from "./Sidebar";

const CircularProgress = ({ percentage, title, color }) => (
  <div className="flex flex-col items-center">
    <div className="relative w-24 h-24">
      <svg className="w-24 h-24 transform -rotate-90">
        <circle
          cx="48"
          cy="48"
          r="45"
          fill="none"
          stroke="#e5e7eb"
          strokeWidth="6"
        />
        <circle
          cx="48"
          cy="48"
          r="45"
          fill="none"
          stroke={color}
          strokeWidth="6"
          strokeDasharray={`${2 * Math.PI * 45}`}
          strokeDashoffset={`${2 * Math.PI * 45 * (1 - percentage / 100)}`}
          className="transition-all duration-1000 ease-in-out"
        />
      </svg>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <span className="text-xl font-bold text-indigo-900">{percentage}%</span>
      </div>
    </div>
    <span className="mt-2 text-xs sm:text-sm font-medium text-indigo-900 text-nowrap">
      {title}
    </span>
  </div>
);

const EventModal = ({ isOpen, onClose, onAdd }) => {
  const [eventData, setEventData] = useState({
    title: "",
    date: "",
    description: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(eventData);
    setEventData({ title: "", date: "", description: "" });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            className="bg-white rounded-lg p-6 w-full max-w-md"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold text-indigo-900">
                Add New Event
              </h3>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-indigo-900 mb-1">
                  Event Title
                </label>
                <input
                  type="text"
                  value={eventData.title}
                  onChange={(e) =>
                    setEventData({ ...eventData, title: e.target.value })
                  }
                  className="w-full p-2 border border-indigo-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-indigo-900 mb-1">
                  Date
                </label>
                <input
                  type="date"
                  value={eventData.date}
                  onChange={(e) =>
                    setEventData({ ...eventData, date: e.target.value })
                  }
                  className="w-full p-2 border border-indigo-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-indigo-900 mb-1">
                  Description
                </label>
                <textarea
                  value={eventData.description}
                  onChange={(e) =>
                    setEventData({ ...eventData, description: e.target.value })
                  }
                  className="w-full p-2 border border-indigo-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  rows="3"
                  required
                />
              </div>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-200"
              >
                Add Event
              </motion.button>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const Dashboard = () => {
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [events, setEvents] = useState([
    {
      id: 1,
      title: "Parent-Teacher Conference",
      date: "2024-10-28",
      description: "Annual meeting with parents",
    },
    {
      id: 2,
      title: "Science Fair",
      date: "2024-11-05",
      description: "Student projects exhibition",
    },
    {
      id: 3,
      title: "Sports Day",
      date: "2024-11-15",
      description: "Annual sports competition",
    },
  ]);

  const handleAddEvent = (newEvent) => {
    setEvents([...events, { ...newEvent, id: events.length + 1 }]);
  };

  const attendanceData = [
    { month: "Sep", students: 95, teachers: 98 },
    { month: "Oct", students: 97, teachers: 99 },
    { month: "Nov", students: 94, teachers: 97 },
    { month: "Dec", students: 93, teachers: 98 },
    { month: "Jan", students: 96, teachers: 99 },
    { month: "Feb", students: 90, teachers: 95 },
    { month: "Mar", students: 92, teachers: 96 },
    { month: "Apr", students: 95, teachers: 97 },
    { month: "May", students: 94, teachers: 98 },
  ];

  const dailyAttendance = [
    { day: "Mon", present: 1150, absent: 84, total: 1234 },
    { day: "Tue", present: 1180, absent: 54, total: 1234 },
    { day: "Wed", present: 1200, absent: 34, total: 1234 },
    { day: "Thu", present: 1160, absent: 74, total: 1234 },
    { day: "Fri", present: 1140, absent: 94, total: 1234 },
  ];

  const subjectProgress = [
    { name: "Mathematics", completed: 65 },
    { name: "Science", completed: 72 },
    { name: "English", completed: 58 },
    { name: "History", completed: 80 },
  ];

  return (
    <div className="flex min-h-screen pl-12 bg-gray-50">
      <Sidebar />

      <div className="flex-1 p-4 lg:p-8 space-y-6">
        {/* Header */}
        <div className="flex flex-col sm:flex-row justify-between items-center gap-4">
          <div>
            <h1 className="text-2xl sm:text-3xl font-bold text-indigo-900">
              Admin Dashboard
            </h1>
            <p className="text-sm text-indigo-600 mt-1">
              Welcome back, Administrator
            </p>
          </div>
          <div className="flex items-center gap-2 bg-white px-4 py-2 rounded-lg shadow-sm">
            <AlertCircle className="text-indigo-500" />
            <span className="text-sm text-indigo-600">
              Last updated: Today 9:00 AM
            </span>
          </div>
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 p-2 sm:p-0">
          {/* Total Students */}
          <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-xl shadow-xl p-6 text-white transform transition-transform hover:scale-105 duration-300">
            <div className="flex items-center">
              <Users className="h-10 w-10 mr-4" />
              <div>
                <p className="text-sm font-medium opacity-90">Total Students</p>
                <div className="flex items-baseline flex-col gap-1">
                  <h3 className="text-3xl font-bold">1,234</h3>
                  <span className="ml-2 text-sm opacity-75">
                    +5% this month
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Teachers */}
          <div className="bg-gradient-to-br from-purple-500 to-indigo-600 rounded-xl shadow-xl p-6 text-white transform transition-transform hover:scale-105 duration-300">
            <div className="flex items-center">
              <GraduationCap className="h-10 w-10 mr-4" />
              <div>
                <p className="text-sm font-medium opacity-90">Teachers</p>
                <div className="flex items-baseline flex-col gap-1">
                  <h3 className="text-3xl font-bold">98</h3>
                  <span className="ml-2 text-sm opacity-75">Full-time</span>
                </div>
              </div>
            </div>
          </div>

          {/* Fee Collection */}
          <div className="bg-gradient-to-br from-indigo-600 to-purple-500 rounded-xl shadow-xl p-6 text-white transform transition-transform hover:scale-105 duration-300">
            <div className="flex items-center">
              <IndianRupee className="h-10 w-10 mr-4" />
              <div>
                <p className="text-sm font-medium opacity-90">Fee Collection</p>
                <div className="flex items-baseline flex-col gap-1">
                  <h3 className="text-3xl font-bold">24.5M</h3>
                  <span className="ml-2 text-sm opacity-75">This Quarter</span>
                </div>
              </div>
            </div>
          </div>

          {/* Sports Events */}
          <div className="bg-gradient-to-br from-purple-600 to-indigo-500 rounded-xl shadow-xl p-6 text-white transform transition-transform hover:scale-105 duration-300">
            <div className="flex items-center">
              <Trophy className="h-10 w-10 mr-4" />
              <div>
                <p className="text-sm font-medium opacity-90">Sports Events</p>
                <div className="flex items-baseline flex-col gap-1">
                  <h3 className="text-3xl font-bold">12</h3>
                  <span className="ml-2 text-sm opacity-75">This Season</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* KPIs */}
        <div className="bg-white rounded-xl shadow-lg">
          <div className="p-6 border-b border-indigo-100">
            <h2 className="text-xl font-semibold text-indigo-900">
              Key Performance Indicators
            </h2>
          </div>
          <div className="sm:p-6 p-3">
            <div className="grid sm:grid-cols-4 grid-cols-2 sm:gap-8 gap-4">
              <CircularProgress
                percentage={85}
                title="Admission Rate"
                color="#6366f1"
              />
              <CircularProgress
                percentage={92}
                title="Fee Collection"
                color="#818cf8"
              />
              <CircularProgress
                percentage={78}
                title="Syllabus Completion"
                color="#6366f1"
              />
              <CircularProgress
                percentage={88}
                title="Sports Participation"
                color="#818cf8"
              />
            </div>
          </div>
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Monthly Attendance Chart */}
          <div className="bg-white rounded-xl shadow-lg">
            <div className="p-6 border-b border-indigo-100">
              <h2 className="text-xl font-semibold text-indigo-900">
                Monthly Attendance Trends
              </h2>
            </div>
            <div className="p-6">
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={attendanceData}>
                    <defs>
                      <linearGradient
                        id="colorStudents"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor="#6366f1"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#6366f1"
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                      <linearGradient
                        id="colorTeachers"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor="#818cf8"
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor="#818cf8"
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
                    <XAxis dataKey="month" />
                    <YAxis domain={[85, 100]} />
                    <Tooltip />
                    <Legend />
                    <Area
                      type="monotone"
                      dataKey="students"
                      stroke="#6366f1"
                      fill="url(#colorStudents)"
                      name="Students"
                    />
                    <Area
                      type="monotone"
                      dataKey="teachers"
                      stroke="#4c4ab3"
                      fill="url(#colorTeachers)"
                      name="Teachers"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Daily Attendance Chart */}
          <div className="bg-white rounded-xl shadow-lg">
            <div className="p-6 border-b border-indigo-100">
              <h2 className="text-xl font-semibold text-indigo-900">
                Daily Attendance Overview
              </h2>
            </div>
            <div className="p-6">
              <div className="h-80">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={dailyAttendance}>
                    <CartesianGrid strokeDasharray="3 3" opacity={0.3} />
                    <XAxis dataKey="day" />
                    <YAxis domain={[0, 1300]} />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="present" fill="#6366f1" name="Present" />
                    <Bar dataKey="absent" fill="#818cf8" name="Absent" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>

        {/* Subject Progress and Events Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Subject Progress */}
          <div className="bg-white rounded-xl shadow-lg">
            <div className="p-6 border-b border-indigo-100">
              <h2 className="text-xl font-semibold text-indigo-900">
                Subject Progress
              </h2>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                {subjectProgress.map((subject) => (
                  <div key={subject.name} className="space-y-2">
                    <div className="flex justify-between text-sm">
                      <span className="font-medium text-indigo-900">
                        {subject.name}
                      </span>
                      <span className="text-indigo-600">
                        {subject.completed}%
                      </span>
                    </div>
                    <div className="h-2 bg-indigo-100 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-indigo-600 rounded-full transition-all duration-500"
                        style={{ width: `${subject.completed}%` }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Upcoming Events */}
          <div className="bg-white rounded-xl shadow-lg">
            <div className="p-6 border-b border-indigo-100">
              <div className="flex justify-between items-center">
                <h2 className="text-lg sm:text-xl text-nowrap font-semibold text-indigo-900">
                  Upcoming Events
                </h2>
                <button
                  onClick={() => setIsEventModalOpen(true)}
                  className="flex items-center gap-2 text-nowrap bg-indigo-600 text-white sm:px-4 px-2 sm:py-2 py-1 rounded-lg hover:bg-indigo-700 transition-colors duration-200"
                >
                  <Plus className="h-4 w-4" />
                  <span>Add Event</span>
                </button>
              </div>
            </div>
            <div className="p-6">
              <div className="space-y-4">
                {events.map((event) => (
                  <div
                    key={event.id}
                    className="flex items-start gap-4 sm:p-4 p-2 bg-indigo-50 rounded-lg"
                  >
                    <Calendar className="sm:h-10  sm:w-10  text-indigo-600 flex-shrink-0" />
                    <div className="flex-1">
                      <h3 className="font-semibold text-indigo-900">
                        {event.title}
                      </h3>
                      <p className="text-sm text-indigo-600 mt-1">
                        {new Date(event.date).toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </p>
                      <p className="text-sm text-gray-600 mt-2">
                        {event.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Event Modal */}
        <EventModal
          isOpen={isEventModalOpen}
          onClose={() => setIsEventModalOpen(false)}
          onAdd={handleAddEvent}
        />
      </div>
    </div>
  );
};

export default Dashboard;
