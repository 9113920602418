import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  useColorModeValue,
  HStack,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const ExamFormPage = () => {
  const toast = useToast();

  // Sample data for subjects and their due dates
  const exams = [
    {
      id: 1,
      subject: 'Mathematics',
      dueDate: '2024-11-15', // Date format: YYYY-MM-DD
    },
    {
      id: 2,
      subject: 'Physics',
      dueDate: '2024-11-20',
    },
    {
      id: 3,
      subject: 'Chemistry',
      dueDate: '2024-11-25',
    },
    // Add more subjects as needed
  ];

  // Track form submission status
  const [formSubmitted, setFormSubmitted] = useState({});

  // Check if the form is disabled based on due date
  const isFormDisabled = (dueDate) => {
    const currentDate = new Date();
    const examDate = new Date(dueDate);
    return currentDate > examDate;
  };

  // Handle form submission
  const handleSubmit = (subjectId) => {
    setFormSubmitted((prev) => ({ ...prev, [subjectId]: true }));
    toast({
      title: 'Form Submitted',
      description: `You have successfully submitted the exam form for the subject.`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading size="lg" textAlign="center" color={useColorModeValue('teal.600', 'teal.300')}>
          Student Exam Forms
        </Heading>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          p={4}
          bg={useColorModeValue('white', 'gray.800')}
        >
          {exams.map((exam) => (
            <Box key={exam.id} mb={4} borderWidth="1px" borderRadius="lg" p={4} bg="gray.50">
              <Heading size="md">{exam.subject}</Heading>
              <Text fontSize="sm" color="gray.500">
                Due Date: {exam.dueDate}
              </Text>

              {/* Check if the form is disabled based on the due date */}
              {isFormDisabled(exam.dueDate) ? (
                <Text color="red.500" fontSize="lg" mt={2}>
                  Form is closed. The due date has passed.
                </Text>
              ) : (
                <VStack spacing={4} mt={4}>
                  {/* Form for subject */}
                  <FormControl id={`subject-${exam.id}`} isRequired>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <Input id="name" placeholder="Enter your name" />
                  </FormControl>

                  <FormControl id={`subject-${exam.id}-branch`} isRequired>
                    <FormLabel htmlFor="branch">Branch</FormLabel>
                    <Select id="branch" placeholder="Select your branch">
                      <option value="science">Science</option>
                      <option value="engineering">Engineering</option>
                      <option value="commerce">Commerce</option>
                    </Select>
                  </FormControl>

                  <HStack spacing={4}>
                    <Button
                      colorScheme="teal"
                      leftIcon={<CheckIcon />}
                      isFullWidth
                      isDisabled={formSubmitted[exam.id]}
                      onClick={() => handleSubmit(exam.id)}
                    >
                      {formSubmitted[exam.id] ? 'Form Submitted' : 'Submit Form'}
                    </Button>
                    <Button
                      colorScheme="gray"
                      variant="outline"
                      isFullWidth
                      isDisabled={formSubmitted[exam.id]}
                    >
                      {formSubmitted[exam.id] ? 'Already Submitted' : 'Cancel'}
                    </Button>
                  </HStack>
                </VStack>
              )}
            </Box>
          ))}
        </Box>
      </VStack>
    </Container>
  );
};

export default ExamFormPage;
