"use client";

import React, { useState } from "react";
import {
  Search,
  Download,
  ChevronDown,
  ChevronUp,
  BarChart2,
  Book,
  Award,
  Calendar,
  User,
  X,
} from "lucide-react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

// Sample data (you would fetch this from an API in a real application)
const initialStudents = [
  {
    id: "S001",
    name: "Aarav Patel",
    class: "10",
    rollNumber: "1001",
    overallGrade: "A",
    attendance: "95%",
    subjects: [
      { name: "Mathematics", grade: "A+", score: 95 },
      { name: "Science", grade: "A", score: 88 },
      { name: "English", grade: "B+", score: 82 },
      { name: "Hindi", grade: "A", score: 90 },
      { name: "Social Studies", grade: "A-", score: 85 },
    ],
    extracurricular: ["Debate Club", "Science Olympiad"],
    remarks: "Excellent student with strong analytical skills.",
    recentPerformance: [92, 88, 95, 89, 93],
    avatar: "/placeholder.svg?height=40&width=40",
  },
  {
    id: "S002",
    name: "Zara Khan",
    class: "11",
    rollNumber: "1102",
    overallGrade: "A-",
    attendance: "92%",
    subjects: [
      { name: "Physics", grade: "A", score: 89 },
      { name: "Chemistry", grade: "B+", score: 84 },
      { name: "Mathematics", grade: "A+", score: 96 },
      { name: "English", grade: "A-", score: 87 },
      { name: "Computer Science", grade: "A", score: 92 },
    ],
    extracurricular: ["Robotics Club", "Basketball Team"],
    remarks: "Talented in STEM subjects with good leadership skills.",
    recentPerformance: [87, 92, 88, 94, 90],
    avatar: "/placeholder.svg?height=40&width=40",
  },
  {
    id: "S003",
    name: "Arjun Reddy",
    class: "12",
    rollNumber: "1203",
    overallGrade: "B+",
    attendance: "88%",
    subjects: [
      { name: "Biology", grade: "A", score: 91 },
      { name: "Chemistry", grade: "B", score: 78 },
      { name: "Physics", grade: "B+", score: 83 },
      { name: "English", grade: "A-", score: 86 },
      { name: "Physical Education", grade: "A+", score: 95 },
    ],
    extracurricular: ["Cricket Team Captain", "Eco Club"],
    remarks: "Strong in sports and biology. Needs improvement in chemistry.",
    recentPerformance: [85, 79, 88, 92, 86],
    avatar: "/placeholder.svg?height=40&width=40",
  },
];

const StudentReport = () => {
  const [students, setStudents] = useState(initialStudents);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("All");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const [selectedStudent, setSelectedStudent] = useState(null);

  const classes = ["All", "9", "10", "11", "12"];

  const filteredStudents = students.filter((student) => {
    const matchesSearch =
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.rollNumber.includes(searchTerm);
    const matchesClass =
      selectedClass === "All" || student.class === selectedClass;
    return matchesSearch && matchesClass;
  });

  const sortedStudents = [...filteredStudents].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const handleStudentSelect = (student) => {
    setSelectedStudent(student);
  };

  const handleDownloadReport = (student) => {
    console.log(`Downloading report for ${student.name}`);
    alert(`Report for ${student.name} is being downloaded.`);
  };

  const calculateOverallProgress = (student) => {
    const totalScore = student.subjects.reduce(
      (sum, subject) => sum + subject.score,
      0
    );
    const averageScore = totalScore / student.subjects.length;
    return (averageScore / 100) * 100; // Convert to percentage
  };

  const classPerformanceData = ["9", "10", "11", "12"].map((cls) => {
    const classStudents = students.filter((student) => student.class === cls);
    const averagePerformance =
      classStudents.length > 0
        ? classStudents.reduce(
            (sum, student) => sum + calculateOverallProgress(student),
            0
          ) / classStudents.length
        : 0;
    return {
      class: `Class ${cls}`,
      performance: averagePerformance.toFixed(2),
    };
  });

  return (
    <div className="min-h-screen">
      <div className="max-w-7xl mx-auto">
        <div className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 p-4 md:p-6 mb-3">
          <h1 className="text-4xl font-bold text-white mb-8">
            Student Report Dashboard
          </h1>

          {/* Filters and Search */}
          <div>
            <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 md:space-x-4">
              <div className="relative w-full md:w-64 group">
                <input
                  type="text"
                  placeholder="Search by name or roll number..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full !pl-10 !mb-0 pr-4 py-2 text-sm rounded-full bg-white/10 text-white placeholder-indigo-200 border border-indigo-400 focus:outline-none focus:ring-2 focus:ring-white transition-all"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400 group-hover:text-indigo-500 transition-colors duration-300" />
              </div>
              <select
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
                className="px-4 py-2 text-sm rounded-full bg-white/10 text-white border border-indigo-400 focus:outline-none focus:ring-2 focus:ring-white transition-all w-full md:w-auto"
              >
                {classes.map((cls) => (
                  <option key={cls} value={cls} className="text-gray-900">
                    {cls === "All" ? "All Classes" : `Class ${cls}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Dashboard Summary */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <div className="bg-white rounded-2xl shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center">
              <BarChart2 className="h-12 w-12 text-indigo-500 mr-4" />
              <div>
                <p className="text-sm font-medium text-gray-500">
                  Total Students
                </p>
                <p className="text-3xl font-semibold text-gray-900">
                  {students.length}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center">
              <Book className="h-12 w-12 text-green-500 mr-4" />
              <div>
                <p className="text-sm font-medium text-gray-500">
                  Average Grade
                </p>
                <p className="text-3xl font-semibold text-gray-900">
                  {(
                    students.reduce(
                      (sum, student) =>
                        sum + (student.overallGrade.charCodeAt(0) - 65),
                      0
                    ) / students.length
                  ).toFixed(2)}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center">
              <Award className="h-12 w-12 text-yellow-500 mr-4" />
              <div>
                <p className="text-sm font-medium text-gray-500">
                  Top Performer
                </p>
                <p className="text-3xl font-semibold text-gray-900">
                  {
                    students.reduce((top, student) =>
                      student.overallGrade < top.overallGrade ? student : top
                    ).name
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center">
              <Calendar className="h-12 w-12 text-red-500 mr-4" />
              <div>
                <p className="text-sm font-medium text-gray-500">
                  Average Attendance
                </p>
                <p className="text-3xl font-semibold text-gray-900">
                  {(
                    students.reduce(
                      (sum, student) => sum + parseInt(student.attendance),
                      0
                    ) / students.length
                  ).toFixed(2)}
                  %
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Class-wise Performance Chart */}
        <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
          <h2 className="text-2xl font-semibold mb-6">
            Class-wise Performance
          </h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={classPerformanceData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="class" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="performance" fill="#4f46e5" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Student List */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-8 overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                {[
                  "Name",
                  "Class",
                  "Roll Number",
                  "Overall Grade",
                  "Attendance",
                  "Actions",
                ].map((header) => (
                  <th
                    key={header}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() =>
                      handleSort(header.toLowerCase().replace(" ", ""))
                    }
                  >
                    <div className="flex items-center">
                      {header}
                      {sortConfig.key ===
                        header.toLowerCase().replace(" ", "") &&
                        (sortConfig.direction === "ascending" ? (
                          <ChevronUp className="w-4 h-4 ml-1" />
                        ) : (
                          <ChevronDown className="w-4 h-4 ml-1" />
                        ))}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedStudents.map((student) => (
                <tr
                  key={student.id}
                  className="hover:bg-gray-50 transition-colors duration-300"
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <div className="h-10 w-10 rounded-full bg-gradient-to-r from-indigo-400 to-purple-500 flex items-center justify-center text-white font-bold text-lg">
                          {student.name.charAt(0)}
                        </div>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {student.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {student.class}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {student.rollNumber}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {student.overallGrade}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {student.attendance}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleStudentSelect(student)}
                      className="text-indigo-600 hover:text-indigo-900 mr-4 transition-colors duration-300"
                    >
                      View Details
                    </button>
                    <button
                      onClick={() => handleDownloadReport(student)}
                      className="text-green-600 hover:text-green-900 transition-colors duration-300"
                    >
                      <Download className="w-5 h-5 inline-block" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Student Details Modal */}
        {selectedStudent && (
          <div
            className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
            id="my-modal"
          >
            <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-2xl bg-white">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-semibold text-gray-900">
                  Student Details
                </h3>
                <button
                  onClick={() => setSelectedStudent(null)}
                  className="text-gray-400 hover:text-gray-500 transition-colors duration-300"
                >
                  <span className="sr-only">Close</span>
                  <X className="h-6 w-6" />
                </button>
              </div>
              <div className="mt-2">
                <div className="flex flex-col md:flex-row md:space-x-4">
                  <div className="md:w-1/3">
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                      <div className="flex items-center mb-2">
                        <div className="h-10 w-10 mr-4 rounded-full bg-gradient-to-r from-indigo-400 to-purple-500 flex items-center justify-center text-white font-bold text-lg">
                          {selectedStudent.name.charAt(0)}
                        </div>
                        <div>
                          <h4 className="font-semibold text-lg">
                            {selectedStudent.name}
                          </h4>
                          <p className="text-sm text-gray-600">
                            Class: {selectedStudent.class}
                          </p>
                        </div>
                      </div>
                      <p className="text-sm text-gray-600">
                        Roll Number: {selectedStudent.rollNumber}
                      </p>
                      <p className="text-sm text-gray-600">
                        Overall Grade: {selectedStudent.overallGrade}
                      </p>
                      <p className="text-sm text-gray-600">
                        Attendance: {selectedStudent.attendance}
                      </p>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-lg">
                      <h4 className="font-semibold mb-2">
                        Extracurricular Activities
                      </h4>
                      <ul className="list-disc list-inside">
                        {selectedStudent.extracurricular.map(
                          (activity, index) => (
                            <li key={index} className="text-sm text-gray-600">
                              {activity}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="md:w-2/3 mt-4 md:mt-0">
                    <h4 className="font-semibold mb-2">Subject Grades</h4>
                    <div className="bg-gray-100 p-4 rounded-lg mb-4">
                      {selectedStudent.subjects.map((subject, index) => (
                        <div key={index} className="mb-2">
                          <div className="flex justify-between items-center">
                            <span className="text-sm font-medium">
                              {subject.name}
                            </span>
                            <span className="text-sm font-semibold">
                              {subject.grade}
                            </span>
                          </div>
                          <div className="w-full bg-gray-200 rounded-full h-2.5">
                            <div
                              className="bg-indigo-600 h-2.5 rounded-full"
                              style={{ width: `${subject.score}%` }}
                            ></div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="mt-4">
                      <h4 className="font-semibold mb-2">Recent Performance</h4>
                      <div className="bg-gray-100 p-4 rounded-lg">
                        <ResponsiveContainer width="100%" height={200}>
                          <BarChart
                            data={selectedStudent.recentPerformance.map(
                              (score, index) => ({
                                name: `Test ${index + 1}`,
                                score,
                              })
                            )}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Bar dataKey="score" fill="#4f46e5" />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h4 className="font-semibold mb-2">Teacher's Remarks</h4>
                      <p className="text-sm text-gray-600 bg-gray-100 p-4 rounded-lg">
                        {selectedStudent.remarks}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button
                  onClick={() => handleDownloadReport(selectedStudent)}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm transition-colors duration-300"
                >
                  Download Full Report
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentReport;
