/* eslint-disable react/prop-types */
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  FaUser,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaPen,
  FaCity,
  FaFlag,
  FaVenusMars,
  FaBuilding,
  FaCalendarAlt,
} from "react-icons/fa";

const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
const classes = [
  "1st Grade",
  "2nd Grade",
  "3rd Grade",
  "4th Grade",
  "5th Grade",
  "6th Grade",
  "7th Grade",
  "8th Grade",
  "9th Grade",
  "10th Grade",
  "11th Grade",
  "12th Grade",
];

function StudentRegistration() {
  const [formData, setFormData] = useState({
    name: "",
    guardianName: "",
    email: "",
    mobile: "",
    address: "",
    pin: "",
    cityOrVillage: "",
    state: "",
    gender: "",
    bloodGroup: "",
    class: "",
    admissionDate: "",
    photo: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        photo: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.success("Registered Successfully");
    setFormData({
      name: "",
      guardianName: "",
      email: "",
      mobile: "",
      address: "",
      pin: "",
      cityOrVillage: "",
      state: "",
      gender: "",
      bloodGroup: "",
      class: "",
      admissionDate: "",
      photo: null,
    });
  };

  return (
    <div className="min-h-screen sm:py-8 p-0 sm:px-6 lg:px-8">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="max-w-5xl mx-auto">
        <div className="bg-white rounded-xl shadow-xl overflow-hidden">
          <Header />
          <Form
            formData={formData}
            handleChange={handleChange}
            handlePhotoChange={handlePhotoChange}
            handleSubmit={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}

const Header = () => (
  <div className="bg-gradient-to-r from-indigo-600 to-blue-500 px-6 py-4">
    <h2 className="sm:text-2xl text-lg font-bold text-white">
      Student Registration Form
    </h2>
    <p className="text-indigo-100 mt-1 !text-center sm:text-left sm:text-base text-sm">
      Please fill in the information below
    </p>
  </div>
);

const Form = ({ formData, handleChange, handlePhotoChange, handleSubmit }) => (
  <form onSubmit={handleSubmit} className="sm:px-6 px-3 py-6 sm:py-8">
    <PhotoUpload photo={formData.photo} handlePhotoChange={handlePhotoChange} />
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 sm:gap-6 gap-3">
      {renderInputFields(formData, handleChange)}
    </div>
    <SubmitButton />
  </form>
);

const PhotoUpload = ({ photo, handlePhotoChange }) => (
  <div className="mb-8 flex flex-col items-center">
    <div className="relative w-32 h-32 mb-4">
      <div className="w-full h-full rounded-full border-4 border-gray-200 overflow-hidden">
        {photo ? (
          <img
            src={photo}
            alt="Preview"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gray-100 flex items-center justify-center">
            <FaUser className="w-12 h-12 text-gray-400" />
          </div>
        )}
      </div>
      <label className="absolute bottom-0 right-0 bg-indigo-600 rounded-full p-2 cursor-pointer hover:bg-indigo-700 transition duration-200">
        <FaPen className="w-4 h-4 text-white" />
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handlePhotoChange}
        />
      </label>
    </div>
  </div>
);

const renderInputFields = (formData, handleChange) => {
  const inputFields = [
    { label: "Full Name", name: "name", type: "text", icon: <FaUser /> },
    {
      label: "Guardian Name",
      name: "guardianName",
      type: "text",
      icon: <FaUser />,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      icon: <FaEnvelope />,
    },
    { label: "Mobile Number", name: "mobile", type: "tel", icon: <FaPhone /> },
    {
      label: "Address",
      name: "address",
      type: "text",
      icon: <FaMapMarkerAlt />,
    },
    { label: "PIN Code", name: "pin", type: "text", icon: <FaMapMarkerAlt /> },
    {
      label: "City/Village",
      name: "cityOrVillage",
      type: "text",
      icon: <FaCity />,
    },
    { label: "State", name: "state", type: "text", icon: <FaFlag /> },
    {
      label: "Gender",
      name: "gender",
      type: "select",
      options: [
        { value: "", label: "Select Gender" },
        { value: "male", label: "Male" },
        { value: "female", label: "Female" },
        { value: "other", label: "Other" },
      ],
      icon: <FaVenusMars />,
    },
    {
      label: "Blood Group",
      name: "bloodGroup",
      type: "select",
      options: bloodGroups.map((group) => ({ value: group, label: group })),
      icon: <FaBuilding />,
    },
    {
      label: "Class",
      name: "class",
      type: "select",
      options: classes.map((className) => ({
        value: className,
        label: className,
      })),
      icon: <FaBuilding />,
    },
    {
      label: "Admission Date",
      name: "admissionDate",
      type: "date",
      icon: <FaCalendarAlt />,
    },
  ];

  return inputFields.map(({ label, name, type, options, icon }) => (
    <div className="relative" key={name}>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {icon}
        </div>
        {type === "select" ? (
          <select
            name={name}
            value={formData[name]}
            onChange={handleChange}
            className="block w-full !pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            required
          >
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        ) : (
          <input
            type={type}
            name={name}
            value={formData[name]}
            onChange={handleChange}
            className="block w-full !mb-0 !pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            required
          />
        )}
      </div>
    </div>
  ));
};

const SubmitButton = () => (
  <div className="mt-8">
    <button
      type="submit"
      className="w-full bg-gradient-to-r from-indigo-600 to-blue-500 text-white py-3 px-4 rounded-lg font-medium hover:from-indigo-700 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transform transition-all duration-200 hover:scale-[1.02]"
    >
      Register Student
    </button>
  </div>
);

export default StudentRegistration;
