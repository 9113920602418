import React, { useState } from 'react';
import "../cssTeacher/ExamTeachers.css";

function ExamTeachers() {
  const [pdfFile, setPdfFile] = useState(null);

 
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
    } else {
      alert("Please select a valid PDF file.");
    }
  };


  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (pdfFile) {
      alert(`PDF ${pdfFile.name} submitted successfully.`);
      setPdfFile(null); 
    } else {
      alert("Please select a PDF file to submit.");
    }
  };

  return (
    <div className="exam-teachers-container">
   
      <section className="notifications-section">
        <h2>Upcoming Exam Notifications</h2>
        <ul className="notifications-list">
          <li>Mathematics Exam on 20th Nov</li>
          <li>Science Test on 15th Nov</li>
          <li>Physics Paper Submission Due - 10th Nov</li>
        </ul>
      </section>


      <section className="pdf-submission-section">
        <h2>Create Question Paper</h2>
        <form onSubmit={handleFileSubmit}>
          <label>
            Upload PDF:
            <input
              type="file"
              accept="application/pdf"
              onChange={handleFileChange}
            />
          </label>
          <button type="submit" className="submit-button">Submit PDF</button>
        </form>
      </section>
    </div>
  );
}

export default ExamTeachers;
