/* eslint-disable react/prop-types */
import React, { useState, useMemo } from "react";
import { Search, Calendar, Users, UserCheck, UserX, Clock } from "lucide-react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

const initialData = [
  {
    id: 1,
    name: "Aarav Sharma",
    grade: "10th",
    section: "A",
    rollNo: "1001",
    date: "2024-10-28",
    status: "Present",
    checkInTime: "08:30 AM",
    checkOutTime: "03:30 PM",
    parentName: "Rajesh Sharma",
    contactNo: "+91 98765 43210",
  },
  {
    id: 2,
    name: "Diya Patel",
    grade: "10th",
    section: "A",
    rollNo: "1002",
    date: "2024-10-28",
    status: "Absent",
    checkInTime: "-",
    checkOutTime: "-",
    parentName: "Amit Patel",
    contactNo: "+91 98765 43211",
  },
  {
    id: 3,
    name: "Arjun Kumar",
    grade: "10th",
    section: "B",
    rollNo: "1003",
    date: "2024-10-28",
    status: "Late",
    checkInTime: "09:15 AM",
    checkOutTime: "03:30 PM",
    parentName: "Suresh Kumar",
    contactNo: "+91 98765 43212",
  },
  {
    id: 4,
    name: "Ananya Gupta",
    grade: "10th",
    section: "B",
    rollNo: "1004",
    date: "2024-10-28",
    status: "Present",
    checkInTime: "08:25 AM",
    checkOutTime: "03:30 PM",
    parentName: "Vikram Gupta",
    contactNo: "+91 98765 43213",
  },
  {
    id: 4,
    name: "Ananya Gupta",
    grade: "11th",
    section: "B",
    rollNo: "1005",
    date: "2024-10-28",
    status: "Present",
    checkInTime: "08:25 AM",
    checkOutTime: "03:30 PM",
    parentName: "Vikram Gupta",
    contactNo: "+91 98765 43213",
  },
  {
    id: 4,
    name: "Ananya Gupta",
    grade: "12th",
    section: "B",
    rollNo: "1006",
    date: "2024-10-28",
    status: "Present",
    checkInTime: "08:25 AM",
    checkOutTime: "03:30 PM",
    parentName: "Vikram Gupta",
    contactNo: "+91 98765 43213",
  },
];

const StudentAttendance = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [gradeFilter, setGradeFilter] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const stats = useMemo(() => {
    const total = initialData.length;
    const present = initialData.filter((s) => s.status === "Present").length;
    const absent = initialData.filter((s) => s.status === "Absent").length;
    const late = initialData.filter((s) => s.status === "Late").length;
    return {
      total,
      present,
      absent,
      late,
      presentPercentage: Math.round((present / total) * 100),
    };
  }, []);

  const filteredData = useMemo(() => {
    return initialData
      .filter((student) => {
        const matchesSearch =
          student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.rollNo.includes(searchTerm);
        const matchesGrade =
          gradeFilter === "all" || student.grade === gradeFilter;
        const matchesStatus =
          statusFilter === "all" || student.status === statusFilter;
        return matchesSearch && matchesGrade && matchesStatus;
      })
      .sort((a, b) => {
        if (sortConfig.direction === "asc") {
          return a[sortConfig.key].localeCompare(b[sortConfig.key]);
        }
        return b[sortConfig.key].localeCompare(a[sortConfig.key]);
      });
  }, [searchTerm, gradeFilter, statusFilter, sortConfig]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) return <FaSort className="text-gray-300" />;
    return sortConfig.direction === "asc" ? (
      <FaSortUp className="text-purple-500" />
    ) : (
      <FaSortDown className="text-purple-500" />
    );
  };

  const StatusBadge = ({ status }) => {
    const statusClasses = {
      Present: "bg-green-100 text-green-800",
      Absent: "bg-red-100 text-red-800",
      Late: "bg-yellow-100 text-yellow-800",
    };
    return (
      <span
        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusClasses[status]}`}
      >
        {status}
      </span>
    );
  };

  return (
    <div className="min-h-screen">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <h1 className=" text-xl sm:text-3xl font-semibold text-gray-900">
          Student Attendance Dashboard
        </h1>
        <p className="sm:mt-2 my-1 text-sm text-gray-600">
          Track and manage daily student attendance records
        </p>

        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
          <div className="bg-white rounded-lg shadow p-2 sm:p-6 flex items-center">
            <Users className="h-8 w-8 text-purple-500" />
            <div className="ml-4">
              <p className="sm:text-sm text-xs text-nowrap font-medium text-gray-600">
                Total Students
              </p>
              <p className="text-xl sm:text-2xl font-semibold text-gray-900 ml-6">
                {stats.total}
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow p-2 sm:p-6  flex items-center">
            <UserCheck className="h-8 w-8 text-green-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 text-nowrap">
                Present Today
              </p>
              <p className="text-xl sm:text-2xl font-semibold text-gray-900">
                {stats.present} ({stats.presentPercentage}%)
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow p-2 sm:p-6  flex items-center">
            <UserX className="h-8 w-8 text-red-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 text-nowrap">
                Absent Today
              </p>
              <p className="text-xl sm:text-2xl font-semibold text-gray-900 ml-6">
                {stats.absent}
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow p-2 sm:p-6  flex items-center">
            <Clock className="h-8 w-8 text-yellow-500" />
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 text-nowrap">
                Late Arrivals
              </p>
              <p className="text-xl sm:text-2xl font-semibold text-gray-900 ml-6">
                {stats.late}
              </p>
            </div>
          </div>
        </div>
        <div className="bg-white  rounded-xl shadow-sm w-72 sm:w-full">
          <div className=" p-6 mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="relative">
                <Search className="absolute left-3 top-3 text-gray-700 w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search....."
                  className="!pl-10 p-2 !mb-0 w-full border rounded-lg placeholder:text-gray-700"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="relative">
                <Calendar className="absolute left-3 top-3 text-gray-700 w-5 h-5" />
                <input
                  type="date"
                  className="!pl-10 p-2 !mb-0 w-full border rounded-lg"
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                />
              </div>
              <select
                className="p-2  border rounded-lg"
                value={gradeFilter}
                onChange={(e) => setGradeFilter(e.target.value)}
              >
                <option value="all">All Classes</option>
                <option value="9th">Class 9</option>
                <option value="10th">Class 10</option>
                <option value="11th">Class 11</option>
                <option value="12th">Class 12</option>
              </select>
              <select
                className="p-2 border rounded-lg"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="all">All Status</option>
                <option value="Present">Present</option>
                <option value="Absent">Absent</option>
                <option value="Late">Late</option>
              </select>
            </div>
          </div>
          <div className=" overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Roll No.",
                      "Name",
                      "Grade",
                      "Date",
                      "Status",
                      "Check-In",
                      "Check-Out",
                      "Parent Name",
                      "Contact No.",
                    ].map((col, idx) => (
                      <th
                        key={idx}
                        className="px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() =>
                          handleSort(
                            col.replace(/[^A-Za-z]/g, "").toLowerCase()
                          )
                        }
                      >
                        <div className="flex items-center">
                          {col}
                          <SortIcon
                            column={col.replace(/[^A-Za-z]/g, "").toLowerCase()}
                          />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredData.map((student) => (
                    <tr key={student.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {student.rollNo}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                        {student.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.grade}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.date}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {<StatusBadge status={student.status} />}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.checkInTime}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.checkOutTime}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.parentName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {student.contactNo}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentAttendance;
