import SidebarTeacher from "../dashboard/Teacher/SidebarTeacher";
import ProfileTeachers from "../dashboard/Teacher/DashbordTeacher/ProfileTeachers";
import AssignmentsTeacher from "../dashboard/Teacher/DashbordTeacher/AssinmentsTeacher";
import ReportsForTeachers from "../dashboard/Teacher/DashbordTeacher/ReportsForTeachers";
import SpecialClassofTeachers from "../dashboard/Teacher/DashbordTeacher/SpecialClassofTeachers";
import ActivitiofTeachers from "../dashboard/Teacher/DashbordTeacher/ActivitiofTeachers";
import AccountTeachers from "../dashboard/Teacher/DashbordTeacher/AccountTeachers";
import TeacherSelfAttendance from "../dashboard/Teacher/DashbordTeacher/AttendenceTeacher/TeacherSelfAttendance";
import StudentAttendanceByTeacher from "../dashboard/Teacher/DashbordTeacher/AttendenceTeacher/StudentAttendencebyTeacher";
import OnlineFees from "../dashboard/Teacher/DashbordTeacher/Feesofteachers/OnlineFees";
import OfflineFees from "../dashboard/Teacher/DashbordTeacher/Feesofteachers/OfflineFees";
import StaffManagementForTeachers from "../dashboard/Teacher/DashbordTeacher/StaffManagementForTeachers";
import LibraryTeachers from "../dashboard/Teacher/DashbordTeacher/LibraryTeachers";
import ExamTeachers from "../dashboard/Teacher/DashbordTeacher/ExamTeachers";

const TeacherLayout = ({ children }) => (
  <div className="flex">
    <SidebarTeacher />
    <div className="ml-59 p-9 w-full">{children}</div>
  </div>
);

export const teacherRoutes = [
  {
    path: "/login/teacher/teacher-dashboard",
    element: (
      <TeacherLayout>
        <ProfileTeachers />
      </TeacherLayout>
    ),
  },
  {
    path: "/ProfileTeacher",
    element: (
      <TeacherLayout>
        <ProfileTeachers />
      </TeacherLayout>
    ),
  },
  {
    path: "/SpecialClassofTeachers",
    element: (
      <TeacherLayout>
        <SpecialClassofTeachers />
      </TeacherLayout>
    ),
  },
  {
    path: "/ActivitiofTeachers",
    element: (
      <TeacherLayout>
        <ActivitiofTeachers />
      </TeacherLayout>
    ),
  },
  {
    path: "/Reportsofteachers",
    element: (
      <TeacherLayout>
        <ReportsForTeachers />
      </TeacherLayout>
    ),
  },
  {
    path: "/AssinmentsTeacher",
    element: (
      <TeacherLayout>
        <AssignmentsTeacher />
      </TeacherLayout>
    ),
  },
  {
    path: "/AccountTeachers",
    element: (
      <TeacherLayout>
        <AccountTeachers />
      </TeacherLayout>
    ),
  },
  {
    path: "/AttendanceTeacher/teacher",
    element: (
      <TeacherLayout>
        <TeacherSelfAttendance />
      </TeacherLayout>
    ),
  },
  {
    path: "/AttendanceTeacher/student",
    element: (
      <TeacherLayout>
        <StudentAttendanceByTeacher />
      </TeacherLayout>
    ),
  },
  {
    path: "/Fees/online",
    element: (
      <TeacherLayout>
        <OnlineFees />
      </TeacherLayout>
    ),
  },
  {
    path: "/Fees/offline",
    element: (
      <TeacherLayout>
        <OfflineFees />
      </TeacherLayout>
    ),
  },
  {
    path: "/LibraryTeachers",
    element: (
      <TeacherLayout>
        <LibraryTeachers />
      </TeacherLayout>
    ),
  },
  {
    path: "/ExamTeachers",
    element: (
      <TeacherLayout>
        <ExamTeachers />
      </TeacherLayout>
    ),
  },
  {
    path: "/StaffManagementforteachers",
    element: (
      <TeacherLayout>
        <StaffManagementForTeachers />
      </TeacherLayout>
    ),
  },
];
