import React from 'react';
import '../cssTeacher/SpecialClassofTeachers.css';

function SpecialClassofTeachers() {

  const classes = [
    { id: 1, name: "Drawing Class", description: "An interactive drawing class with creative activities." },
    { id: 2, name: "Headteacher", description: "The main supervising teacher responsible for all classes." },
    { id: 3, name: "Class Teacher", description: "In charge of managing classroom activities and daily lessons." },
    { id: 4, name: "Absent", description: "Teacher currently unavailable due to personal reasons or leave." },
    { id: 5, name: "Special Class", description: "Extra sessions for advanced learning and skill development." }
  ];

  return (
    <div className="special-class-container">
      <h2 className="special-class-heading">Special Classes for Teachers</h2>
      <div className="special-class-list">
        {classes.map((classItem) => (
          <div key={classItem.id} className="class-item">
            <h3 className="class-name">{classItem.name}</h3>
            <p className="class-description">{classItem.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SpecialClassofTeachers;
