

import React from "react";
import {
  Box,
  Container,
  Heading,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Text,
  Button,
  VStack,
  Progress,
  useColorModeValue,
} from "@chakra-ui/react";

const AttendancePage = () => {
  const attendanceData = [
    { course: "Mathematics", totalClasses: 30, attended: 28 },
    { course: "Physics", totalClasses: 25, attended: 20 },
    { course: "Chemistry", totalClasses: 28, attended: 22 },
    { course: "Biology", totalClasses: 30, attended: 29 },
  ];

  const getAttendancePercentage = (attended, totalClasses) => {
    return ((attended / totalClasses) * 100).toFixed(2);
  };

  return (
    <Container maxW="container.md" py={8} px={[4, 8, 12]}>
      <VStack spacing={5} align="stretch">
        <Heading
          size="lg"
          textAlign="center"
          color={useColorModeValue("teal.600", "teal.300")}
        >
          Student Attendance
        </Heading>

        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" p={4}>
          <Box overflowX="auto">
            <Table
              variant="striped"
              colorScheme="teal"
              size="sm"
              css={{
                "@media (max-width: 600px)": {
                  "th, td": { padding: "0.5rem" },
                  th: { fontSize: "0.85rem" },
                  td: { fontSize: "0.8rem" },
                },
                "@media (max-width: 400px)": {
                  "th, td": { fontSize: "0.7rem" },
                  th: { fontWeight: "bold" },
                },
              }}
            >
              <Thead>
                <Tr>
                  <Th>Course</Th>
                  <Th>Total Classes</Th>
                  <Th>Attended</Th>
                  <Th>Attendance %</Th>
                  <Th>Progress</Th>
                </Tr>
              </Thead>
              <tbody>
                {attendanceData.map((data, index) => (
                  <Tr key={index}>
                    <Td>{data.course}</Td>
                    <Td>{data.totalClasses}</Td>
                    <Td>{data.attended}</Td>
                    <Td>
                      {getAttendancePercentage(
                        data.attended,
                        data.totalClasses
                      )}
                      %
                    </Td>
                    <Td>
                      <Progress
                        value={getAttendancePercentage(
                          data.attended,
                          data.totalClasses
                        )}
                        colorScheme="teal"
                        size="sm"
                        borderRadius="md"
                        width="100%"
                      />
                    </Td>
                  </Tr>
                ))}
              </tbody>
            </Table>
          </Box>
        </Box>

        <Button
          colorScheme="teal"
          variant="solid"
          size="lg"
          width={["100%", "auto"]}
          alignSelf="center"
        >
          Download Attendance Report
        </Button>
      </VStack>
    </Container>
  );
};

export default AttendancePage;
