import React, { useState } from "react";
import {
  Search,
  Filter,
  CheckCircle,
  Clock,
  AlertCircle,
  Edit,
  Trash2,
  Plus,
  X,
  Calendar,
  BookOpen,
  User,
  FileText,
} from "lucide-react";

// Sample assignment data
const assignmentData = [
  {
    id: "ASG001",
    title: "Data Structures Implementation",
    subject: "Computer Science",
    class: "12th",
    section: "A",
    assignedDate: "2024-04-15",
    dueDate: "2024-04-25",
    status: "pending",
    type: "Programming",
    description:
      "Implement a binary search tree with insertion, deletion, and traversal operations",
    maxMarks: 50,
    teacher: "Dr. Sarah Wilson",
    submittedCount: 15,
    totalStudents: 30,
  },
  {
    id: "ASG002",
    title: "Literary Analysis Essay",
    subject: "English Literature",
    class: "11th",
    section: "B",
    assignedDate: "2024-04-16",
    dueDate: "2024-04-28",
    status: "completed",
    type: "Essay",
    description:
      "Analyze the themes of power and corruption in Shakespeare's Macbeth",
    maxMarks: 100,
    teacher: "Ms. Emily Parker",
    submittedCount: 28,
    totalStudents: 28,
  },
  {
    id: "ASG003",
    title: "Physics Lab Report",
    subject: "Physics",
    class: "12th",
    section: "A",
    assignedDate: "2024-04-14",
    dueDate: "2024-04-22",
    status: "overdue",
    type: "Lab Report",
    description:
      "Write a detailed lab report on the Simple Pendulum experiment",
    maxMarks: 75,
    teacher: "Dr. Robert Chen",
    submittedCount: 22,
    totalStudents: 30,
  },
];

export default function AssignmentManager() {
  const [assignments, setAssignments] = useState(assignmentData);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("all");
  const [showFilters, setShowFilters] = useState(false);
  const [sortBy, setSortBy] = useState("dueDate");
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("view"); // 'view', 'edit', or 'create'

  // Get unique subjects
  const subjects = ["all", ...new Set(assignments.map((a) => a.subject))];

  // Filter and sort assignments
  const filteredAssignments = assignments
    .filter((assignment) => {
      const matchesSearch =
        assignment.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        assignment.subject.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesStatus =
        filterStatus === "all" || assignment.status === filterStatus;
      const matchesSubject =
        selectedSubject === "all" || assignment.subject === selectedSubject;
      return matchesSearch && matchesStatus && matchesSubject;
    })
    .sort((a, b) => {
      if (sortBy === "dueDate") {
        return new Date(a.dueDate) - new Date(b.dueDate);
      }
      return a.title.localeCompare(b.title);
    });

  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "bg-green-100 text-green-800";
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      case "overdue":
        return "bg-red-100 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "completed":
        return <CheckCircle className="w-4 h-4" />;
      case "pending":
        return <Clock className="w-4 h-4" />;
      case "overdue":
        return <AlertCircle className="w-4 h-4" />;
      default:
        return null;
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleCreateAssignment = () => {
    setModalMode("create");
    setSelectedAssignment({
      id: `ASG${assignments.length + 1}`.padStart(6, "0"),
      title: "",
      subject: "",
      class: "",
      section: "",
      assignedDate: new Date().toISOString().split("T")[0],
      dueDate: "",
      status: "pending",
      type: "",
      description: "",
      maxMarks: 0,
      teacher: "",
      submittedCount: 0,
      totalStudents: 0,
    });
    setShowModal(true);
  };

  const handleEditAssignment = (assignment) => {
    setModalMode("edit");
    setSelectedAssignment(assignment);
    setShowModal(true);
  };

  const handleDeleteAssignment = (id) => {
    setAssignments(assignments.filter((assignment) => assignment.id !== id));
  };

  const handleSaveAssignment = (updatedAssignment) => {
    if (modalMode === "create") {
      setAssignments([...assignments, updatedAssignment]);
    } else {
      setAssignments(
        assignments.map((assignment) =>
          assignment.id === updatedAssignment.id
            ? updatedAssignment
            : assignment
        )
      );
    }
    setShowModal(false);
  };

  return (
    <div className="min-h-screen p-4 md:p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-6">
          <div className="bg-gradient-to-r from-purple-600 to-indigo-600 p-6">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
              <div className="text-white">
                <h1 className="text-2xl md:text-3xl font-bold">
                  Assignment Dashboard
                </h1>
                <p className="mt-1 text-purple-100">
                  Manage and track student assignments
                </p>
              </div>

              {/* Desktop Search and Create */}
              <div className="hidden md:flex items-center gap-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search assignments..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="!pl-10 !mb-0 pr-4 py-2 rounded-lg bg-white/10 text-white placeholder-purple-200 border border-purple-400 focus:outline-none focus:ring-2 focus:ring-white focus:border-transparent w-64"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-purple-200" />
                </div>
                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className="flex items-center px-4 py-2 rounded-lg bg-white/10 text-white border border-purple-400 hover:bg-white/20 transition-colors"
                >
                  <Filter className="w-5 h-5 mr-2" />
                  Filters
                </button>
                <button
                  onClick={handleCreateAssignment}
                  className="flex items-center px-4 py-2 rounded-lg bg-white text-purple-600 hover:bg-purple-50 transition-colors"
                >
                  <Plus className="w-5 h-5 mr-2" />
                  Create Assignment
                </button>
              </div>

              {/* Mobile Search Button */}
              <button
                onClick={() => setShowFilters(!showFilters)}
                className="md:hidden w-full px-4 py-2 rounded-lg bg-white/10 text-white border border-purple-400"
              >
                <Search className="w-5 h-5 mr-2 inline-block" />
                Search & Filter
              </button>
            </div>
          </div>

          {/* Filters Panel */}
          {showFilters && (
            <div className="p-4 border-t border-gray-200 bg-white">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Status
                  </label>
                  <select
                    value={filterStatus}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  >
                    <option value="all">All Status</option>
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                    <option value="overdue">Overdue</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Subject
                  </label>
                  <select
                    value={selectedSubject}
                    onChange={(e) => setSelectedSubject(e.target.value)}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  >
                    {subjects.map((subject) => (
                      <option key={subject} value={subject}>
                        {subject.charAt(0).toUpperCase() + subject.slice(1)}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Sort By
                  </label>
                  <select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  >
                    <option value="dueDate">Due Date</option>
                    <option value="title">Title</option>
                  </select>
                </div>
                <div className="md:hidden">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Search
                  </label>
                  <input
                    type="text"
                    placeholder="Search assignments..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Assignments Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:pb-0 pb-20">
          {filteredAssignments.map((assignment) => (
            <div
              key={assignment.id}
              className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow"
            >
              <div className="p-6">
                <div className="flex justify-between items-start mb-4">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-900 mb-1">
                      {assignment.title}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {assignment.subject}
                    </p>
                  </div>
                  <span
                    className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${getStatusColor(
                      assignment.status
                    )}`}
                  >
                    {getStatusIcon(assignment.status)}
                    <span className="ml-1 capitalize">{assignment.status}</span>
                  </span>
                </div>

                <div className="space-y-3">
                  <div className="flex items-center text-sm text-gray-500">
                    <Calendar className="w-4 h-4 mr-2" />
                    <span>Due: {formatDate(assignment.dueDate)}</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500">
                    <BookOpen className="w-4 h-4 mr-2" />
                    <span>
                      {assignment.class} Class - Section {assignment.section}
                    </span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500">
                    <User className="w-4 h-4 mr-2" />
                    <span>{assignment.teacher}</span>
                  </div>
                  <div className="flex items-center text-sm text-gray-500">
                    <FileText className="w-4 h-4 mr-2" />
                    <span>
                      Submitted: {assignment.submittedCount}/
                      {assignment.totalStudents}
                    </span>
                  </div>
                </div>

                <div className="mt-4 flex justify-between">
                  <button
                    onClick={() => {
                      setModalMode("view");
                      setSelectedAssignment(assignment);
                      setShowModal(true);
                    }}
                    className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                  >
                    View Details
                  </button>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditAssignment(assignment)}
                      className="p-2 text-purple-600 hover:bg-purple-50 rounded-lg transition-colors"
                    >
                      <Edit className="w-5 h-5" />
                    </button>
                    <button
                      onClick={() => handleDeleteAssignment(assignment.id)}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                    >
                      <Trash2 className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* No Assignments Message */}
        {filteredAssignments.length === 0 && (
          <div className="text-center py-12">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-purple-100 mb-4">
              <BookOpen className="w-8 h-8 text-purple-600" />
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              No Assignments Found
            </h3>
            <p className="text-gray-500">
              Try adjusting your search or filter criteria
            </p>
          </div>
        )}

        {/* Assignment Modal */}
        {showModal && selectedAssignment && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
              <div className="p-6">
                <div className="flex justify-between items-start mb-6">
                  <h2 className="text-2xl font-bold text-gray-900">
                    {modalMode === "create"
                      ? "Create New Assignment"
                      : modalMode === "edit"
                      ? "Edit Assignment"
                      : selectedAssignment.title}
                  </h2>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>

                <div className="space-y-6">
                  {modalMode === "view" ? (
                    // View mode
                    <>
                      <div className="flex flex-wrap gap-4">
                        <span
                          className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(
                            selectedAssignment.status
                          )}`}
                        >
                          {getStatusIcon(selectedAssignment.status)}
                          <span className="ml-1 capitalize">
                            {selectedAssignment.status}
                          </span>
                        </span>
                        <span className="inline-flex items-center text-sm text-gray-500">
                          <Calendar className="w-4 h-4 mr-2" />
                          Due: {formatDate(selectedAssignment.dueDate)}
                        </span>
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <h4 className="text-sm font-medium text-gray-700 mb-2">
                            Teacher
                          </h4>
                          <p className="text-gray-900">
                            {selectedAssignment.teacher}
                          </p>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg">
                          <h4 className="text-sm font-medium text-gray-700 mb-2">
                            Class Details
                          </h4>
                          <p className="text-gray-900">
                            {selectedAssignment.class} - Section{" "}
                            {selectedAssignment.section}
                          </p>
                        </div>
                      </div>

                      <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-2">
                          Description
                        </h3>
                        <p className="text-gray-600">
                          {selectedAssignment.description}
                        </p>
                      </div>

                      <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-2">
                          Submission Details
                        </h3>
                        <div className="bg-gray-50 p-4 rounded-lg">
                          <div className="mb-4">
                            <h4 className="text-sm font-medium text-gray-700 mb-1">
                              Maximum Marks
                            </h4>
                            <p className="text-gray-900">
                              {selectedAssignment.maxMarks} points
                            </p>
                          </div>
                          <div>
                            <h4 className="text-sm font-medium text-gray-700 mb-1">
                              Submission Status
                            </h4>
                            <p className="text-gray-900">
                              {selectedAssignment.submittedCount} out of{" "}
                              {selectedAssignment.totalStudents} submitted
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    // Edit/Create mode
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      className="space-y-4"
                    >
                      <div>
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          id="title"
                          value={selectedAssignment.title}
                          onChange={(e) =>
                            setSelectedAssignment({
                              ...selectedAssignment,
                              title: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="subject"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          id="subject"
                          value={selectedAssignment.subject}
                          onChange={(e) =>
                            setSelectedAssignment({
                              ...selectedAssignment,
                              subject: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="class"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Class
                          </label>
                          <input
                            type="text"
                            id="class"
                            value={selectedAssignment.class}
                            onChange={(e) =>
                              setSelectedAssignment({
                                ...selectedAssignment,
                                class: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="section"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Section
                          </label>
                          <input
                            type="text"
                            id="section"
                            value={selectedAssignment.section}
                            onChange={(e) =>
                              setSelectedAssignment({
                                ...selectedAssignment,
                                section: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="assignedDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Assigned Date
                          </label>
                          <input
                            type="date"
                            id="assignedDate"
                            value={selectedAssignment.assignedDate}
                            onChange={(e) =>
                              setSelectedAssignment({
                                ...selectedAssignment,
                                assignedDate: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="dueDate"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Due Date
                          </label>
                          <input
                            type="date"
                            id="dueDate"
                            value={selectedAssignment.dueDate}
                            onChange={(e) =>
                              setSelectedAssignment({
                                ...selectedAssignment,
                                dueDate: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <textarea
                          id="description"
                          rows={3}
                          value={selectedAssignment.description}
                          onChange={(e) =>
                            setSelectedAssignment({
                              ...selectedAssignment,
                              description: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="maxMarks"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Maximum Marks
                          </label>
                          <input
                            type="number"
                            id="maxMarks"
                            value={selectedAssignment.maxMarks}
                            onChange={(e) =>
                              setSelectedAssignment({
                                ...selectedAssignment,
                                maxMarks: parseInt(e.target.value),
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="teacher"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Teacher
                          </label>
                          <input
                            type="text"
                            id="teacher"
                            value={selectedAssignment.teacher}
                            onChange={(e) =>
                              setSelectedAssignment({
                                ...selectedAssignment,
                                teacher: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                          />
                        </div>
                      </div>
                    </form>
                  )}

                  {/* Action Buttons */}
                  <div className="flex flex-col sm:flex-row gap-4 pt-6 border-t">
                    <button
                      onClick={() => setShowModal(false)}
                      className="flex-1 px-4 py-2 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors"
                    >
                      Cancel
                    </button>
                    {modalMode !== "view" && (
                      <button
                        onClick={() => handleSaveAssignment(selectedAssignment)}
                        className="flex-1 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                      >
                        {modalMode === "create"
                          ? "Create Assignment"
                          : "Save Changes"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Bottom Navigation */}
        <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white border-t p-4">
          <div className="flex justify-around">
            <button
              onClick={() => setShowFilters(false)}
              className="flex flex-col items-center text-gray-500 hover:text-purple-600"
            >
              <BookOpen className="w-6 h-6" />
              <span className="text-xs mt-1">Assignments</span>
            </button>
            <button
              onClick={() => setShowFilters(true)}
              className="flex flex-col items-center text-gray-500 hover:text-purple-600"
            >
              <Filter className="w-6 h-6" />
              <span className="text-xs mt-1">Filters</span>
            </button>
            <button
              onClick={handleCreateAssignment}
              className="flex flex-col items-center text-purple-600"
            >
              <Plus className="w-6 h-6" />
              <span className="text-xs mt-1">Create</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
