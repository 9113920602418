// import React from "react";
// import "./Slider.css";
// import silder_1 from "../assets/nk.jpg";
// import silder_4 from "../assets/vc.jpg";
// import silder_5 from "../assets/slider_5.jpg";

// const Slider = () => {
//   return (
//     <>
//       <div id="carouselExample" className="carousel slide ">
//         <div className="carousel-inner">
//           <div className="carousel-item active">
//             <img src={silder_5} className="d-block w-100" alt="..." />
//           </div>
//           <div className="carousel-item">
//             <img src={silder_4} className="d-block w-100" alt="..." />
//           </div>
//           <div className="carousel-item">
//             <img src={silder_1} className="d-block w-100" alt="..." />
//           </div>
//         </div>
//         <button
//           className="carousel-control-prev"
//           type="button"
//           data-bs-target="#carouselExample"
//           data-bs-slide="prev"
//         >
//           <span
//             className="carousel-control-prev-icon"
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Previous</span>
//         </button>
//         <button
//           className="carousel-control-next"
//           type="button"
//           data-bs-target="#carouselExample"
//           data-bs-slide="next"
//         >
//           <span
//             className="carousel-control-next-icon"
//             aria-hidden="true"
//           ></span>
//           <span className="visually-hidden">Next</span>
//         </button>
//       </div>
//     </>
//   );
// };

// export default Slider;

import React, { useState } from "react";
import { Box, Flex, Text, Image, Button, Link } from "@chakra-ui/react";
import silder_1 from "../assets/nk.jpg";
import silder_4 from "../assets/vc.jpg";
import silder_5 from "../assets/slider_5.jpg";

const Slider = () => {
  const images = [silder_5, silder_4, silder_1];
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <>
      <Box position="relative" w="100%" overflow="hidden">
        {/* Carousel Images */}
        <Box
          display="flex"
          transform={`translateX(-${currentIndex * 100}%)`}
          transition="transform 0.5s ease-in-out"
          w="100%" // Set width to 100%
        >
          {images.map((image, index) => (
            <Box flex="none" w="100%" key={index}>
              <Image
                src={image}
                alt={`Slide ${index}`}
                w="100%" // Set width to 100%
                h="500px"
                objectFit="cover"
              />
            </Box>
          ))}
        </Box>

        {/* Previous Button */}
        <Button
          position="absolute"
          top="50%"
          left="10px"
          transform="translateY(-50%)"
          zIndex="10"
          onClick={prevSlide}
          bg="white"
          _hover={{ bg: "gray.200" }}
        >
          &#9664;
        </Button>

        {/* Next Button */}
        <Button
          position="absolute"
          top="50%"
          right="10px"
          transform="translateY(-50%)"
          zIndex="10"
          onClick={nextSlide}
          bg="white"
          _hover={{ bg: "gray.200" }}
        >
          &#9654;
        </Button>
      </Box>

      {/* Admission Open Marquee */}
      <Box bg="blue.600" py="2" mt="4" position="relative" overflow="hidden">
        <Text
          fontSize="lg"
          fontWeight="bold"
          color="white"
          whiteSpace="nowrap"
          animation="marquee 10s linear infinite"
        >
          Admission Open Now &nbsp;&nbsp;|&nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;|&nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;|&nbsp;&nbsp;Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp; | &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;| &nbsp;&nbsp; Admission Open Now &nbsp;&nbsp;|
        </Text>
      </Box>

      <style>
        {`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </>
  );
};

export default Slider;
