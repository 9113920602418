import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import {
  DollarSign,
  TrendingUp,
  TrendingDown,
  Users,
  Calendar,
  Download,
  Filter,
  ChevronDown,
  ChevronUp,
  RefreshCw,
  AlertCircle,
  CheckCircle,
  XCircle,
  Search,
  Plus,
  Edit,
  Trash2,
  FileText,
  CreditCard,
  Wallet,
} from "lucide-react";
import { format } from "date-fns";
import toast, { Toaster } from "react-hot-toast";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const COLORS = ["#4F46E5", "#10B981", "#F59E0B", "#EF4444"];

// Sample data
const initialTransactions = [
  {
    id: "T001",
    date: "2024-03-01",
    type: "Income",
    category: "Fees",
    amount: 25000,
    description: "Student fees collection - March 2024",
    paymentMethod: "Online Transfer",
    status: "Completed",
    studentId: "STU001",
    studentName: "John Doe",
    class: "10th",
    section: "A",
  },
  {
    id: "T002",
    date: "2024-03-05",
    type: "Expense",
    category: "Salary",
    amount: 35000,
    description: "Staff salary - February 2024",
    paymentMethod: "Bank Transfer",
    status: "Completed",
    staffId: "STF001",
    staffName: "Jane Smith",
    department: "Mathematics",
  },
];

const AccountsManagement = () => {
  const [transactions, setTransactions] = useState(initialTransactions);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("All");
  const [filterCategory, setFilterCategory] = useState("All");
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });
  const [showModal, setShowModal] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [view, setView] = useState("transactions"); // transactions, analytics, reports
  const [chartType, setChartType] = useState("monthly"); // monthly, category, payment-method

  // Calculate financial metrics
  const financialMetrics = {
    totalIncome: transactions
      .filter((t) => t.type === "Income")
      .reduce((sum, t) => sum + t.amount, 0),
    totalExpense: transactions
      .filter((t) => t.type === "Expense")
      .reduce((sum, t) => sum + t.amount, 0),
    pendingPayments: transactions
      .filter((t) => t.status === "Pending")
      .reduce((sum, t) => sum + t.amount, 0),
    currentBalance: transactions.reduce(
      (sum, t) => (t.type === "Income" ? sum + t.amount : sum - t.amount),
      0
    ),
  };

  // Chart data
  const monthlyData = [
    { month: "Jan", income: 120000, expense: 95000 },
    { month: "Feb", income: 135000, expense: 98000 },
    { month: "Mar", income: 125000, expense: 92000 },
    { month: "Apr", income: 140000, expense: 97000 },
    { month: "May", income: 130000, expense: 94000 },
    { month: "Jun", income: 145000, expense: 99000 },
  ];

  const categoryData = [
    { name: "Fees", value: 450000 },
    { name: "Salary", value: 380000 },
    { name: "Maintenance", value: 85000 },
    { name: "Supplies", value: 65000 },
  ];

  // Handlers
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const handleAddTransaction = () => {
    setCurrentTransaction(null);
    setShowModal(true);
  };

  const handleEditTransaction = (transaction) => {
    setCurrentTransaction(transaction);
    setShowModal(true);
  };

  const handleSaveTransaction = (transaction) => {
    if (transaction.id) {
      setTransactions(
        transactions.map((t) => (t.id === transaction.id ? transaction : t))
      );
      toast.success("Transaction updated successfully");
    } else {
      const newTransaction = {
        ...transaction,
        id: `T${transactions.length + 1}`.padStart(4, "0"),
        date: format(new Date(), "yyyy-MM-dd"),
      };
      setTransactions([...transactions, newTransaction]);
      toast.success("Transaction added successfully");
    }
    setShowModal(false);
  };

  const handleDeleteTransaction = (id) => {
    setTransactions(transactions.filter((t) => t.id !== id));
    toast.success("Transaction deleted successfully");
  };

  const generateReport = () => {
    const doc = new jsPDF();

    // Add title
    doc.setFontSize(18);
    doc.text("Financial Report", 14, 22);

    // Add summary
    doc.setFontSize(12);
    doc.text(`Total Income: ₹${financialMetrics.totalIncome}`, 14, 35);
    doc.text(`Total Expense: ₹${financialMetrics.totalExpense}`, 14, 45);
    doc.text(`Current Balance: ₹${financialMetrics.currentBalance}`, 14, 55);

    // Add transactions table
    const tableData = transactions.map((t) => [
      t.date,
      t.type,
      t.category,
      `₹${t.amount}`,
      t.status,
    ]);

    doc.autoTable({
      startY: 70,
      head: [["Date", "Type", "Category", "Amount", "Status"]],
      body: tableData,
    });

    doc.save("financial-report.pdf");
    toast.success("Report downloaded successfully");
  };

  // Filter and sort transactions
  const filteredTransactions = transactions.filter(
    (transaction) =>
      (transaction.description
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        transaction.id.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (filterType === "All" || transaction.type === filterType) &&
      (filterCategory === "All" || transaction.category === filterCategory)
  );

  const sortedTransactions = [...filteredTransactions].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key])
      return sortConfig.direction === "asc" ? -1 : 1;
    if (a[sortConfig.key] > b[sortConfig.key])
      return sortConfig.direction === "asc" ? 1 : -1;
    return 0;
  });

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-purple-50 p-6">
      <Toaster position="top-center" />

      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-8"
      >
        <h1 className="text-3xl font-bold text-gray-900">
          Financial Management
        </h1>
        <p className="mt-2 text-gray-600">
          Track and manage school finances efficiently
        </p>
      </motion.div>

      {/* Financial Overview Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <motion.div
          whileHover={{ scale: 1.02 }}
          className="bg-white p-6 rounded-xl shadow-md"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Total Income</p>
              <h3 className="text-2xl font-bold text-gray-900">
                ₹{financialMetrics.totalIncome.toLocaleString()}
              </h3>
            </div>
            <TrendingUp className="h-8 w-8 text-green-600" />
          </div>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.02 }}
          className="bg-white p-6 rounded-xl shadow-md"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Total Expense</p>
              <h3 className="text-2xl font-bold text-gray-900">
                ₹{financialMetrics.totalExpense.toLocaleString()}
              </h3>
            </div>
            <TrendingDown className="h-8 w-8 text-red-600" />
          </div>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.02 }}
          className="bg-white p-6 rounded-xl shadow-md"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Current Balance</p>
              <h3 className="text-2xl font-bold text-gray-900">
                ₹{financialMetrics.currentBalance.toLocaleString()}
              </h3>
            </div>
            <Wallet className="h-8 w-8 text-indigo-600" />
          </div>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.02 }}
          className="bg-white p-6 rounded-xl shadow-md"
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-gray-600">Pending Payments</p>
              <h3 className="text-2xl font-bold text-gray-900">
                ₹{financialMetrics.pendingPayments.toLocaleString()}
              </h3>
            </div>
            <AlertCircle className="h-8 w-8 text-yellow-600" />
          </div>
        </motion.div>
      </div>

      {/* Controls */}
      <div className="bg-white rounded-xl shadow-md p-6 mb-8">
        <div className="flex flex-col md:flex-row gap-4 items-center justify-between mb-6">
          <div className="flex flex-col sm:flex-row gap-4 flex-grow">
            <div className="relative flex-grow">
              <input
                type="text"
                placeholder="Search transactions..."
                value={searchTerm}
                onChange={handleSearch}
                className="w-full !pl-10 !mb-0 pr-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
              />
              <Search className="absolute left-3 sm:top-2.5 top-3 h-5 w-5 text-gray-400" />
            </div>
            <select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="All">All Types</option>
              <option value="Income">Income</option>
              <option value="Expense">Expense</option>
            </select>
            <select
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
              className="px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
            >
              <option value="All">All Categories</option>
              <option value="Fees">Fees</option>
              <option value="Salary">Salary</option>
              <option value="Maintenance">Maintenance</option>
              <option value="Supplies">Supplies</option>
            </select>
          </div>
          <div className="flex gap-4 sm:flex-row flex-col">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleAddTransaction}
              className="px-4 py-2 bg-indigo-600 text-white rounded-lg flex items-center gap-2 hover:bg-indigo-700 transition-colors text-nowrap"
            >
              <Plus className="h-5 w-5" />
              Add Transaction
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={generateReport}
              className="px-4 py-2 bg-gray-600 text-nowrap text-white rounded-lg flex items-center gap-2 hover:bg-gray-700 transition-colors"
            >
              <Download className="h-5 w-5" />
              Export Report
            </motion.button>
          </div>
        </div>

        {/* Charts */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold mb-4">Monthly Overview</h3>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="income"
                  stroke="#4F46E5"
                  fill="#4F46E5"
                  fillOpacity={0.1}
                />
                <Area
                  type="monotone"
                  dataKey="expense"
                  stroke="#EF4444"
                  fill="#EF4444"
                  fillOpacity={0.1}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h3 className="text-lg font-semibold mb-4">Expense Distribution</h3>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={categoryData}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {categoryData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Transactions Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {[
                  { key: "date", label: "Date" },
                  { key: "type", label: "Type" },
                  { key: "category", label: "Category" },
                  { key: "amount", label: "Amount" },
                  { key: "description", label: "Description" },
                  { key: "status", label: "Status" },
                  { key: "actions", label: "Actions" },
                ].map((column) => (
                  <th
                    key={column.key}
                    onClick={() =>
                      column.key !== "actions" && handleSort(column.key)
                    }
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  >
                    <div className="flex items-center gap-2">
                      {column.label}
                      {sortConfig.key === column.key &&
                        (sortConfig.direction === "asc" ? (
                          <ChevronUp className="h-4 w-4" />
                        ) : (
                          <ChevronDown className="h-4 w-4" />
                        ))}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedTransactions.map((transaction) => (
                <motion.tr
                  key={transaction.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="hover:bg-gray-50"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {format(new Date(transaction.date), "dd MMM yyyy")}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        transaction.type === "Income"
                          ? "bg-green-100 text-green-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {transaction.type}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {transaction.category}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    ₹{transaction.amount.toLocaleString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {transaction.description}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        transaction.status === "Completed"
                          ? "bg-green-100 text-green-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {transaction.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex items-center gap-3">
                      <button
                        onClick={() => handleEditTransaction(transaction)}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        <Edit className="h-4 w-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteTransaction(transaction.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Transaction Modal */}
      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              className="bg-white rounded-xl p-6 max-w-lg w-full"
            >
              <h2 className="text-2xl font-bold mb-6">
                {currentTransaction ? "Edit Transaction" : "New Transaction"}
              </h2>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSaveTransaction(currentTransaction);
                }}
              >
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Type
                    </label>
                    <select
                      value={currentTransaction?.type || "Income"}
                      onChange={(e) =>
                        setCurrentTransaction({
                          ...currentTransaction,
                          type: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="Income">Income</option>
                      <option value="Expense">Expense</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Category
                    </label>
                    <select
                      value={currentTransaction?.category || ""}
                      onChange={(e) =>
                        setCurrentTransaction({
                          ...currentTransaction,
                          category: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="Fees">Fees</option>
                      <option value="Salary">Salary</option>
                      <option value="Maintenance">Maintenance</option>
                      <option value="Supplies">Supplies</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Amount
                    </label>
                    <input
                      type="number"
                      value={currentTransaction?.amount || ""}
                      onChange={(e) =>
                        setCurrentTransaction({
                          ...currentTransaction,
                          amount: parseFloat(e.target.value),
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <textarea
                      value={currentTransaction?.description || ""}
                      onChange={(e) =>
                        setCurrentTransaction({
                          ...currentTransaction,
                          description: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      rows={3}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Status
                    </label>
                    <select
                      value={currentTransaction?.status || "Pending"}
                      onChange={(e) =>
                        setCurrentTransaction({
                          ...currentTransaction,
                          status: e.target.value,
                        })
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="Pending">Pending</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                </div>
                <div className="mt-6 flex justify-end gap-3">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Save
                  </button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AccountsManagement;
