import React from 'react';
import "../../cssTeacher/feesOfteachers.css" 

function OnlineFees() {
  return (
    <div className="online-fees-container">
      <h2 className="page-heading">Online Fees Payment</h2>

  
      <section className="documents-section">
        <h3>Required Documents</h3>
        <ul className="document-list">
          <li>Identification Certificate</li>
          <li>Experience Certificates (if applicable)</li>
          <li>Previous Year Marksheets</li>
          <li>Admission Confirmation Document</li>
        </ul>
      </section>


      <section className="fees-categories-section">
        <h3>Fees Categories</h3>

        <div className="fee-category">
          <h4>Exam Fees</h4>
          <p>Amount: ₹2000</p>
        </div>

        <div className="fee-category">
          <h4>Admission Fees</h4>
          <p>Amount: ₹5000</p>
        </div>

        <div className="fee-category">
          <h4>Test Activities Fees</h4>
          <p>Amount: ₹1500</p>
        </div>
      </section>
    </div>
  );
}

export default OnlineFees;
