import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  VStack,
  HStack,
  useColorModeValue,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

const ResultPage = () => {
  // Sample student data
  const student = {
    name: 'John Doe',
    rollNumber: '12345',
    course: 'Computer Science',
    results: [
      {
        semester: 'Semester 1',
        subjects: [
          { name: 'Mathematics', marks: 85, totalMarks: 100 },
          { name: 'Physics', marks: 78, totalMarks: 100 },
          { name: 'Chemistry', marks: 88, totalMarks: 100 },
        ],
      },
      {
        semester: 'Semester 2',
        subjects: [
          { name: 'Mathematics', marks: 90, totalMarks: 100 },
          { name: 'Physics', marks: 82, totalMarks: 100 },
          { name: 'Chemistry', marks: 91, totalMarks: 100 },
        ],
      },
    ],
  };

  // Function to calculate total marks and percentage
  const calculateResults = (subjects) => {
    const totalMarks = subjects.reduce((acc, subject) => acc + subject.marks, 0);
    const totalMaxMarks = subjects.reduce((acc, subject) => acc + subject.totalMarks, 0);
    const percentage = ((totalMarks / totalMaxMarks) * 100).toFixed(2);
    return { totalMarks, totalMaxMarks, percentage };
  };

  const toast = useToast();
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  // Fetch color values outside of the map callback
  const headingColor = useColorModeValue('teal.600', 'teal.300');
  const boxBgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.300');
  
  const handleDownloadResult = (semester) => {
    toast({
      title: `${semester} Result Downloaded`,
      description: `The result for ${semester} has been downloaded successfully!`,
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    // Implement PDF download functionality for each semester result here
  };

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading size={headingSize} textAlign="center" color={headingColor}>
          Student Result 
        </Heading>

        <Box borderWidth="1px" borderRadius="lg" boxShadow="md" p={6} bg={boxBgColor}>
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg" color={textColor}>
              <strong>Name:</strong> {student.name}
            </Text>
            <Text fontSize="lg" color={textColor}>
              <strong>Roll Number:</strong> {student.rollNumber}
            </Text>
            <Text fontSize="lg" color={textColor}>
              <strong>Course:</strong> {student.course}
            </Text>

            <Heading size="md" color={headingColor}>
              Semester Results
            </Heading>

            {student.results.map((result, index) => {
              const { totalMarks, totalMaxMarks, percentage } = calculateResults(result.subjects);

              return (
                <Box key={index} borderWidth="1px" borderRadius="md" p={4} mt={6}>
                  <Heading size="sm" color={headingColor}>
                    {result.semester}
                  </Heading>

                  <Table variant="striped" colorScheme="teal" mt={4}>
                    <Thead>
                      <Tr>
                        <Th>Subject</Th>
                        <Th>Marks Obtained</Th>
                        <Th>Total Marks</Th>
                        <Th>Percentage</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {result.subjects.map((subject, idx) => (
                        <Tr key={idx}>
                          <Td>{subject.name}</Td>
                          <Td>{subject.marks}</Td>
                          <Td>{subject.totalMarks}</Td>
                          <Td>{((subject.marks / subject.totalMarks) * 100).toFixed(2)}%</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>

                  <HStack justify="space-between" mt={4}>
                    <Box>
                      <Text fontSize="lg" fontWeight="bold" color={textColor}>
                        Total Marks: {totalMarks} / {totalMaxMarks}
                      </Text>
                      <Text fontSize="lg" fontWeight="bold" color={textColor}>
                        Percentage: {percentage}%
                      </Text>
                    </Box>

                    <Button
                      colorScheme="teal"
                      variant="solid"
                      size="lg"
                      leftIcon={<DownloadIcon />}
                      onClick={() => handleDownloadResult(result.semester)}
                    >
                      Download {result.semester} Result
                    </Button>
                  </HStack>
                </Box>
              );
            })}
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default ResultPage;
