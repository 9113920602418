import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  Badge,
  useBreakpointValue,
  useColorModeValue,
  useToast,
  IconButton,
  SimpleGrid,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import { DownloadIcon, BellIcon } from "@chakra-ui/icons";

const SportsActivityPage = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([
    {
      id: 1,
      eventName: "Annual Sports Meet",
      date: "2024-12-10",
      isNotified: false,
    },
    {
      id: 2,
      eventName: "Inter-School Cricket",
      date: "2025-01-15",
      isNotified: false,
    },
  ]);

  const [studentParticipation, setStudentParticipation] = useState([
    {
      eventName: "Annual Sports Meet",
      status: "Participated",
      isWinner: true,
      certificateDownloadLink: "/path/to/certificate1.pdf",
    },
    {
      eventName: "Inter-School Cricket",
      status: "Not Participated",
      isWinner: false,
      certificateDownloadLink: "",
    },
  ]);

  const toast = useToast();
  const headingSize = useBreakpointValue({ base: "lg", md: "xl" });

  // Fetch color values outside of map callbacks
  const headingColor = useColorModeValue("teal.600", "teal.300");
  const boxBgColor = useColorModeValue("white", "gray.800");
  const badgeColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("gray.700", "gray.300");
  const cardBgColor = useColorModeValue("gray.100", "gray.700"); // Moved from map

  const handleDownloadCertificate = (event) => {
    if (event.certificateDownloadLink) {
      const link = document.createElement("a");
      link.href = event.certificateDownloadLink;
      link.download = `certificate-${event.eventName}.pdf`;
      link.click();

      toast({
        title: "Certificate Downloaded",
        description: `Your certificate for ${event.eventName} has been downloaded successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleNotifyUpcomingEvent = (eventId) => {
    setUpcomingEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === eventId ? { ...event, isNotified: true } : event
      )
    );
  };

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading size={headingSize} textAlign="center" color={headingColor}>
          Student Sports Activities
        </Heading>

        {/* Participation Status */}
        <Box borderWidth="1px" borderRadius="lg" boxShadow="md" p={6} bg={boxBgColor}>
          <VStack spacing={4} align="stretch">
            <Heading size="md" color={headingColor}>
              Participation Status
            </Heading>

            {studentParticipation.map((activity, index) => (
              <Box
                key={index}
                borderWidth="1px"
                borderRadius="md"
                p={4}
                mt={4}
                bg={cardBgColor} // Use the variable here
              >
                <HStack justify="space-between">
                  <Text fontSize="lg" fontWeight="bold" color={textColor}>
                    {activity.eventName}
                  </Text>
                  <Badge
                    colorScheme={activity.status === "Participated" ? "green" : "red"}
                    variant="solid"
                  >
                    {activity.status}
                  </Badge>
                </HStack>

                {activity.isWinner && activity.status === "Participated" && (
                  <HStack mt={4}>
                    <Button
                      colorScheme="teal"
                      size="sm"
                      leftIcon={<DownloadIcon />}
                      onClick={() => handleDownloadCertificate(activity)}
                    >
                      Download Certificate
                    </Button>
                    <Text fontSize="md" color={textColor}>
                      You won the event!
                    </Text>
                  </HStack>
                )}
              </Box>
            ))}
          </VStack>
        </Box>

        {/* Upcoming Events */}
        <Box borderWidth="1px" borderRadius="lg" boxShadow="md" p={6} bg={boxBgColor}>
          <VStack spacing={4} align="stretch">
            <Heading size="md" color={headingColor}>
              Upcoming Sports Events
            </Heading>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              {upcomingEvents.map((event, index) => (
                <Box
                  key={event.id}
                  borderWidth="1px"
                  borderRadius="md"
                  p={4}
                  bg={badgeColor}
                >
                  <Text fontSize="lg" fontWeight="bold" color={textColor}>
                    {event.eventName}
                  </Text>
                  <Text fontSize="md" color={textColor}>
                    Date: {event.date}
                  </Text>
                  {!event.isNotified ? (
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      size="sm"
                      mt={4}
                      leftIcon={<BellIcon />}
                      onClick={() => handleNotifyUpcomingEvent(event.id)}
                    >
                      Notify Me
                    </Button>
                  ) : (
                    <Alert status="info" variant="left-accent" mt={4}>
                      <AlertIcon />
                      <AlertTitle>Upcoming Event</AlertTitle>
                      <AlertDescription>
                        Your event notification has been sent!
                      </AlertDescription>
                    </Alert>
                  )}
                </Box>
              ))}
            </SimpleGrid>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default SportsActivityPage;
