import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
  useToast,
  useColorModeValue,
  HStack,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const FeesPaymentPage = () => {
  const [feesStatus, setFeesStatus] = useState('unpaid'); // 'unpaid' or 'paid'
  const toast = useToast();
  
  // Toggle between paid and unpaid fees (for demo purposes)
  const handlePayment = () => {
    setFeesStatus('paid');
    toast({
      title: 'Payment Successful',
      description: 'Your fees have been successfully paid.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  // Blinking link for unpaid fees
  const isUnpaid = feesStatus === 'unpaid';
  const blinkingStyle = isUnpaid
    ? { animation: 'blink 1.5s linear infinite' }
    : {};

  // Responsive text sizes for small screens
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading size={headingSize} textAlign="center" color={useColorModeValue('teal.600', 'teal.300')}>
          Student Fees Payment
        </Heading>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          p={6}
          bg={useColorModeValue('white', 'gray.800')}
        >
          <VStack spacing={4} align="center">
            <Text fontSize="lg">
              Fees Status for this Month: 
              <Text as="span" color={feesStatus === 'paid' ? 'green.500' : 'red.500'} fontWeight="bold">
                {feesStatus === 'paid' ? 'Paid' : 'Unpaid'}
              </Text>
            </Text>

            {isUnpaid ? (
              <Text
                fontSize="md"
                color="red.500"
                fontWeight="bold"
                style={blinkingStyle}
                mb={4}
              >
                ** Your fees are still unpaid! **
              </Text>
            ) : (
              <Text fontSize="md" color="green.500" fontWeight="bold">
                ** Your fees have been paid. Thank you! **
              </Text>
            )}

            <HStack spacing={4}>
              {isUnpaid ? (
                <Link
                  href="#"
                  onClick={handlePayment}
                  textDecoration="none"
                  _hover={{ textDecoration: 'none' }}
                >
                  <Button colorScheme="teal" size="lg" leftIcon={<CheckIcon />} variant="solid">
                    Pay Now
                  </Button>
                </Link>
              ) : (
                <Button colorScheme="green" size="lg" isDisabled>
                  Paid
                </Button>
              )}
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default FeesPaymentPage;
