import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format, isSameDay, addDays, startOfWeek } from 'date-fns';
import {  Clock, MapPin, ChevronLeft, ChevronRight } from 'lucide-react';

const ScheduleList = ({ events, onEventClick }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [weekStart, setWeekStart] = useState(startOfWeek(selectedDate));

  useEffect(() => {
    setWeekStart(startOfWeek(selectedDate));
  }, [selectedDate]);

  const weekDays = Array.from({ length: 7 }, (_, i) => addDays(weekStart, i));

  const getEventTypeStyle = (type) => {
    const styles = {
      class: 'bg-indigo-100 text-indigo-800',
      bus: 'bg-green-100 text-green-800',
      extra: 'bg-purple-100 text-purple-800',
      activity: 'bg-orange-100 text-orange-800'
    };
    return styles[type] || 'bg-blue-100 text-blue-800';
  };

  const EventCard = ({ event }) => (
    <motion.div
      layout
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`p-3 rounded-lg shadow-sm ${getEventTypeStyle(event.type)} cursor-pointer`}
      onClick={() => onEventClick(event)}
    >
      <h4 className="font-medium text-sm mb-1">{event.title}</h4>
      <div className="flex items-center text-xs space-x-2">
        <Clock className="w-3 h-3" />
        <span>{format(new Date(event.start), 'h:mm a')} - {format(new Date(event.end), 'h:mm a')}</span>
      </div>
      {event.location && (
        <div className="flex items-center text-xs space-x-2 mt-1">
          <MapPin className="w-3 h-3" />
          <span>{event.location}</span>
        </div>
      )}
    </motion.div>
  );

  return (
    <div className="bg-white rounded-xl shadow-lg p-4 max-w-6xl mx-auto">
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => setSelectedDate(date => addDays(date, -7))}
          className="p-2 rounded-full hover:bg-gray-100"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>
        <h2 className="text-lg font-semibold">
          {format(weekStart, 'MMMM d')} - {format(addDays(weekStart, 6), 'MMMM d, yyyy')}
        </h2>
        <button
          onClick={() => setSelectedDate(date => addDays(date, 7))}
          className="p-2 rounded-full hover:bg-gray-100"
        >
          <ChevronRight className="w-5 h-5" />
        </button>
      </div>

      <div className="grid grid-cols-7 gap-4 mb-4">
        {weekDays.map((day, index) => (
          <button
            key={day.toISOString()}
            onClick={() => setSelectedDate(day)}
            className={`flex flex-col items-center p-2 rounded-lg transition-colors ${
              isSameDay(day, selectedDate)
                ? 'bg-blue-500 text-white'
                : 'hover:bg-gray-100'
            }`}
          >
            <span className="text-xs font-medium">{format(day, 'EEE')}</span>
            <span className="text-lg font-bold">{format(day, 'd')}</span>
          </button>
        ))}
      </div>

      <div className="overflow-y-auto max-h-[calc(100vh-300px)] pr-2">
        <AnimatePresence>
          {events
            .filter(event => isSameDay(new Date(event.start), selectedDate))
            .sort((a, b) => new Date(a.start) - new Date(b.start))
            .map(event => (
              <motion.div key={event.id} className="mb-3">
                <EventCard event={event} />
              </motion.div>
            ))}
        </AnimatePresence>
      </div>

      {events.filter(event => isSameDay(new Date(event.start), selectedDate)).length === 0 && (
        <p className="text-center text-gray-500 mt-4">No events scheduled for this day.</p>
      )}
    </div>
  );
};

export default ScheduleList;