import React from 'react';
import '../cssTeacher/StaffManagementforTeachers.css';

function StaffManagementForTeachers() {
  return (
    <div className="staff-management">
      <h2 className="title">Staff Management</h2>

    
      <div className="staff-actions">
        <button className="btn">Add New Staff</button>
        <button className="btn">View All Staff</button>
      </div>

  
      <div className="staff-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Role</th>
              <th>Department</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
      
            <tr>
              <td>John Doe</td>
              <td>Teacher</td>
              <td>Math</td>
              <td>Active</td>
            </tr>
            <tr>
              <td>Jane Smith</td>
              <td>Admin</td>
              <td>Office</td>
              <td>On Leave</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default StaffManagementForTeachers;
