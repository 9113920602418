import React, { useState } from 'react';
import { FaEnvelope, FaPhoneAlt, FaFileAlt, FaUserCircle } from 'react-icons/fa';
// import schoollogo from "../../../assets/schoollogo.png"
import "../cssTeacher/ProfileTeachers.css";

function ProfileTeachers() {
  const [profileImage, setProfileImage] = useState(null);

  const teacherData = {
    name: "John Doe",
    email: "johndoe@example.com",
    phoneNumber: "+91 9876543210",
    experience: "12 years",
    documents: [
      "Bachelor's Degree Certificate",
      "Master's Degree Certificate",
      "Teaching Certification",
      "Training Certificates",
    ],
    trainingExperiences: [
      "Advanced Teaching Techniques - 2018",
      "Classroom Management Workshop - 2019",
      "Curriculum Design - 2021",
    ],
    subjects: [
      "Mathematics",
      "Physics",
      "Chemistry",
      "Computer Science",
      "English",
    ],
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="profile-teachers-container">
      <div className="logo-container">
        <img src="" alt="School Logo" className="logo-image" />
      </div>
      
      <h2 className="profile-title">Teacher Profile</h2>
      <hr className="divider" />

      <div className="details-section">
    
        <div className="teacher-logo" onClick={() => document.getElementById('imageUpload').click()}>
          {profileImage ? (
            <img src="" alt="Teacher Profile" className="teacher-logo-img" />
          ) : (
            <FaUserCircle className="teacher-logo-icon" />
          )}
        </div>

      
        <input
          type="file"
          id="imageUpload"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleImageChange}
        />

        <div>

        <p><strong>Name:</strong> {teacherData.name}</p>
        <p><strong>Email:</strong> <a href={`mailto:${teacherData.email}`} className="email-link"><FaEnvelope className="icon" />{teacherData.email}</a></p>
        <p><strong>Phone Number:</strong> <span className="phone"><FaPhoneAlt className="icon" /> {teacherData.phoneNumber}</span></p>
        <p><strong>Experience:</strong> {teacherData.experience}</p>
        </div>

      </div>

      <div className="documents-section">
        <h3 className="section-title">Documents</h3>
        <ul className="document-list">
          {teacherData.documents.map((doc, index) => (
            <li key={index} className="document-item"><FaFileAlt className="icon" /> {doc}</li>
          ))}
        </ul>
      </div>

      <div className="training-section">
        <h3 className="section-title">Teacher Training Experiences</h3>
        <ul className="training-list">
          {teacherData.trainingExperiences.map((training, index) => (
            <li key={index} className="training-item">{training}</li>
          ))}
        </ul>
      </div>

      <div className="subjects-section">
        <h3 className="section-title">Subjects Taught</h3>
        <ul className="subject-list">
          {teacherData.subjects.map((subject, index) => (
            <li key={index} className="subject-item">{subject}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ProfileTeachers;
