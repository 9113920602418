"use client";

import React, { useState } from "react";
import {
  ChevronLeft,
  ChevronRight,
  Calendar as CalIcon,
  Clock,
  Users,
  MapPin,
  Info,
  Search,
  Plus,
  Edit,
  Trash2,
  X,
  Filter,
} from "lucide-react";

// Sample exam data
const initialExamData = [
  {
    id: "EX001",
    class: "9th",
    section: "A",
    exam: "Mathematics",
    subject_code: "MATH101",
    date: "2024-05-15",
    time: "09:00 AM",
    duration: "3 hours",
    venue: "Hall A",
    examiner: "Dr. Smith",
    instructions: "Bring geometric instruments",
    type: "Final Exam",
    maxMarks: 100,
  },
  {
    id: "EX002",
    class: "9th",
    section: "B",
    exam: "Physics",
    subject_code: "PHY101",
    date: "2024-05-16",
    time: "11:00 AM",
    duration: "2 hours",
    venue: "Hall B",
    examiner: "Mr. Johnson",
    instructions: "No calculators allowed",
    type: "Midterm",
    maxMarks: 50,
  },
  {
    id: "EX003",
    class: "10th",
    section: "A",
    exam: "Chemistry",
    subject_code: "CHEM201",
    date: "2024-05-17",
    time: "10:00 AM",
    duration: "2.5 hours",
    venue: "Lab 1",
    examiner: "Dr. Williams",
    instructions: "Bring lab coat and goggles",
    type: "Practical Exam",
    maxMarks: 75,
  },
  {
    id: "EX004",
    class: "11th",
    section: "A",
    exam: "English Literature",
    subject_code: "ENG301",
    date: "2024-05-18",
    time: "01:00 PM",
    duration: "3 hours",
    venue: "Auditorium",
    examiner: "Ms. Brown",
    instructions: "Open book exam",
    type: "Final Exam",
    maxMarks: 100,
  },
  {
    id: "EX005",
    class: "12th",
    section: "B",
    exam: "Biology",
    subject_code: "BIO401",
    date: "2024-05-19",
    time: "09:00 AM",
    duration: "3 hours",
    venue: "Hall C",
    examiner: "Dr. Taylor",
    instructions: "Bring specimen identification guide",
    type: "Final Exam",
    maxMarks: 100,
  },
];

const ExamCalander = () => {
  const [examData, setExamData] = useState(initialExamData);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedClass, setSelectedClass] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("view"); // 'view', 'edit', or 'create'
  const [showFilters, setShowFilters] = useState(false);

  const uniqueClasses = ["All", ...new Set(examData.map((item) => item.class))];

  const filteredExams = examData.filter((exam) => {
    const matchesClass =
      selectedClass === "All" || exam.class === selectedClass;
    const matchesSearch =
      exam.exam.toLowerCase().includes(searchTerm.toLowerCase()) ||
      exam.subject_code.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesClass && matchesSearch;
  });

  const groupedExams = filteredExams.reduce((acc, exam) => {
    if (!acc[exam.class]) {
      acc[exam.class] = [];
    }
    acc[exam.class].push(exam);
    return acc;
  }, {});

  const sortedClasses = Object.keys(groupedExams).sort((a, b) => {
    const aNum = parseInt(a);
    const bNum = parseInt(b);
    return aNum - bNum;
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleCreateExam = () => {
    setModalMode("create");
    setSelectedExam({
      id: `EX${examData.length + 1}`.padStart(5, "0"),
      class: "",
      section: "",
      exam: "",
      subject_code: "",
      date: "",
      time: "",
      duration: "",
      venue: "",
      examiner: "",
      instructions: "",
      type: "",
      maxMarks: 0,
    });
    setShowModal(true);
  };

  const handleEditExam = (exam) => {
    setModalMode("edit");
    setSelectedExam(exam);
    setShowModal(true);
  };

  const handleDeleteExam = (id) => {
    setExamData(examData.filter((exam) => exam.id !== id));
  };

  const handleSaveExam = (updatedExam) => {
    if (modalMode === "create") {
      setExamData([...examData, updatedExam]);
    } else {
      setExamData(
        examData.map((exam) =>
          exam.id === updatedExam.id ? updatedExam : exam
        )
      );
    }
    setShowModal(false);
  };

  return (
    <div className="min-h-scree p-4 md:p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-6">
          <div className="bg-gradient-to-r from-purple-600 to-indigo-600 p-6">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <div className="text-white">
                <h1 className="text-2xl md:text-3xl font-bold">
                  Exam Calendar
                </h1>
                <p className="mt-1 text-purple-100">
                  Manage and organize school exams
                </p>
              </div>

              <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto">
                <div className="relative w-full md:w-64">
                  <input
                    type="text"
                    placeholder="Search exams..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full !pl-10 !mb-0 pr-4 py-2 rounded-lg bg-white/10 text-white placeholder-purple-200 border border-purple-400 focus:outline-none focus:ring-2 focus:ring-white"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-purple-200" />
                </div>

                <button
                  onClick={() => setShowFilters(!showFilters)}
                  className="px-4 py-2 bg-white/10 text-white rounded-lg border border-purple-400 hover:bg-white/20 transition-colors"
                >
                  <Filter className="w-5 h-5" />
                </button>

                <button
                  onClick={handleCreateExam}
                  className="px-4 py-2 bg-white text-purple-600 rounded-lg hover:bg-purple-50 transition-colors"
                >
                  <Plus className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>

          {/* Filters Panel */}
          {showFilters && (
            <div className="p-4 border-t border-gray-200">
              <div className="flex flex-wrap gap-4">
                <div className="flex-1">
                  <label
                    htmlFor="class-filter"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Class
                  </label>
                  <select
                    id="class-filter"
                    value={selectedClass}
                    onChange={(e) => setSelectedClass(e.target.value)}
                    className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500"
                  >
                    {uniqueClasses.map((cls) => (
                      <option key={cls} value={cls}>
                        {cls} {cls !== "All" && "Class"}
                      </option>
                    ))}
                  </select>
                </div>
                {/* Add more filters here as needed */}
              </div>
            </div>
          )}
        </div>

        {/* Exam Calendar */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="p-6">
            {sortedClasses.map((classGroup) => (
              <div key={classGroup} className="mb-8">
                <h2 className="text-2xl font-bold text-gray-900 mb-4">
                  Class {classGroup}
                </h2>
                <div className="space-y-4">
                  {groupedExams[classGroup].map((exam) => (
                    <div
                      key={exam.id}
                      className="bg-white rounded-lg border border-gray-200 p-4 hover:shadow-md transition-shadow"
                    >
                      <div className="flex flex-col md:flex-row justify-between gap-4">
                        <div>
                          <h3 className="text-lg font-medium text-gray-900">
                            {exam.exam}
                          </h3>
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 mt-1">
                            {exam.subject_code}
                          </span>
                        </div>

                        <div className="flex flex-wrap gap-4">
                          <div className="flex items-center">
                            <CalIcon className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-600">
                              {formatDate(exam.date)}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <Clock className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-600">
                              {exam.time}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <Users className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-600">
                              Section {exam.section}
                            </span>
                          </div>
                          <div className="flex items-center">
                            <MapPin className="w-4 h-4 text-gray-400 mr-2" />
                            <span className="text-sm text-gray-600">
                              {exam.venue}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 flex items-center justify-between">
                        <div className="text-sm text-purple-600">
                          <span className="font-medium">Duration:</span>{" "}
                          {exam.duration}
                        </div>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => {
                              setModalMode("view");
                              setSelectedExam(exam);
                              setShowModal(true);
                            }}
                            className="text-purple-600 hover:text-purple-700"
                          >
                            <Info className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleEditExam(exam)}
                            className="text-blue-600 hover:text-blue-700"
                          >
                            <Edit className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteExam(exam.id)}
                            className="text-red-600 hover:text-red-700"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Exam Details Modal */}
        {showModal && selectedExam && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
              <div className="p-6 space-y-6">
                <div className="flex justify-between items-start">
                  <div>
                    <h3 className="text-xl font-bold text-gray-900">
                      {modalMode === "create"
                        ? "Create New Exam"
                        : modalMode === "edit"
                        ? "Edit Exam"
                        : selectedExam.exam}
                    </h3>
                    {modalMode === "view" && (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800 mt-1">
                        {selectedExam.subject_code}
                      </span>
                    )}
                  </div>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>

                {modalMode === "view" ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <div>
                        <p className="text-sm text-gray-500">Class & Section</p>
                        <p className="font-medium">
                          {selectedExam.class} - Section {selectedExam.section}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Date & Time</p>
                        <p className="font-medium">
                          {formatDate(selectedExam.date)}
                        </p>
                        <p className="text-sm">{selectedExam.time}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Duration</p>
                        <p className="font-medium">{selectedExam.duration}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Venue</p>
                        <p className="font-medium">{selectedExam.venue}</p>
                      </div>
                    </div>
                    <div className="space-y-4">
                      <div>
                        <p className="text-sm text-gray-500">Examiner</p>
                        <p className="font-medium">{selectedExam.examiner}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Maximum Marks</p>
                        <p className="font-medium">{selectedExam.maxMarks}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Exam Type</p>
                        <p className="font-medium">{selectedExam.type}</p>
                      </div>
                      <div>
                        <p className="text-sm text-gray-500">Instructions</p>
                        <p className="font-medium">
                          {selectedExam.instructions}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    className="space-y-4"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="exam"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Exam Name
                        </label>
                        <input
                          type="text"
                          id="exam"
                          value={selectedExam.exam}
                          onChange={(e) =>
                            setSelectedExam({
                              ...selectedExam,
                              exam: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="subject_code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Subject Code
                        </label>
                        <input
                          type="text"
                          id="subject_code"
                          value={selectedExam.subject_code}
                          onChange={(e) =>
                            setSelectedExam({
                              ...selectedExam,
                              subject_code: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div>
                        <label
                          htmlFor="class"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Class
                        </label>
                        <input
                          type="text"
                          id="class"
                          value={selectedExam.class}
                          onChange={(e) =>
                            setSelectedExam({
                              ...selectedExam,
                              class: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="section"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Section
                        </label>
                        <input
                          type="text"
                          id="section"
                          value={selectedExam.section}
                          onChange={(e) =>
                            setSelectedExam({
                              ...selectedExam,
                              section: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="maxMarks"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Maximum Marks
                        </label>
                        <input
                          type="number"
                          id="maxMarks"
                          value={selectedExam.maxMarks}
                          onChange={(e) =>
                            setSelectedExam({
                              ...selectedExam,
                              maxMarks: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div>
                        <label
                          htmlFor="date"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Date
                        </label>
                        <input
                          type="date"
                          id="date"
                          value={selectedExam.date}
                          onChange={(e) =>
                            setSelectedExam({
                              ...selectedExam,
                              date: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="time"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Time
                        </label>
                        <input
                          type="time"
                          id="time"
                          value={selectedExam.time}
                          onChange={(e) =>
                            setSelectedExam({
                              ...selectedExam,
                              time: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="duration"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Duration
                        </label>
                        <input
                          type="text"
                          id="duration"
                          value={selectedExam.duration}
                          onChange={(e) =>
                            setSelectedExam({
                              ...selectedExam,
                              duration: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="venue"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Venue
                      </label>
                      <input
                        type="text"
                        id="venue"
                        value={selectedExam.venue}
                        onChange={(e) =>
                          setSelectedExam({
                            ...selectedExam,
                            venue: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="examiner"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Examiner
                      </label>
                      <input
                        type="text"
                        id="examiner"
                        value={selectedExam.examiner}
                        onChange={(e) =>
                          setSelectedExam({
                            ...selectedExam,
                            examiner: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="instructions"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Instructions
                      </label>
                      <textarea
                        id="instructions"
                        rows={3}
                        value={selectedExam.instructions}
                        onChange={(e) =>
                          setSelectedExam({
                            ...selectedExam,
                            instructions: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="type"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Exam Type
                      </label>
                      <input
                        type="text"
                        id="type"
                        value={selectedExam.type}
                        onChange={(e) =>
                          setSelectedExam({
                            ...selectedExam,
                            type: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                      />
                    </div>
                  </form>
                )}

                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  >
                    Cancel
                  </button>
                  {modalMode !== "view" && (
                    <button
                      onClick={() => handleSaveExam(selectedExam)}
                      className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                    >
                      {modalMode === "create" ? "Create Exam" : "Save Changes"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExamCalander;
