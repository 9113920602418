"use client";

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Search,
  Plus,
  Edit,
  Power,
  ChevronDown,
  ChevronUp,
  X,
  FileText,
  Check,
  AlertCircle,
  Eye,
  RefreshCw,
  ThumbsUp,
  ThumbsDown,
  Download,
} from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

// Sample admission data
const initialAdmissions = [
  {
    id: "A001",
    name: "Aarav Patel",
    email: "aarav.patel@example.com",
    phone: "+91 98765 43210",
    program: "B.Tech in Computer Science and Engineering",
    status: "Pending",
    applicationDate: "2024-03-15",
    lastUpdated: "2024-03-20",
    documents: [
      "10th Marksheet",
      "12th Marksheet",
      "JEE Score Card",
      "Aadhaar Card",
    ],
    notes:
      "Strong academic background, needs financial aid information. Participated in National Science Olympiad.",
    testScores: { JEE: 245, "CBSE 12th": 95.6 },
    interviewDate: null,
    decisionDate: null,
    category: "General",
    state: "Gujarat",
    city: "Ahmedabad",
    school: "Delhi Public School, Bopal",
    extracurricular: "Captain of school cricket team, Member of coding club",
    disabled: false,
  },
  {
    id: "A002",
    name: "Zara Khan",
    email: "zara.khan@example.com",
    phone: "+91 87654 32109",
    program: "B.Tech in Electronics and Communication Engineering",
    status: "Approved",
    applicationDate: "2024-03-10",
    lastUpdated: "2024-03-18",
    documents: [
      "10th Marksheet",
      "12th Marksheet",
      "JEE Score Card",
      "Domicile Certificate",
      "Income Certificate",
    ],
    notes:
      "Excellent extracurricular activities, state-level badminton player. Received scholarship for academic excellence.",
    testScores: { JEE: 280, "Maharashtra HSC": 92.3 },
    interviewDate: "2024-03-25",
    decisionDate: "2024-04-01",
    category: "OBC",
    state: "Maharashtra",
    city: "Pune",
    school: "Symbiosis School for Liberal Arts",
    extracurricular:
      "State-level badminton player, Lead organizer of school tech fest",
    disabled: false,
  },
  {
    id: "A003",
    name: "Arjun Reddy",
    email: "arjun.reddy@example.com",
    phone: "+91 76543 21098",
    program: "B.Tech in Mechanical Engineering",
    status: "Waitlisted",
    applicationDate: "2024-03-05",
    lastUpdated: "2024-03-12",
    documents: [
      "10th Marksheet",
      "12th Marksheet",
      "JEE Score Card",
      "Sports Certificate",
      "Caste Certificate",
    ],
    notes:
      "Good academic record, strong in robotics competitions. Needs improvement in English proficiency.",
    testScores: { JEE: 220, "Telangana State Board": 88.7 },
    interviewDate: "2024-03-15",
    decisionDate: null,
    category: "SC",
    state: "Telangana",
    city: "Hyderabad",
    school: "Narayana Junior College",
    extracurricular:
      "Winner of state-level robotics competition, Volunteer at local NGO",
    disabled: false,
  },
];

export default function AdmissionDetails() {
  const [admissions, setAdmissions] = useState(initialAdmissions);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortConfig, setSortConfig] = useState({
    key: "applicationDate",
    direction: "descending",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("view");
  const [selectedAdmission, setSelectedAdmission] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  const programs = [
    "All",
    ...new Set(admissions.map((admission) => admission.program)),
  ];
  const statuses = ["All", "Pending", "Approved", "Rejected", "Waitlisted"];
  const categories = ["All", "General", "OBC", "SC", "ST"];

  const filteredAdmissions = admissions.filter((admission) => {
    const matchesSearch =
      admission.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      admission.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      admission.id.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesProgram =
      selectedProgram === "All" || admission.program === selectedProgram;
    const matchesStatus =
      selectedStatus === "All" || admission.status === selectedStatus;
    const matchesCategory =
      selectedCategory === "All" || admission.category === selectedCategory;
    return matchesSearch && matchesProgram && matchesStatus && matchesCategory;
  });

  const sortedAdmissions = [...filteredAdmissions].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const handleCreateAdmission = () => {
    setModalMode("create");
    setSelectedAdmission({
      id: `A${admissions.length + 1}`.padStart(4, "0"),
      name: "",
      email: "",
      phone: "",
      program: "",
      status: "Pending",
      applicationDate: new Date().toISOString().split("T")[0],
      lastUpdated: new Date().toISOString().split("T")[0],
      documents: [],
      notes: "",
      testScores: {},
      interviewDate: null,
      decisionDate: null,
      category: "General",
      state: "",
      city: "",
      school: "",
      extracurricular: "",
      disabled: false,
    });
    setShowModal(true);
  };

  const handleEditAdmission = (admission) => {
    setModalMode("edit");
    setSelectedAdmission(admission);
    setShowModal(true);
  };

  const handleToggleAdmission = (admission) => {
    setConfirmAction({
      type: "toggle",
      admission: admission,
      message: `Are you sure you want to ${
        admission.disabled ? "enable" : "disable"
      } this application?`,
    });
    setShowConfirmModal(true);
  };

  const handleApproveAdmission = (admission) => {
    setConfirmAction({
      type: "approve",
      admission: admission,
      message: `Are you sure you want to approve the application for ${admission.name}?`,
    });
    setShowConfirmModal(true);
  };

  const handleRejectAdmission = (admission) => {
    setConfirmAction({
      type: "reject",
      admission: admission,
      message: `Are you sure you want to reject the application for ${admission.name}?`,
    });
    setShowConfirmModal(true);
  };

  const confirmActionHandler = () => {
    if (confirmAction.type === "toggle") {
      setAdmissions(
        admissions.map((a) =>
          a.id === confirmAction.admission.id
            ? { ...a, disabled: !a.disabled }
            : a
        )
      );
      toast.success(
        confirmAction.admission.disabled
          ? "Application Enabled"
          : "Application Disabled"
      );
    } else if (confirmAction.type === "approve") {
      setAdmissions(
        admissions.map((a) =>
          a.id === confirmAction.admission.id ? { ...a, status: "Approved" } : a
        )
      );
      toast.success(
        `Application for ${confirmAction.admission.name} has been approved`
      );
    } else if (confirmAction.type === "reject") {
      setAdmissions(
        admissions.map((a) =>
          a.id === confirmAction.admission.id ? { ...a, status: "Rejected" } : a
        )
      );
      toast.error(
        `Application for ${confirmAction.admission.name} has been rejected`
      );
    }
    setShowConfirmModal(false);
  };

  const handleSaveAdmission = (updatedAdmission) => {
    setIsLoading(true);
    setTimeout(() => {
      if (modalMode === "create") {
        setAdmissions([...admissions, updatedAdmission]);
      } else {
        setAdmissions(
          admissions.map((admission) =>
            admission.id === updatedAdmission.id ? updatedAdmission : admission
          )
        );
      }
      setShowModal(false);
      setIsLoading(false);
      toast.success(
        modalMode === "create"
          ? "New Application Added"
          : "Application Details Updated"
      );
    }, 1000);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "bg-green-100 text-green-800";
      case "Rejected":
        return "bg-red-100 text-red-800";
      case "Waitlisted":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-blue-100 text-blue-800";
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "Approved":
        return <Check className="w-4 h-4" />;
      case "Rejected":
        return <X className="w-4 h-4" />;
      case "Waitlisted":
        return <RefreshCw className="w-4 h-4" />;
      default:
        return <AlertCircle className="w-4 h-4" />;
    }
  };

  const downloadPDF = (admission) => {
    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Student Application Details", 14, 22);

    doc.setFontSize(12);
    doc.text(`Name: ${admission.name}`, 14, 30);
    doc.text(`Email: ${admission.email}`, 14, 38);
    doc.text(`Phone: ${admission.phone}`, 14, 46);
    doc.text(`Program: ${admission.program}`, 14, 54);
    doc.text(`Status: ${admission.status}`, 14, 62);
    doc.text(`Category: ${admission.category}`, 14, 70);
    doc.text(`State: ${admission.state}`, 14, 78);
    doc.text(`City: ${admission.city}`, 14, 86);
    doc.text(`School: ${admission.school}`, 14, 94);

    doc.text("Test Scores:", 14, 106);
    const testScoresData = Object.entries(admission.testScores).map(
      ([test, score]) => [test, score]
    );
    doc.autoTable({
      startY: 110,
      head: [["Test", "Score"]],
      body: testScoresData,
    });

    const documentsStartY = doc.previousAutoTable.finalY + 10;
    doc.text("Documents:", 14, documentsStartY);
    doc.autoTable({
      startY: documentsStartY + 4,
      head: [["Document"]],
      body: admission.documents.map((doc) => [doc]),
    });

    doc.text(
      "Extracurricular Activities:",
      14,
      doc.previousAutoTable.finalY + 10
    );
    doc.text(admission.extracurricular, 14, doc.previousAutoTable.finalY + 18);

    doc.text("Notes:", 14, doc.previousAutoTable.finalY + 30);
    doc.text(admission.notes, 14, doc.previousAutoTable.finalY + 38);

    doc.save(`${admission.name.replace(" ", "_")}_application.pdf`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br p-4 from-indigo-50 via-white to-purple-50">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 rounded-2xl p-6 mb-8 shadow-lg"
        >
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-col space-y-4">
              {/* Header Text */}
              <div className="text-white">
                <h1 className="text-3xl md:text-4xl font-bold">
                  Admission Dashboard
                </h1>
                <p className="mt-2 text-indigo-100">
                  Manage and track student applications efficiently
                </p>
              </div>

              {/* Controls */}
              <div className="flex flex-col lg:flex-row gap-3 lg:items-center">
                {/* Search */}
                <div className="relative flex-1">
                  <input
                    type="text"
                    placeholder="Search applicants..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full !pl-10 !mb-0 pr-4 py-2 text-sm rounded-full bg-white/10 text-white placeholder-indigo-200 border border-indigo-400 focus:outline-none focus:ring-2 focus:ring-white transition-all"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-indigo-200" />
                </div>

                {/* Dropdowns */}
                <div className="flex flex-col sm:flex-row gap-3 lg:w-auto">
                  <select
                    value={selectedProgram}
                    onChange={(e) => setSelectedProgram(e.target.value)}
                    className="px-4 py-2 text-sm rounded-full bg-white/10 text-white border border-indigo-400 focus:outline-none focus:ring-2 focus:ring-white transition-all min-w-[120px]"
                  >
                    {programs.map((program) => (
                      <option
                        key={program}
                        value={program}
                        className="text-gray-900"
                      >
                        {program}
                      </option>
                    ))}
                  </select>

                  <select
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                    className="px-4 py-2 text-sm rounded-full bg-white/10 text-white border border-indigo-400 focus:outline-none focus:ring-2 focus:ring-white transition-all min-w-[120px]"
                  >
                    {statuses.map((status) => (
                      <option
                        key={status}
                        value={status}
                        className="text-gray-900"
                      >
                        {status}
                      </option>
                    ))}
                  </select>

                  <select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                    className="px-4 py-2 text-sm rounded-full bg-white/10 text-white border border-indigo-400 focus:outline-none focus:ring-2 focus:ring-white transition-all min-w-[120px]"
                  >
                    {categories.map((category) => (
                      <option
                        key={category}
                        value={category}
                        className="text-gray-900"
                      >
                        {category}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Add Button */}
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleCreateAdmission}
                  className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-indigo-600 bg-white rounded-full hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white transition-all whitespace-nowrap"
                >
                  <Plus className="w-5 h-5 mr-2" />
                  New Application
                </motion.button>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Admission List */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="bg-white rounded-2xl shadow-lg overflow-hidden"
        >
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    "Name",
                    "Program",
                    "Status",
                    "Category",
                    "State",
                    "Application Date",
                    "Actions",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer transition-colors duration-300 hover:bg-gray-100"
                      onClick={() =>
                        handleSort(header.toLowerCase().replace(" ", ""))
                      }
                    >
                      <div className="flex items-center">
                        {header}
                        {sortConfig.key ===
                          header.toLowerCase().replace(" ", "") &&
                          (sortConfig.direction === "ascending" ? (
                            <ChevronUp className="w-4 h-4 ml-1" />
                          ) : (
                            <ChevronDown className="w-4 h-4 ml-1" />
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {sortedAdmissions.map((admission) => (
                  <motion.tr
                    key={admission.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className={`hover:bg-gray-50 transition-colors duration-300 ${
                      admission.disabled ? "opacity-50" : ""
                    }`}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <div className="h-10 w-10 rounded-full bg-gradient-to-r from-indigo-400 to-purple-500 flex items-center justify-center text-white font-bold text-lg">
                            {admission.name.charAt(0)}
                          </div>
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {admission.name}
                          </div>
                          <div className="text-sm text-gray-500">
                            {admission.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {admission.program}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(
                          admission.status
                        )}`}
                      >
                        {getStatusIcon(admission.status)}
                        <span className="ml-1">{admission.status}</span>
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {admission.category}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {admission.state}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(admission.applicationDate).toLocaleDateString(
                        "en-IN"
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => {
                            setModalMode("view");
                            setSelectedAdmission(admission);
                            setShowModal(true);
                          }}
                          className="text-indigo-600 hover:text-indigo-900 bg-indigo-100 hover:bg-indigo-200 p-2 rounded-full transition-colors duration-300"
                          title="View Details"
                        >
                          <Eye className="w-4 h-4" />
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => handleEditAdmission(admission)}
                          className="text-blue-600 hover:text-blue-900 bg-blue-100 hover:bg-blue-200 p-2 rounded-full transition-colors duration-300"
                          title="Edit Application"
                        >
                          <Edit className="w-4 h-4" />
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => handleApproveAdmission(admission)}
                          className="text-green-600 hover:text-green-900 bg-green-100 hover:bg-green-200 p-2 rounded-full transition-colors duration-300"
                          title="Approve Application"
                        >
                          <ThumbsUp className="w-4 h-4" />
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => handleRejectAdmission(admission)}
                          className="text-red-600 hover:text-red-900 bg-red-100 hover:bg-red-200 p-2 rounded-full transition-colors duration-300"
                          title="Reject Application"
                        >
                          <ThumbsDown className="w-4 h-4" />
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => handleToggleAdmission(admission)}
                          className={`${
                            admission.disabled
                              ? "text-green-600 hover:text-green-900 bg-green-100 hover:bg-green-200"
                              : "text-red-600 hover:text-red-900 bg-red-100 hover:bg-red-200"
                          } p-2 rounded-full transition-colors duration-300`}
                          title={
                            admission.disabled
                              ? "Enable Application"
                              : "Disable Application"
                          }
                        >
                          <Power className="w-4 h-4" />
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => downloadPDF(admission)}
                          className="text-purple-600 hover:text-purple-900 bg-purple-100 hover:bg-purple-200 p-2 rounded-full transition-colors duration-300"
                          title="Download Application PDF"
                        >
                          <Download className="w-4 h-4" />
                        </motion.button>
                      </div>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        </motion.div>

        {/* Admission Modal */}
        <AnimatePresence>
          {showModal && selectedAdmission && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-2xl max-w-3xl w-full max-h-[90vh] overflow-y-auto"
              >
                <div className="p-6 space-y-6">
                  <div className="flex justify-between items-start">
                    <h3 className="text-2xl font-bold text-gray-900">
                      {modalMode === "create"
                        ? "New Application"
                        : modalMode === "edit"
                        ? "Edit Application"
                        : selectedAdmission.name}
                    </h3>
                    <button
                      onClick={() => setShowModal(false)}
                      className="text-gray-400 hover:text-gray-600 transition-colors duration-300"
                    >
                      <X className="w-6 h-6" />
                    </button>
                  </div>

                  {modalMode === "view" ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div className="space-y-4">
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Applicant Name
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.name}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Email
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.email}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Phone
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.phone}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Program
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.program}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Status
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.status}
                          </p>
                        </div>
                      </div>
                      <div className="space-y-4">
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Category
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.category}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            State
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.state}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            City
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.city}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            School
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {selectedAdmission.school}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm font-medium text-gray-500">
                            Application Date
                          </p>
                          <p className="mt-1 text-sm text-gray-900">
                            {new Date(
                              selectedAdmission.applicationDate
                            ).toLocaleDateString("en-IN")}
                          </p>
                        </div>
                      </div>
                      <div className="md:col-span-2">
                        <p className="text-sm font-medium text-gray-500">
                          Test Scores
                        </p>
                        <div className="mt-1 grid grid-cols-2 gap-4">
                          {Object.entries(selectedAdmission.testScores).map(
                            ([test, score]) => (
                              <div
                                key={test}
                                className="bg-gray-100 p-2 rounded-md"
                              >
                                <span className="font-medium">{test}:</span>{" "}
                                {score}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="md:col-span-2">
                        <p className="text-sm font-medium text-gray-500">
                          Documents
                        </p>
                        <div className="mt-1 space-y-2">
                          {selectedAdmission.documents.map((doc, index) => (
                            <div
                              key={index}
                              className="flex items-center space-x-2"
                            >
                              <FileText className="w-4 h-4 text-gray-400" />
                              <span>{doc}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="md:col-span-2">
                        <p className="text-sm font-medium text-gray-500">
                          Extracurricular Activities
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedAdmission.extracurricular}
                        </p>
                      </div>
                      <div className="md:col-span-2">
                        <p className="text-sm font-medium text-gray-500">
                          Notes
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedAdmission.notes}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSaveAdmission(selectedAdmission);
                      }}
                      className="space-y-4"
                    >
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Applicant Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            value={selectedAdmission.name}
                            onChange={(e) =>
                              setSelectedAdmission({
                                ...selectedAdmission,
                                name: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            value={selectedAdmission.email}
                            onChange={(e) =>
                              setSelectedAdmission({
                                ...selectedAdmission,
                                email: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone
                          </label>
                          <input
                            type="tel"
                            id="phone"
                            value={selectedAdmission.phone}
                            onChange={(e) =>
                              setSelectedAdmission({
                                ...selectedAdmission,
                                phone: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="program"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Program
                          </label>
                          <input
                            type="text"
                            id="program"
                            value={selectedAdmission.program}
                            onChange={(e) =>
                              setSelectedAdmission({
                                ...selectedAdmission,
                                program: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                          <label
                            htmlFor="status"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Status
                          </label>
                          <select
                            id="status"
                            value={selectedAdmission.status}
                            onChange={(e) =>
                              setSelectedAdmission({
                                ...selectedAdmission,
                                status: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                          >
                            {statuses
                              .filter((status) => status !== "All")
                              .map((status) => (
                                <option key={status} value={status}>
                                  {status}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div>
                          <label
                            htmlFor="category"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Category
                          </label>
                          <select
                            id="category"
                            value={selectedAdmission.category}
                            onChange={(e) =>
                              setSelectedAdmission({
                                ...selectedAdmission,
                                category: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                          >
                            {categories
                              .filter((category) => category !== "All")
                              .map((category) => (
                                <option key={category} value={category}>
                                  {category}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div>
                          <label
                            htmlFor="state"
                            className="block text-sm font-medium text-gray-700"
                          >
                            State
                          </label>
                          <input
                            type="text"
                            id="state"
                            value={selectedAdmission.state}
                            onChange={(e) =>
                              setSelectedAdmission({
                                ...selectedAdmission,
                                state: e.target.value,
                              })
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          City
                        </label>
                        <input
                          type="text"
                          id="city"
                          value={selectedAdmission.city}
                          onChange={(e) =>
                            setSelectedAdmission({
                              ...selectedAdmission,
                              city: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="school"
                          className="block text-sm font-medium text-gray-700"
                        >
                          School
                        </label>
                        <input
                          type="text"
                          id="school"
                          value={selectedAdmission.school}
                          onChange={(e) =>
                            setSelectedAdmission({
                              ...selectedAdmission,
                              school: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="extracurricular"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Extracurricular Activities
                        </label>
                        <textarea
                          id="extracurricular"
                          rows={3}
                          value={selectedAdmission.extracurricular}
                          onChange={(e) =>
                            setSelectedAdmission({
                              ...selectedAdmission,
                              extracurricular: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="notes"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Notes
                        </label>
                        <textarea
                          id="notes"
                          rows={3}
                          value={selectedAdmission.notes}
                          onChange={(e) =>
                            setSelectedAdmission({
                              ...selectedAdmission,
                              notes: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 transition-all duration-300"
                        />
                      </div>
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          onClick={() => setShowModal(false)}
                          className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="px-4 py-2 w-fit border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          disabled={isLoading}
                        >
                          {isLoading ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Confirmation Modal */}
        <AnimatePresence>
          {showConfirmModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white rounded-lg p-6 max-w-sm w-full"
              >
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Confirm Action
                </h3>
                <p className="text-sm text-gray-500 mb-4">
                  {confirmAction?.message}
                </p>
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setShowConfirmModal(false)}
                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={confirmActionHandler}
                    className={`px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                      confirmAction.type === "reject" ||
                      confirmAction.type === "toggle"
                        ? "bg-red-600 hover:bg-red-700 focus:ring-red-500"
                        : "bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500"
                    }`}
                  >
                    Confirm
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
