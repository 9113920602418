import React, { useState } from 'react';
import "../../cssTeacher/AttendanceTeacher.css";

function TeacherSelfAttendance() {
  const [teacherAttendance, setTeacherAttendance] = useState({
    status: '',
    leaveReason: '',
  });

  const handleTeacherAttendanceSubmit = (e) => {
    e.preventDefault();
    alert(`Teacher attendance marked as ${teacherAttendance.status}`);
    if (teacherAttendance.status === 'Leave') {
      alert(`Leave reason: ${teacherAttendance.leaveReason}`);
    }
  };

  return (
    <section className="self-attendance">
      <h2>Teacher Self Attendance</h2>
      <form onSubmit={handleTeacherAttendanceSubmit}>
        <label>
          Attendance Status:
          <select
            value={teacherAttendance.status}
            onChange={(e) =>
              setTeacherAttendance({
                ...teacherAttendance,
                status: e.target.value,
              })
            }
            required
          >
            <option value="">Select</option>
            <option value="Present">Present</option>
            <option value="Leave">Leave</option>
          </select>
        </label>

        {teacherAttendance.status === 'Leave' && (
          <label>
            Leave Reason:
            <input
              type="text"
              value={teacherAttendance.leaveReason}
              onChange={(e) =>
                setTeacherAttendance({
                  ...teacherAttendance,
                  leaveReason: e.target.value,
                })
              }
              required
            />
          </label>
        )}

        <button type="submit">Submit Attendance</button>
      </form>
    </section>
  );
}

export default TeacherSelfAttendance;
