// import React from "react";
// import Footer from "../Components/Footer";
// import { Link } from "react-router-dom";
// import Navbar from "../Components/Navbar"

// const About = () => {
//   return (
//     <>
//      <Navbar/>

//       <nav style={{ "--bs-breadcrumb-divider": "'>'" }} aria-label="breadcrumb">
//         <ol className="breadcrumb">
//           <li className="breadcrumb-item">
//             <Link to="/About">Home</Link>
//           </li>
//           <li className="breadcrumb-item active" aria-current="page">
//             About
//           </li>
//         </ol>
//       </nav>

//       <main className="container-fluid d-flex flex-column flex-md-row">
//         <div className="col-md-8 p-3">
//           <p className="align-middle">
//             PBS College Banka is located in the district head quarter, near the
//             old Mountain of Mandar. You can visualise the highly spread and
//             beautiful searise mountain of mandar from the upper roof of PBS
//             college Banka. This college was inaugurated by late Smt. Indra
//             Gandi, the then President of Congress. PBS College Banka is about
//             forty-eight K.M. from University head Quarter of TMBU Bhagalpur. The
//             very renowned named “PAP-HARNI” quoted by the seventh monarch of
//             Cholas, supposed to wash the holy feet of Goad LaxmiNarayan & Goad
//             Narshingha, is in the feet of Mandar Parwat and the visitors of PBS
//             College Banka can get a golden chance of holy deep year. It has been
//             described in the holy books of Hindus that with this Mandar Parbat,
//             the process of ‘Samudra Manthan’ was completed in which fourteen
//             Precious Ratnas including ‘Poison’ & ‘Lakshmi’ were found. This land
//             of Mandar & Banka is famous for its ‘Bankpan’. The great martyr
//             ‘Satish’ was the son of this land. This is the land which possesses
//             ‘Gurudham’ Maharshi Shyamacharan Lahri which was recently visited by
//             Hon’ble President of India. Here the great educationist Late Anand
//             Shankar Madhwan completed his meditation of literature for years &
//             established a famous educational institution named Mandar Vidyapith.
//             Banka has a unique socio-cultural structure that is a mixture of
//             Hindu, Tribal & Islamic culture. Most of the population of this
//             area, particularly the students, are rural & economically backward
//             but on the contrary this area is politically very conscious. Since
//             the days of British rule, despite natural hardships, the people of
//             this region have been excelling in achievements in the fields of
//             Science, Arts & Politics. Needless to say that Banka has produced
//             many IAS Officers such as Late T.P. Singh, Smt. Krishna Singh, Smt.
//             Radha Singh & Pankaj Kumar etc. The people of this area have a great
//             sense of patriotism, participated actively in the struggle for
//             freedom & sacrificed their lives on the altar of Mother India. PBS
//             College Banka established in 1959 is the only constituent unit of
//             TMBU, a prestigious University of Bihar established on
//             12th-July-1960 having its campus in around 250 acres of area. At
//             present, this University runs under the dynamic leadership of a
//             learned VC Prof. (Dr.) Ramashankar Dubey, who is dedicated to the
//             development of this University as well as the development of PBS
//             College Banka. The college is being run by the dedicated Principal
//             in-Charge Dr. S.C. Singh. Banka is well communicated by railway and
//             road both. Road distance from Jasidih, Deoghar to Banka is 65 KM.
//             The Vaidhnath Dwadas Jayotirlinga Temple of Deoghar is only 65 KM
//             from Banka & the Vikramsheela (Ancient University) is only 80 KM
//             from Banka.
//           </p>
//         </div>

//         <div className="col-md-4 p-3">
//           <div className="accordion" id="accordionExample">
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseOne"
//                   aria-expanded="true"
//                   aria-controls="collapseOne"
//                 >
//                   Know More About
//                 </button>
//               </h2>
//               <div
//                 id="collapseOne"
//                 className="accordion-collapse collapse show"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   <strong>PBS College Banka</strong> is located in the district
//                   head quarter, near the old Mountain of Mandar. You can
//                   visualise the highly spread and beautiful searise mountain of
//                   mandar from the upper roof of PBS college Banka. This college
//                   was inaugurated by late Smt.
//                 </div>
//               </div>
//             </div>
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseTwo"
//                   aria-expanded="false"
//                   aria-controls="collapseTwo"
//                 >
//                   PBS Ranking
//                 </button>
//               </h2>
//               <div
//                 id="collapseTwo"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   <strong>PBS College Banka</strong> is located in the district
//                   head quarter, near the old Mountain of Mandar. You can
//                   visualise the highly spread and beautiful searise mountain of
//                   mandar from the upper roof of PBS college Banka. This college
//                   was inaugurated by late Smt.
//                 </div>
//               </div>
//             </div>
//             <div className="accordion-item">
//               <h2 className="accordion-header">
//                 <button
//                   className="accordion-button collapsed"
//                   type="button"
//                   data-bs-toggle="collapse"
//                   data-bs-target="#collapseThree"
//                   aria-expanded="false"
//                   aria-controls="collapseThree"
//                 >
//                   PBS Sport
//                 </button>
//               </h2>
//               <div
//                 id="collapseThree"
//                 className="accordion-collapse collapse"
//                 data-bs-parent="#accordionExample"
//               >
//                 <div className="accordion-body">
//                   <strong>PBS College Banka</strong> is located in the district
//                   head quarter, near the old Mountain of Mandar. You can
//                   visualise the highly spread and beautiful searise mountain of
//                   mandar from the upper roof of PBS college Banka. This college
//                   was inaugurated by late Smt.
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>

//       <Footer />
//     </>
//   );
// };

// export default About;


import React from "react";
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Image,
  SimpleGrid,
  Container,
  Button,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";

const AboutUs = () => {
  return (
    <>
      {/* Hero Section */}
      <Box
        bgImage="url('/path-to-hero-image.jpg')"
        bgPosition="center"
        bgSize="cover"
        bgRepeat="no-repeat"
        py={{ base: 24, md: 32 }}
        px={{ base: 4, md: 12 }}
        textAlign="center"
        color="white"
        position="relative"
      >
        <Box bg="blackAlpha.600" p={6} borderRadius="md">
          <Heading fontSize={{ base: "3xl", md: "5xl" }}>Welcome to PBS College</Heading>
          <Text fontSize={{ base: "md", md: "lg" }} mt={4}>
            Shaping the leaders of tomorrow with excellence in education, research, and innovation.
          </Text>
        </Box>
      </Box>

      {/* Introduction Section */}
      <Container maxW="6xl" py={12} px={6}>
        <Heading mb={6} textAlign="center">
          About PBS College
        </Heading>
        <Text fontSize="lg" textAlign="justify" lineHeight={7}>
          Established in 1985, PBS College has been a pioneer in providing
          quality education and nurturing talent across various disciplines.
          Our world-class infrastructure, experienced faculty, and
          student-centered approach have made us a premier institution for
          higher education. We aim to empower our students with the skills and
          knowledge to excel in their chosen fields.
        </Text>
      </Container>

      {/* Mission, Vision, and Values */}
      <Box bg="gray.50" py={12} px={6}>
        <Container maxW="6xl">
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <Box textAlign="center">
              <Heading fontSize="xl" mb={4}>
                Our Mission
              </Heading>
              <Text>
                To provide holistic education that fosters intellectual and
                personal growth, preparing students to contribute meaningfully
                to society.
              </Text>
            </Box>
            <Box textAlign="center">
              <Heading fontSize="xl" mb={4}>
                Our Vision
              </Heading>
              <Text>
                To be a globally recognized institution renowned for academic
                excellence and impactful research.
              </Text>
            </Box>
            <Box textAlign="center">
              <Heading fontSize="xl" mb={4}>
                Our Values
              </Heading>
              <Text>
                Integrity, inclusivity, innovation, and a relentless pursuit of
                excellence.
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      {/* History Timeline */}
      <Container maxW="6xl" py={12} px={6}>
        <Heading mb={6} textAlign="center">
          Our Journey
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Box>
            <Heading fontSize="lg" mb={2}>
              1985
            </Heading>
            <Text>Founded as a small institution with 200 students.</Text>
          </Box>
          <Box>
            <Heading fontSize="lg" mb={2}>
              2000
            </Heading>
            <Text>Expanded to include a School of Engineering and Sciences.</Text>
          </Box>
          <Box>
            <Heading fontSize="lg" mb={2}>
              2015
            </Heading>
            <Text>Recognized as a National Center of Excellence in Education.</Text>
          </Box>
          <Box>
            <Heading fontSize="lg" mb={2}>
              Present
            </Heading>
            <Text>Continues to shape future leaders with over 10,000 students.</Text>
          </Box>
        </SimpleGrid>
      </Container>

      {/* Why Choose Us */}
      <Box bg="blue.600" py={12} px={6} color="white">
        <Container maxW="6xl">
          <Heading mb={6} textAlign="center">
            Why Choose Us?
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <Box>
              <Heading fontSize="lg" mb={4}>
                Experienced Faculty
              </Heading>
              <Text>
                Learn from the best minds in the industry and academia.
              </Text>
            </Box>
            <Box>
              <Heading fontSize="lg" mb={4}>
                State-of-the-Art Facilities
              </Heading>
              <Text>
                Modern labs, libraries, and classrooms for enhanced learning.
              </Text>
            </Box>
            <Box>
              <Heading fontSize="lg" mb={4}>
                Vibrant Campus Life
              </Heading>
              <Text>
                A wide range of extracurricular activities to nurture talents.
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      {/* Footer Call-to-Action */}
      <Container maxW="6xl" py={12} px={6} textAlign="center">
        <Heading mb={4}>Join Us at PBS College</Heading>
        <Text fontSize="lg" mb={6}>
          Be a part of an enriching journey of academic excellence and personal
          growth. Apply now and take the first step toward a brighter future.
        </Text>
        <Button colorScheme="blue" size="lg" as="a" href="/Admissions">
          Apply for Admission
        </Button>
      </Container>
    </>
  );
};

export default AboutUs;
