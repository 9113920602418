/* eslint-disable react/prop-types */
"use client";

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Search,
  Plus,
  Edit,
  Trash2,
  Calendar,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  Clock,
  Users,
} from "lucide-react";

const ExtraActivities = () => {
  const [activities, setActivities] = useState([
    {
      id: 1,
      name: "Chess Club",
      category: "Academic",
      schedule: "Monday, Wednesday 4-5 PM",
      participants: 15,
    },
    {
      id: 2,
      name: "Football Team",
      category: "Sports",
      schedule: "Tuesday, Thursday 3-5 PM",
      participants: 22,
    },
    {
      id: 3,
      name: "Drama Club",
      category: "Arts",
      schedule: "Friday 3-6 PM",
      participants: 18,
    },
    {
      id: 4,
      name: "Robotics Club",
      category: "Technology",
      schedule: "Saturday 10 AM - 1 PM",
      participants: 12,
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [showCalendarView, setShowCalendarView] = useState(false);

  const categories = ["All", "Academic", "Sports", "Arts", "Technology"];

  const filteredActivities = activities.filter(
    (activity) =>
      (selectedCategory === "All" || activity.category === selectedCategory) &&
      (activity.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        activity.category.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const sortedActivities = [...filteredActivities].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const handleCreateActivity = () => {
    setModalMode("create");
    setSelectedActivity({
      id: activities.length + 1,
      name: "",
      category: "Academic",
      schedule: "",
      participants: 0,
    });
    setShowModal(true);
  };

  const handleEditActivity = (activity) => {
    setModalMode("edit");
    setSelectedActivity(activity);
    setShowModal(true);
  };

  const handleDeleteActivity = (id) => {
    setActivities(activities.filter((activity) => activity.id !== id));
  };

  const handleSaveActivity = (activity) => {
    if (modalMode === "create") {
      setActivities([...activities, activity]);
    } else {
      setActivities(
        activities.map((a) => (a.id === activity.id ? activity : a))
      );
    }
    setShowModal(false);
  };

  const ActivityModal = ({ activity, onSave, onClose }) => (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-lg p-6 w-full max-w-md"
      >
        <h2 className="text-2xl font-bold mb-4">
          {modalMode === "create" ? "Create" : "Edit"} Activity
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSave(activity);
          }}
        >
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              value={activity.name}
              onChange={(e) =>
                setSelectedActivity({ ...activity, name: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Category
            </label>
            <select
              value={activity.category}
              onChange={(e) =>
                setSelectedActivity({ ...activity, category: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              {categories
                .filter((cat) => cat !== "All")
                .map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Schedule
            </label>
            <input
              type="text"
              value={activity.schedule}
              onChange={(e) =>
                setSelectedActivity({ ...activity, schedule: e.target.value })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Participants
            </label>
            <input
              type="number"
              value={activity.participants}
              onChange={(e) =>
                setSelectedActivity({
                  ...activity,
                  participants: parseInt(e.target.value),
                })
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );

  const CalendarView = () => (
    <div className="bg-white rounded-lg shadow p-4 sm:p-6">
      <h2 className="text-xl sm:text-2xl font-bold mb-4">Activity Calendar</h2>
      <div className="grid grid-cols-7 gap-2">
        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
          <div key={day} className="text-center font-semibold text-sm">
            {day}
          </div>
        ))}
        {Array.from({ length: 35 }, (_, i) => (
          <div
            key={i}
            className="border rounded-md p-2 h-20 sm:h-24 overflow-y-auto"
          >
            <div className="text-xs text-gray-500">{i + 1}</div>
            {activities
              .filter((activity) =>
                activity.schedule.includes(
                  [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                    "Sunday",
                  ][i % 7]
                )
              )
              .map((activity) => (
                <div
                  key={activity.id}
                  className="text-xs bg-indigo-100 rounded px-1 py-0.5 mb-1 truncate"
                >
                  {activity.name}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-purple-50 p-4 sm:p-6 md:p-8">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white rounded-xl shadow-lg overflow-hidden mb-6"
        >
          {/* Header Section */}
          <div className="p-4 sm:p-6 bg-gradient-to-r from-indigo-600 to-purple-600">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white">
              Extra Activities Dashboard
            </h1>
            <p className="mt-2 text-sm sm:text-base text-indigo-100">
              Manage and track extra-curricular activities
            </p>
          </div>

          {/* Controls Section */}
          <div className="p-4 sm:p-6">
            <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:justify-between sm:items-center mb-6">
              {/* Search Bar */}
              <div className="relative w-full sm:w-64 md:w-72">
                <input
                  type="text"
                  placeholder="Search activities..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full !pl-10 !mb-0 pr-4 py-2 text-sm rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
                <Search className="absolute left-3 sm:top-2.5 top-3 h-4 w-4 text-gray-400" />
              </div>

              {/* Action Buttons */}
              <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="px-4 py-2 text-sm rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleCreateActivity}
                  className="text-nowrap px-4 py-2 bg-indigo-600 text-white text-sm rounded-full hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 flex items-center justify-center"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  Add Activity
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setShowCalendarView(!showCalendarView)}
                  className="text-nowrap px-4 py-2 bg-purple-600 text-white text-sm rounded-full hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 flex items-center justify-center"
                >
                  <Calendar className="w-4 h-4 mr-2" />
                  {showCalendarView ? "List View" : "Calendar View"}
                </motion.button>
              </div>
            </div>

            {/* Content Section */}
            <AnimatePresence mode="wait">
              {showCalendarView ? (
                <motion.div
                  key="calendar"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="overflow-x-auto"
                >
                  <div className="min-w-[768px]">
                    <CalendarView />
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  key="list"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="overflow-x-auto"
                >
                  {/* Mobile List View */}
                  <div className="block sm:hidden">
                    {sortedActivities.map((activity) => (
                      <motion.div
                        key={activity.id}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="bg-white rounded-lg shadow-sm p-4 mb-4 border border-gray-100"
                      >
                        <div className="flex justify-between items-start mb-2">
                          <h3 className="font-medium text-gray-900">
                            {activity.name}
                          </h3>
                          <div className="flex space-x-2">
                            <button
                              onClick={() => handleEditActivity(activity)}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              <Edit className="w-4 h-4" />
                            </button>
                            <button
                              onClick={() => handleDeleteActivity(activity.id)}
                              className="text-red-600 hover:text-red-900"
                            >
                              <Trash2 className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                        <div className="space-y-1 text-sm">
                          <div className="flex items-center text-gray-500">
                            <span className="px-2 py-1 text-xs rounded-full bg-indigo-100 text-indigo-800">
                              {activity.category}
                            </span>
                          </div>
                          <div className="flex items-center text-gray-500">
                            <Clock className="w-4 h-4 mr-2" />
                            {activity.schedule}
                          </div>
                          <div className="flex items-center text-gray-500">
                            <Users className="w-4 h-4 mr-2" />
                            {activity.participants} participants
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>

                  {/* Desktop Table View */}
                  <div className="hidden sm:block min-w-[768px]">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {[
                            "Name",
                            "Category",
                            "Schedule",
                            "Participants",
                            "Actions",
                          ].map((header) => (
                            <th
                              key={header}
                              onClick={() => handleSort(header.toLowerCase())}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                            >
                              <div className="flex items-center">
                                {header}
                                {sortConfig.key === header.toLowerCase() &&
                                  (sortConfig.direction === "ascending" ? (
                                    <ChevronUp className="w-4 h-4 ml-1" />
                                  ) : (
                                    <ChevronDown className="w-4 h-4 ml-1" />
                                  ))}
                              </div>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {sortedActivities.map((activity) => (
                          <motion.tr
                            key={activity.id}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="hover:bg-gray-50"
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                {activity.name}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className="px-2 py-1 text-xs rounded-full bg-indigo-100 text-indigo-800">
                                {activity.category}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-500">
                                {activity.schedule}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {activity.participants}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button
                                onClick={() => handleEditActivity(activity)}
                                className="text-indigo-600 hover:text-indigo-900 mr-2"
                              >
                                <Edit className="w-4 h-4" />
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteActivity(activity.id)
                                }
                                className="text-red-600 hover:text-red-900"
                              >
                                <Trash2 className="w-4 h-4" />
                              </button>
                            </td>
                          </motion.tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </div>

      {/* Modal Component */}
      <AnimatePresence>
        {showModal && selectedActivity && (
          <ActivityModal
            activity={selectedActivity}
            onSave={handleSaveActivity}
            onClose={() => setShowModal(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ExtraActivities;
