import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  VStack,
  HStack,
  useColorModeValue,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';

const ReportCardPage = () => {
  // Sample student data
  const student = {
    name: 'John Doe',
    rollNumber: '12345',
    course: 'Computer Science',
    subjects: [
      { name: 'Mathematics', marks: 85, totalMarks: 100 },
      { name: 'Physics', marks: 78, totalMarks: 100 },
      { name: 'Chemistry', marks: 88, totalMarks: 100 },
      { name: 'Biology', marks: 92, totalMarks: 100 },
      { name: 'English', marks: 75, totalMarks: 100 },
    ],
  };

  // Calculate the total marks and percentage
  const totalMarks = student.subjects.reduce((acc, subject) => acc + subject.marks, 0);
  const totalMaxMarks = student.subjects.reduce((acc, subject) => acc + subject.totalMarks, 0);
  const percentage = ((totalMarks / totalMaxMarks) * 100).toFixed(2);

  const toast = useToast();
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  const handleDownloadReportCard = () => {
    toast({
      title: 'Report Card Downloaded',
      description: 'The report card has been downloaded successfully!',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    // Implement PDF download functionality here
  };

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading size={headingSize} textAlign="center" color={useColorModeValue('teal.600', 'teal.300')}>
          Student Report Card
        </Heading>

        <Box borderWidth="1px" borderRadius="lg" boxShadow="md" p={6} bg={useColorModeValue('white', 'gray.800')}>
          <VStack spacing={4} align="stretch">
            <Text fontSize="lg" color={useColorModeValue('gray.700', 'gray.300')}>
              <strong>Name:</strong> {student.name}
            </Text>
            <Text fontSize="lg" color={useColorModeValue('gray.700', 'gray.300')}>
              <strong>Roll Number:</strong> {student.rollNumber}
            </Text>
            <Text fontSize="lg" color={useColorModeValue('gray.700', 'gray.300')}>
              <strong>Course:</strong> {student.course}
            </Text>

            <Heading size="md" color={useColorModeValue('teal.600', 'teal.300')}>
              Subject-wise Marks
            </Heading>

            <Table variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>Subject</Th>
                  <Th>Marks Obtained</Th>
                  <Th>Total Marks</Th>
                  <Th>Percentage</Th>
                </Tr>
              </Thead>
              <Tbody>
                {student.subjects.map((subject, index) => (
                  <Tr key={index}>
                    <Td>{subject.name}</Td>
                    <Td>{subject.marks}</Td>
                    <Td>{subject.totalMarks}</Td>
                    <Td>{((subject.marks / subject.totalMarks) * 100).toFixed(2)}%</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            <HStack justify="space-between" mt={6}>
              <Box>
                <Text fontSize="lg" fontWeight="bold" color={useColorModeValue('gray.700', 'gray.300')}>
                  Total Marks: {totalMarks} / {totalMaxMarks}
                </Text>
                <Text fontSize="lg" fontWeight="bold" color={useColorModeValue('gray.700', 'gray.300')}>
                  Percentage: {percentage}%
                </Text>
              </Box>

              <Button
                colorScheme="teal"
                variant="solid"
                size="lg"
                leftIcon={<DownloadIcon />}
                onClick={handleDownloadReportCard}
              >
                Download Report Card
              </Button>
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default ReportCardPage;
