export const initialAdmins = [
    {
      id: 'ADM001',
      name: 'Ayush Verma',
      email: 'ayushverma@school.edu',
      phone: '+91 98765 43210',
      role: 'Super Admin',
      department: 'Administration',
      joinDate: '2024-01-15',
      status: 'Active',
      profileImage: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400&h=400&fit=crop',
    },
    {
      id: 'ADM002',
      name: 'Avinash kumar',
      email: 'Avinashkumar@school.edu',
      phone: '+91 87654 32109',
      role: 'Admin',
      department: 'Academic Affairs',
      joinDate: '2024-02-01',
      status: 'Active',
      profileImage: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400&h=400&fit=crop',
    },
  ]