import React from "react";
import { Box, Container, Grid, Heading, Image } from "@chakra-ui/react";
import award_1 from "../assets/lps_award1.png";
import award_2 from "../assets/lps_award2.png";
import award_3 from "../assets/lps_award3.png";

const Award = () => {
  return (
    <Box py="16" bg="gray.100">
      <Container maxW="container.xl">
        <Box textAlign="left" mb="8">
          <Heading as="h2" size="xl" color="gray.800" mb="4">
            Awards - PBS College Banka in Bihar
          </Heading>
        </Box>
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
          gap={6}
        >
          <Box
            bg="white"
            p="4"
            borderRadius="lg"
            boxShadow="md"
            _hover={{ boxShadow: "lg" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Image src={award_1} alt="Award 1" borderRadius="md" />
          </Box>
          <Box
            bg="white"
            p="4"
            borderRadius="lg"
            boxShadow="md"
            _hover={{ boxShadow: "lg" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Image src={award_2} alt="Award 2" borderRadius="md" />
          </Box>
          <Box
            bg="white"
            p="4"
            borderRadius="lg"
            boxShadow="md"
            _hover={{ boxShadow: "lg" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
          >
            <Image src={award_3} alt="Award 3" borderRadius="md" />
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Award;
