import React from "react";
import { useNavigate } from "react-router-dom";
import {
  FaClipboardList,
  FaBell,
  FaCalendar,
  FaClipboardCheck,
  FaBook,
  FaGraduationCap,
} from "react-icons/fa";
import "../Student/Studentcss/SliderStudents.css";

function SidebarStudent() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="sidebar bg-white-800 text-gray p-4">
      <h2 className="text-xl font-bold mb-6 sidebar-title">Student Dashboard</h2>
      <ul className="space-y-4">
        <li onClick={() => handleNavigation("/ProfileStudents")} className="cursor-pointer">
          <FaClipboardList className="icon mr-2" />
          <span>Profile</span>
        </li>
        <li onClick={() => handleNavigation("/AttendanceStudents")} className="cursor-pointer">
          <FaClipboardList className="icon mr-2" />
          <span>Attendance</span>
        </li>
        {/* <li onClick={() => handleNavigation("/")} className="cursor-pointer">
          <FaBell className="icon mr-2" />
          <span>Notification</span>
        </li> */}
        {/* <li onClick={() => handleNavigation("/calendar")} className="cursor-pointer">
          <FaCalendar className="icon mr-2" />
          <span>Calendar</span>
        </li>
        <li onClick={() => handleNavigation("/schedule")} className="cursor-pointer">
          <FaCalendar className="icon mr-2" />
          <span>Schedule</span>
        </li> */}
        {/* <li onClick={() => handleNavigation("/Syllabus")} className="cursor-pointer">
          <FaBook className="icon mr-2" />
          <span>Syllabus</span>
        </li> */}
        {/* <li onClick={() => handleNavigation("student/Assignments")} className="cursor-pointer">
          <FaClipboardCheck className="icon mr-2" />
          <span>Assignment</span>
        </li> */}
        {/* <li onClick={() => handleNavigation("/test")} className="cursor-pointer">
          <FaClipboardList className="icon mr-2" />
          <span>Test</span>
        </li> */}
        <li onClick={() => handleNavigation("/Studentexam")} className="cursor-pointer">
          <FaGraduationCap className="icon mr-2" />
          <span>Exam</span>
        </li>
        <li onClick={() => handleNavigation("/ResultStudents")} className="cursor-pointer">
          <FaGraduationCap className="icon mr-2" />
          <span>Result</span>
        </li>
        <li onClick={() => handleNavigation("/FeesStudents")} className="cursor-pointer">
          <FaGraduationCap className="icon mr-2" />
          <span>Fees</span>
        </li>
        <li onClick={() => handleNavigation("/SportStudents")} className="cursor-pointer">
          <FaGraduationCap className="icon mr-2" />
          <span>Sport</span>
        </li>
        <li onClick={() => handleNavigation("/LibarryStudents")} className="cursor-pointer">
          <FaGraduationCap className="icon mr-2" />
          <span>Library</span>
        </li>
        <li onClick={() => handleNavigation("/TransportStudents")} className="cursor-pointer">
          <FaGraduationCap className="icon mr-2" />
          <span>Transport</span>
        </li>
        <li onClick={() => handleNavigation("/TimeTableStudents")} className="cursor-pointer">
          <FaGraduationCap className="icon mr-2" />
          <span>Time Table</span>
        </li>
        <li onClick={() => handleNavigation("/ReportStudents")} className="cursor-pointer">
          <FaGraduationCap className="icon mr-2" />
          <span>Report</span>
        </li>
       
      </ul>
    </div>
  );
}

export default SidebarStudent;
