import React, { useState } from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  Button,
  IconButton,
  HStack,
  useColorModeValue,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Progress,
  useToast,
} from "@chakra-ui/react";
import { DownloadIcon, ArrowUpIcon } from "@chakra-ui/icons";

const AssignmentsPage = () => {
  const toast = useToast();
  const [file, setFile] = useState(null);
  const assignments = [
    {
      id: 1,
      title: "Math Assignment",
      description: "Complete the exercises in Chapter 3.",
      dueDate: "2024-11-15",
      fileLink: "/path/to/math_assignment.pdf",
    },
    {
      id: 2,
      title: "Physics Project",
      description: "Prepare a report on Newton’s Laws of Motion.",
      dueDate: "2024-11-20",
      fileLink: "/path/to/physics_project.pdf",
    },
    // Add more assignments as needed
  ];

  // Handle file input change
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  // Handle file upload
  const handleUpload = (assignmentId) => {
    if (!file) {
      toast({
        title: "No file selected.",
        description: "Please select a file before uploading.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Here you would add your file upload logic
    // For now, we'll just display a success message
    toast({
      title: "File uploaded successfully!",
      description: `Your file for assignment ID ${assignmentId} has been uploaded.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setFile(null); // Clear file after upload
  };

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading
          size="lg"
          textAlign="center"
          color={useColorModeValue("teal.600", "teal.300")}
        >
          Student Assignments
        </Heading>

        <Table variant="striped" colorScheme="teal">
          <Thead>
            <Tr>
              <Th>Title</Th>
              <Th>Description</Th>
              <Th>Due Date</Th>
              <Th>Download</Th>
              <Th>Upload</Th>
            </Tr>
          </Thead>
          <Tbody>
            {assignments.map((assignment) => (
              <Tr key={assignment.id}>
                <Td>{assignment.title}</Td>
                <Td>{assignment.description}</Td>
                <Td>{assignment.dueDate}</Td>
                <Td>
                  <IconButton
                    as="a"
                    href={assignment.fileLink}
                    download
                    icon={<DownloadIcon />}
                    colorScheme="teal"
                    aria-label={`Download ${assignment.title}`}
                  />
                </Td>
                <Td>
                  <HStack>
                    <Input
                      type="file"
                      variant="unstyled"
                      onChange={handleFileChange}
                      accept=".pdf,.doc,.docx"
                    />
                    <Button
                      onClick={() => handleUpload(assignment.id)}
                      colorScheme="teal"
                      leftIcon={<ArrowUpIcon />}
                    >
                      Upload
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>
    </Container>
  );
};

export default AssignmentsPage;
