import React from 'react';
import '../cssTeacher/AccountTeachers.css';

function AccountTeachers() {

  const payments = [
    {
      id: 1,
      type: "Transfer Payment",
      details: [
        { date: "2024-10-01", amount: 5000, by: "John Doe", to: "School Fund" },
        { date: "2024-10-15", amount: 2500, by: "Jane Smith", to: "Library Fund" },
      ],
    },
    {
      id: 2,
      type: "Credit Payment",
      details: [
        { date: "2024-09-10", amount: 1500, by: "Alice Johnson", to: "Sports Fund" },
      ],
    },
    {
      id: 3,
      type: "Debit Payment",
      details: [
        { date: "2024-08-20", amount: 3000, by: "Mark Wilson", to: "Lab Equipment" },
      ],
    },
    {
      id: 4,
      type: "Admission Payment",
      details: [
        { date: "2024-07-05", amount: 10000, by: "Emily Brown", to: "Admissions Office" },
      ],
    },
    {
      id: 5,
      type: "Exam Payment",
      details: [
        { date: "2024-06-15", amount: 1200, by: "Paul Green", to: "Examination Department" },
      ],
    },
  ];

  return (
    <div className="account-teachers-container">
      <h2 className="account-teachers-heading">Account Teachers - Payment Details</h2>
      <div className="payment-section">
        {payments.map((payment) => (
          <div key={payment.id} className="payment-type">
            <h3 className="payment-title">{payment.type}</h3>
            <table className="payment-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>By</th>
                  <th>To</th>
                </tr>
              </thead>
              <tbody>
                {payment.details.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.date}</td>
                    <td>{detail.amount}</td>
                    <td>{detail.by}</td>
                    <td>{detail.to}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AccountTeachers;
