// store.js
import { configureStore } from '@reduxjs/toolkit';
import admissionReducer from './slice/admissionSlice'; // Ensure the path is correct
import userReducer from './slice/userSlice';
const store = configureStore({
  reducer: {
    users: userReducer, // Match this key with the key used in `useSelector`
    admissions: admissionReducer, // This should point to a valid reducer function
  },
});

export default store;