import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Book,
  ChevronDown,
  ChevronUp,
  Edit,
  Plus,
  Trash,
  Download,
  CheckCircle,
  Calendar,
  User,
  BarChart,
} from "lucide-react";

const classes = ["9", "10", "11", "12"];
const subjects = {
  9: [
    "Mathematics",
    "Science",
    "English",
    "Hindi",
    "Social Studies",
    "Computer Science",
  ],
  10: [
    "Mathematics",
    "Science",
    "English",
    "Hindi",
    "Social Studies",
    "Computer Science",
  ],
  11: [
    "Physics",
    "Chemistry",
    "Mathematics",
    "English",
    "Computer Science",
    "Physical Education",
  ],
  12: [
    "Physics",
    "Chemistry",
    "Mathematics",
    "English",
    "Computer Science",
    "Physical Education",
  ],
};

const initialSyllabus = {
  9: {
    Mathematics: [
      {
        unit: "Algebra",
        topics: ["Linear Equations", "Quadratic Equations"],
        progress: 60,
        resources: ["https://example.com/algebra-notes"],
      },
      {
        unit: "Geometry",
        topics: ["Triangles", "Circles"],
        progress: 40,
        resources: ["https://example.com/geometry-videos"],
      },
    ],
    Science: [
      {
        unit: "Physics",
        topics: ["Motion", "Force and Laws of Motion"],
        progress: 75,
        resources: ["https://example.com/physics-simulations"],
      },
      {
        unit: "Chemistry",
        topics: ["Matter in Our Surroundings", "Is Matter Around Us Pure"],
        progress: 50,
        resources: ["https://example.com/chemistry-experiments"],
      },
    ],
  },
  10: {
    Mathematics: [
      {
        unit: "Algebra",
        topics: ["Polynomials", "Pair of Linear Equations in Two Variables"],
        progress: 80,
        resources: ["https://example.com/algebra-10-notes"],
      },
      {
        unit: "Geometry",
        topics: ["Triangles", "Circles"],
        progress: 65,
        resources: ["https://example.com/geometry-10-videos"],
      },
    ],
    Science: [
      {
        unit: "Physics",
        topics: ["Electricity", "Magnetic Effects of Electric Current"],
        progress: 70,
        resources: ["https://example.com/physics-10-simulations"],
      },
      {
        unit: "Chemistry",
        topics: ["Chemical Reactions and Equations", "Acids, Bases and Salts"],
        progress: 55,
        resources: ["https://example.com/chemistry-10-experiments"],
      },
    ],
  },
  11: {
    Physics: [
      {
        unit: "Mechanics",
        topics: ["Units and Measurements", "Motion in a Straight Line"],
        progress: 40,
        resources: ["https://example.com/physics-11-mechanics"],
      },
      {
        unit: "Thermodynamics",
        topics: ["Thermal Properties of Matter", "Thermodynamics"],
        progress: 30,
        resources: ["https://example.com/physics-11-thermodynamics"],
      },
    ],
    Chemistry: [
      {
        unit: "Physical Chemistry",
        topics: ["Some Basic Concepts of Chemistry", "Structure of Atom"],
        progress: 50,
        resources: ["https://example.com/chemistry-11-physical"],
      },
      {
        unit: "Organic Chemistry",
        topics: [
          "Classification and Nomenclature of Organic Compounds",
          "Isomerism",
        ],
        progress: 35,
        resources: ["https://example.com/chemistry-11-organic"],
      },
    ],
  },
  12: {
    Physics: [
      {
        unit: "Electrostatics",
        topics: [
          "Electric Charges and Fields",
          "Electrostatic Potential and Capacitance",
        ],
        progress: 60,
        resources: ["https://example.com/physics-12-electrostatics"],
      },
      {
        unit: "Optics",
        topics: ["Ray Optics and Optical Instruments", "Wave Optics"],
        progress: 45,
        resources: ["https://example.com/physics-12-optics"],
      },
    ],
    Chemistry: [
      {
        unit: "Solid State",
        topics: ["Classification of Solids", "Unit Cell"],
        progress: 70,
        resources: ["https://example.com/chemistry-12-solid-state"],
      },
      {
        unit: "Electrochemistry",
        topics: ["Redox Reactions", "EMF of a Cell"],
        progress: 55,
        resources: ["https://example.com/chemistry-12-electrochemistry"],
      },
    ],
  },
};

const teachers = {
  Mathematics: "Dr. Sharma",
  Science: "Mrs. Gupta",
  English: "Mr. Patel",
  Hindi: "Ms. Singh",
  "Social Studies": "Mr. Kumar",
  "Computer Science": "Mrs. Reddy",
  Physics: "Dr. Joshi",
  Chemistry: "Mr. Verma",
  "Physical Education": "Ms. Choudhury",
};

const exams = {
  9: [
    { name: "Half Yearly", date: "2024-09-15" },
    { name: "Annual", date: "2025-03-10" },
  ],
  10: [
    { name: "Pre-Board", date: "2024-12-05" },
    { name: "Board Exam", date: "2025-03-01" },
  ],
  11: [
    { name: "Half Yearly", date: "2024-09-20" },
    { name: "Annual", date: "2025-03-15" },
  ],
  12: [
    { name: "Pre-Board I", date: "2024-11-25" },
    { name: "Pre-Board II", date: "2025-01-15" },
    { name: "Board Exam", date: "2025-03-01" },
  ],
};

const SyllabusManagement = () => {
  const [selectedClass, setSelectedClass] = useState("9");
  const [syllabus, setSyllabus] = useState(initialSyllabus);
  const [expandedSubject, setExpandedSubject] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [studentPerformance, setStudentPerformance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const fetchStudentPerformance = async () => {
      setIsLoading(true);
      try {
        // Simulating API call
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const mockData = {};
        classes.forEach((classNum) => {
          mockData[classNum] = {};
          subjects[classNum].forEach((subject) => {
            mockData[classNum][subject] = Math.floor(Math.random() * 41) + 60; // Random score between 60 and 100
          });
        });
        setStudentPerformance(mockData);
      } catch (error) {
        console.error("Error fetching student performance:", error);
        setNotification({
          type: "error",
          message: "Failed to load student performance data",
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchStudentPerformance();
  }, []);

  const handleProgressUpdate = (classNum, subject, unitIndex, newProgress) => {
    setSyllabus((prevSyllabus) => ({
      ...prevSyllabus,
      [classNum]: {
        ...prevSyllabus[classNum],
        [subject]: prevSyllabus[classNum][subject].map((unit, index) =>
          index === unitIndex ? { ...unit, progress: newProgress } : unit
        ),
      },
    }));
  };

  const handleAddUnit = (classNum, subject) => {
    setSyllabus((prevSyllabus) => ({
      ...prevSyllabus,
      [classNum]: {
        ...prevSyllabus[classNum],
        [subject]: [
          ...(prevSyllabus[classNum][subject] || []),
          { unit: "New Unit", topics: [], progress: 0, resources: [] },
        ],
      },
    }));
  };

  const handleDeleteUnit = (classNum, subject, unitIndex) => {
    setSyllabus((prevSyllabus) => ({
      ...prevSyllabus,
      [classNum]: {
        ...prevSyllabus[classNum],
        [subject]: prevSyllabus[classNum][subject].filter(
          (_, index) => index !== unitIndex
        ),
      },
    }));
  };

  const handleEditUnit = (classNum, subject, unitIndex, field, value) => {
    setSyllabus((prevSyllabus) => ({
      ...prevSyllabus,
      [classNum]: {
        ...prevSyllabus[classNum],
        [subject]: prevSyllabus[classNum][subject].map((unit, index) =>
          index === unitIndex ? { ...unit, [field]: value } : unit
        ),
      },
    }));
  };

  const getOverallProgress = (classNum, subject) => {
    const units = syllabus[classNum]?.[subject];
    if (!units || units.length === 0) return 0;
    const totalProgress = units.reduce(
      (sum, unit) => sum + (unit.progress || 0),
      0
    );
    return Math.round(totalProgress / units.length);
  };

  const getProgressColor = (progress) => {
    if (progress < 30) return "bg-red-500";
    if (progress < 70) return "bg-yellow-500";
    return "bg-green-500";
  };

  const handleSaveSyllabus = async () => {
    setIsLoading(true);
    try {
      // Simulating API call to save syllabus
      await new Promise((resolve) => setTimeout(resolve, 1500));
      setEditMode(false);
      setNotification({
        type: "success",
        message: "Syllabus saved successfully",
      });
    } catch (error) {
      console.error("Error saving syllabus:", error);
      setNotification({ type: "error", message: "Failed to save syllabus" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportSyllabus = async () => {
    setIsLoading(true);
    try {
      // Simulating API call to export syllabus
      await new Promise((resolve) => setTimeout(resolve, 1500));
      const syllabusJson = JSON.stringify(syllabus, null, 2);
      const blob = new Blob([syllabusJson], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "syllabus.json";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      setNotification({
        type: "success",
        message: "Syllabus exported successfully",
      });
    } catch (error) {
      console.error("Error exporting syllabus:", error);
      setNotification({ type: "error", message: "Failed to export syllabus" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 via-white to-purple-50 p-4 md:p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <h1 className="text-xl sm:text-3xl md:text-4xl font-bold text-gray-800 mb-4 md:mb-0">
            Syllabus Management
          </h1>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`bg-indigo-600 text-white rounded-full px-4 py-2 shadow-md hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={editMode ? handleSaveSyllabus : () => setEditMode(true)}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : editMode ? (
                <>
                  <CheckCircle className="w-5 h-5 mr-2" />
                  Save Changes
                </>
              ) : (
                <>
                  <Edit className="w-5 h-5 mr-2" />
                  Edit Syllabus
                </>
              )}
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`bg-green-600 text-white rounded-full px-4 py-2 shadow-md hover:bg-green-700 transition-colors duration-300 flex items-center justify-center ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleExportSyllabus}
              disabled={isLoading}
            >
              {isLoading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <>
                  <Download className="w-5 h-5 mr-2" />
                  Export Syllabus
                </>
              )}
            </motion.button>
          </div>
        </div>

        {notification && (
          <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            className={`mb-4 p-4 rounded-md ${
              notification.type === "success"
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800"
            }`}
          >
            {notification.message}
          </motion.div>
        )}

        <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-6">
          <div className="p-6 bg-gradient-to-r from-indigo-600 to-purple-600">
            <div className="flex flex-wrap items-center justify-between gap-4">
              <div className="flex flex-wrap justify-center md:justify-start space-x-2 space-y-3 sm:space-y-0">
                {classes.map((classNum) => (
                  <motion.button
                    key={classNum}
                    onClick={() => setSelectedClass(classNum)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300 ${
                      selectedClass === classNum
                        ? "bg-white text-indigo-600 hover:bg-indigo-50"
                        : "bg-indigo-500 text-white hover:bg-indigo-400"
                    }`}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Class {classNum}
                  </motion.button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          <div className="lg:col-span-2">
            <AnimatePresence mode="wait">
              <motion.div
                key={selectedClass}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="bg-white rounded-2xl shadow-lg overflow-hidden"
              >
                <div className="p-6">
                  <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                    Class {selectedClass} Syllabus
                  </h2>
                  {subjects[selectedClass].map((subject) => (
                    <div key={subject} className="mb-6 last:mb-0">
                      <div
                        className="flex items-center justify-between flex-col sm:flex-row gap-3 sm:gap-0 bg-gray-100 p-4 rounded-lg cursor-pointer"
                        onClick={() =>
                          setExpandedSubject(
                            expandedSubject === subject ? null : subject
                          )
                        }
                      >
                        <div className="flex items-center">
                          <Book className="w-6 h-6 text-indigo-600 mr-3" />
                          <span className="font-semibold text-gray-800">
                            {subject}
                          </span>
                        </div>
                        <div className="flex items-center">
                          <div className="mr-4">
                            <div className="text-sm text-gray-600">
                              Overall Progress
                            </div>
                            <div className="flex items-center">
                              <div className="w-32 h-2 bg-gray-300 rounded-full mr-2">
                                <div
                                  className={`h-full rounded-full ${getProgressColor(
                                    getOverallProgress(selectedClass, subject)
                                  )}`}
                                  style={{
                                    width: `${getOverallProgress(
                                      selectedClass,
                                      subject
                                    )}%`,
                                  }}
                                ></div>
                              </div>
                              <span className="text-sm font-medium text-gray-700">
                                {getOverallProgress(selectedClass, subject)}%
                              </span>
                            </div>
                          </div>
                          {expandedSubject === subject ? (
                            <ChevronUp className="w-5 h-5 text-gray-600" />
                          ) : (
                            <ChevronDown className="w-5 h-5 text-gray-600" />
                          )}
                        </div>
                      </div>
                      <AnimatePresence>
                        {expandedSubject === subject && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                          >
                            <div className="mt-4 space-y-4">
                              {syllabus[selectedClass]?.[subject]?.map(
                                (unit, unitIndex) => (
                                  <div
                                    key={unitIndex}
                                    className="bg-gray-50 p-4 rounded-lg"
                                  >
                                    <div className="flex justify-between items-center mb-2">
                                      <h4 className="font-medium text-gray-800">
                                        {unit.unit}
                                      </h4>
                                      {editMode && (
                                        <div className="flex space-x-2">
                                          <button
                                            onClick={() =>
                                              handleEditUnit(
                                                selectedClass,
                                                subject,
                                                unitIndex,
                                                "unit",
                                                prompt(
                                                  "Enter new unit name:",
                                                  unit.unit
                                                )
                                              )
                                            }
                                            className="text-blue-600 hover:text-blue-800"
                                          >
                                            <Edit className="w-4 h-4" />
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleDeleteUnit(
                                                selectedClass,
                                                subject,
                                                unitIndex
                                              )
                                            }
                                            className="text-red-600 hover:text-red-800"
                                          >
                                            <Trash className="w-4 h-4" />
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    <div className="text-sm text-gray-600 mb-2">
                                      Topics: {unit.topics.join(", ")}
                                      {editMode && (
                                        <button
                                          onClick={() =>
                                            handleEditUnit(
                                              selectedClass,
                                              subject,
                                              unitIndex,
                                              "topics",
                                              prompt(
                                                "Enter topics (comma-separated):",
                                                unit.topics.join(", ")
                                              )
                                                .split(",")
                                                .map((t) => t.trim())
                                            )
                                          }
                                          className="ml-2 text-blue-600 hover:text-blue-800"
                                        >
                                          <Edit className="w-4 h-4 inline" />
                                        </button>
                                      )}
                                    </div>
                                    <div className="flex items-center mb-2">
                                      <div className="w-full h-2 bg-gray-300 rounded-full mr-2">
                                        <div
                                          className={`h-full rounded-full ${getProgressColor(
                                            unit.progress
                                          )}`}
                                          style={{ width: `${unit.progress}%` }}
                                        ></div>
                                      </div>
                                      <span className="text-sm font-medium text-gray-700">
                                        {unit.progress}%
                                      </span>
                                    </div>
                                    {editMode && (
                                      <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={unit.progress}
                                        onChange={(e) =>
                                          handleProgressUpdate(
                                            selectedClass,
                                            subject,
                                            unitIndex,
                                            parseInt(e.target.value)
                                          )
                                        }
                                        className="w-full"
                                      />
                                    )}
                                    <div className="text-sm text-gray-600">
                                      Resources:
                                      {unit.resources.map((resource, index) => (
                                        <a
                                          key={index}
                                          href={resource}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="ml-2 text-blue-600 hover:underline"
                                        >
                                          Link {index + 1}
                                        </a>
                                      ))}
                                      {editMode && (
                                        <button
                                          onClick={() =>
                                            handleEditUnit(
                                              selectedClass,
                                              subject,
                                              unitIndex,
                                              "resources",
                                              [
                                                ...unit.resources,
                                                prompt(
                                                  "Enter new resource URL:"
                                                ),
                                              ]
                                            )
                                          }
                                          className="ml-2 text-green-600 hover:text-green-800"
                                        >
                                          <Plus className="w-4 h-4 inline" />
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                              {editMode && (
                                <motion.button
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                  onClick={() =>
                                    handleAddUnit(selectedClass, subject)
                                  }
                                  className="mt-2 w-full bg-indigo-100 text-indigo-600 rounded-lg px-4 py-2 text-sm font-medium hover:bg-indigo-200 transition-colors duration-300 flex items-center justify-center"
                                >
                                  <Plus className="w-4 h-4 mr-2" />
                                  Add New Unit
                                </motion.button>
                              )}
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                  ))}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>

          <div className="lg:col-span-1 space-y-6">
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.2 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden"
            >
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                  <User className="w-5 h-5 mr-2 text-indigo-600" />
                  Teachers
                </h3>
                <ul className="space-y-2">
                  {subjects[selectedClass].map((subject) => (
                    <li
                      key={subject}
                      className="flex justify-between items-center"
                    >
                      <span className="text-gray-700">{subject}</span>
                      <span className="text-gray-500">{teachers[subject]}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden"
            >
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                  <Calendar className="w-5 h-5 mr-2 text-indigo-600" />
                  Upcoming Exams
                </h3>
                <ul className="space-y-2">
                  {exams[selectedClass].map((exam, index) => (
                    <li
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <span className="text-gray-700">{exam.name}</span>
                      <span className="text-gray-500">
                        {new Date(exam.date).toLocaleDateString()}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: 0.4 }}
              className="bg-white rounded-2xl shadow-lg overflow-hidden"
            >
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800 mb-4 flex items-center">
                  <BarChart className="w-5 h-5 mr-2 text-indigo-600" />
                  Student Performance
                </h3>
                <div className="space-y-4">
                  {subjects[selectedClass].map((subject) => (
                    <div
                      key={subject}
                      className="flex items-center justify-between"
                    >
                      <span className="text-sm text-gray-600">{subject}</span>
                      <div className="flex items-center">
                        <div className="w-32 h-2 bg-gray-300 rounded-full mr-2">
                          <div
                            className={`h-full rounded-full ${getProgressColor(
                              studentPerformance[selectedClass]?.[subject] || 0
                            )}`}
                            style={{
                              width: `${
                                studentPerformance[selectedClass]?.[subject] ||
                                0
                              }%`,
                            }}
                          ></div>
                        </div>
                        <span className="text-sm font-medium text-gray-700">
                          {studentPerformance[selectedClass]?.[subject] || 0}%
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyllabusManagement;
