import React, { useState, useMemo } from "react";
import {
  FaSearch,
  FaPlus,
  FaChartLine,
  FaChartBar,
  FaFileExport,
} from "react-icons/fa";
import StockChart from "./components/StockChart";
import StockModal from "./components/StockModal";
import StockTable from "./components/StockTable";
import { initialStockItems } from "./data/initialData";

const StockManagement = () => {
  const [stockItems, setStockItems] = useState(initialStockItems);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [chartType, setChartType] = useState("bar");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const categories = [
    "All",
    ...new Set(stockItems.map((item) => item.category)),
  ];

  const filteredItems = stockItems.filter(
    (item) =>
      (selectedCategory === "All" || item.category === selectedCategory) &&
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedItems = useMemo(() => {
    let sortableItems = [...filteredItems];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [filteredItems, sortConfig]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const handleAddItem = () => {
    setCurrentItem({
      id: null,
      name: "",
      category: "",
      quantity: 0,
      reorderLevel: 0,
      lastRestocked: new Date().toISOString().split("T")[0],
      supplier: "",
      price: 0,
    });
    setShowModal(true);
  };

  const handleEditItem = (item) => {
    setCurrentItem({ ...item });
    setShowModal(true);
  };

  const handleDeleteItem = (id) => {
    setStockItems(stockItems.filter((item) => item.id !== id));
  };

  const handleSaveItem = (e) => {
    e.preventDefault();
    if (currentItem.id) {
      setStockItems(
        stockItems.map((item) =>
          item.id === currentItem.id ? currentItem : item
        )
      );
    } else {
      setStockItems([...stockItems, { ...currentItem, id: Date.now() }]);
    }
    setShowModal(false);
  };

  const exportToCSV = () => {
    const headers = [
      "Name",
      "Category",
      "Quantity",
      "Reorder Level",
      "Last Restocked",
      "Supplier",
      "Price",
    ];
    const data = sortedItems.map((item) => [
      item.name,
      item.category,
      item.quantity,
      item.reorderLevel,
      item.lastRestocked,
      item.supplier,
      item.price,
    ]);
    const csvContent = [headers, ...data]
      .map((row) => row.join(","))
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "stock_inventory.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <div className="min-h-screen p-4">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <header className="bg-gradient-to-r from-blue-600 to-purple-600 shadow-lg rounded-lg p-8 mb-8">
          <h1 className="text-3xl font-bold text-white mb-2">
            Stock Management
          </h1>
          <p className="text-blue-100">
            Monitor and manage your inventory efficiently
          </p>

          <div className="mt-6 flex flex-col md:flex-row gap-4 items-center">
            <div className="relative w-full md:w-96">
              <input
                type="text"
                placeholder="Search items..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full !pl-10 !mb-0 pr-4 py-3 bg-white/10 border border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-white/50 text-white placeholder-white/70"
              />
              <FaSearch className="absolute left-3 top-3.5 text-white/70" />
            </div>

            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full md:w-40 px-4 py-3 bg-white/10 border border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-white/50 text-white"
            >
              {categories.map((category) => (
                <option
                  key={category}
                  value={category}
                  className="text-gray-900"
                >
                  {category}
                </option>
              ))}
            </select>

            <div className="flex gap-2 ml-auto">
              <button
                onClick={handleAddItem}
                className="bg-white text-indigo-600 px-4 py-2 rounded-lg flex items-center justify-center space-x-2 hover:bg-indigo-50 transition duration-300 text-sm font-medium"
              >
                <FaPlus className="mr-1" />
                <span>Add Item</span>
              </button>
              <button
                onClick={exportToCSV}
                className="bg-indigo-600 text-white px-4 py-2 rounded-lg flex items-center justify-center space-x-2 hover:bg-indigo-700 transition duration-300 text-sm font-medium"
              >
                <FaFileExport className="mr-1" />
                <span>Export CSV</span>
              </button>
            </div>
          </div>
        </header>

        {/* Main Content */}
        <div className="bg-white shadow-lg rounded-lg p-6 mb-8">
          {/* Chart Section */}
          <div className="mb-6">
            <div className="flex flex-col md:flex-row md:items-center justify-between mb-4">
              <h2 className="text-xl font-semibold mb-2 md:mb-0">
                Stock Overview
              </h2>
              <div className="flex space-x-2">
                <button
                  onClick={() => setChartType("bar")}
                  className={`px-4 py-2 rounded-lg text-sm flex items-center ${
                    chartType === "bar"
                      ? "bg-indigo-600 text-white hover:bg-indigo-700"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  <FaChartBar className="mr-2" />
                  Bar
                </button>
                <button
                  onClick={() => setChartType("line")}
                  className={`px-4 py-2 rounded-lg text-sm flex items-center ${
                    chartType === "line"
                      ? "bg-indigo-600 text-white hover:bg-indigo-700"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  <FaChartLine className="mr-2" />
                  Line
                </button>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <StockChart data={sortedItems} chartType={chartType} />
            </div>
          </div>

          {/* Inventory Table */}
          <StockTable
            items={sortedItems}
            onEdit={handleEditItem}
            onDelete={handleDeleteItem}
            onSort={handleSort}
          />
        </div>
      </div>

      {/* Modal */}
      <StockModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        currentItem={currentItem}
        onSave={handleSaveItem}
        setCurrentItem={setCurrentItem}
      />
    </div>
  );
};

export default StockManagement;
