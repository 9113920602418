import React, { useState } from 'react';
import "../cssTeacher/ReportsTeachers.css"
function ReportsForTeachers() {
 
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentData, setStudentData] = useState(null);


  const classOptions = ['Class 1', 'Class 2', 'Class 3'];
  const sectionOptions = ['A', 'B', 'C'];
  const students = [
    { name: 'John Doe', class: 'Class 1', section: 'A', marks: 85, behaviorScore: 4 },
    { name: 'Jane Smith', class: 'Class 1', section: 'B', marks: 92, behaviorScore: 5 },
   
  ];


  const handleClassChange = (e) => setSelectedClass(e.target.value);
  const handleSectionChange = (e) => setSelectedSection(e.target.value);
  const handleStudentChange = (e) => {
    setSelectedStudent(e.target.value);
    const data = students.find(
      (student) => student.name === e.target.value && student.class === selectedClass && student.section === selectedSection
    );
    setStudentData(data || null);
  };

  return (
    <div className="reports-for-teachers">
      <h2 className="text-xl font-bold mb-4">Student Reports</h2>
      <div className="form">
   
        <label className="block mb-2">
          Select Class:
          <select className="ml-2 p-2 border rounded" value={selectedClass} onChange={handleClassChange}>
            <option value="">Select Class</option>
            {classOptions.map((classOption) => (
              <option key={classOption} value={classOption}>
                {classOption}
              </option>
            ))}
          </select>
        </label>

 
        <label className="block mb-2">
          Select Section:
          <select className="ml-2 p-2 border rounded" value={selectedSection} onChange={handleSectionChange}>
            <option value="">Select Section</option>
            {sectionOptions.map((section) => (
              <option key={section} value={section}>
                {section}
              </option>
            ))}
          </select>
        </label>

    
        <label className="block mb-4">
          Select Student:
          <select className="ml-2 p-2 border rounded" value={selectedStudent} onChange={handleStudentChange}>
            <option value="">Select Student</option>
            {students
              .filter((student) => student.class === selectedClass && student.section === selectedSection)
              .map((student) => (
                <option key={student.name} value={student.name}>
                  {student.name}
                </option>
              ))}
          </select>
        </label>
      </div>

    
      {studentData && (
        <div className="student-report p-4 border rounded mt-4">
          <h3 className="text-lg font-bold mb-2">{studentData.name}'s Report</h3>
          <p>Marks: {studentData.marks}</p>
          <p>Behavior Score:</p>
          <div className="stars">
            {[...Array(5)].map((star, index) => (
              <span key={index} className={index < studentData.behaviorScore ? 'text-yellow-500' : 'text-gray-300'}>
                &#9733;
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ReportsForTeachers;
