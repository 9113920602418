// import React, { useState } from 'react';
// import {
//   Box,
//   Container,
//   Heading,
//   Text,
//   VStack,
//   FormControl,
//   FormLabel,
//   Input,
//   Button,
//   Avatar,
//   useBreakpointValue,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalCloseButton,
//   ModalBody,
//   ModalFooter,
//   useColorModeValue,
//   Switch,
// } from '@chakra-ui/react';

// const StudentProfilePage = () => {
//   const [isEditingPassword, setIsEditingPassword] = useState(false);
//   const { isOpen, onOpen, onClose } = useDisclosure();

//   // Sample student data
//   const studentData = {
//     name: 'John Doe',
//     dob: '01/01/2000',
//     id: 'S123456789',
//     number: '123-456-7890',
//     gender: 'Male',
//     address: '1234 Elm Street, Springfield',
//     course: 'Computer Science',
//     photoUrl: 'https://via.placeholder.com/150',
//   };

//   const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

//   // Toggle password edit
//   const handlePasswordToggle = () => {
//     setIsEditingPassword(!isEditingPassword);
//   };

//   // Modal for updating password
//   const handlePasswordUpdate = () => {
//     alert('Password updated!');
//     onClose();
//   };

//   const boxBgColor = useColorModeValue('gray.50', 'gray.700');

//   return (
//     <Container maxW="container.md" py={8}>
//       <VStack spacing={5} align="stretch">
//         <Heading size={headingSize} textAlign="center" color="teal.600">
//           Student Profile
//         </Heading>

//         <Box
//           borderWidth="1px"
//           borderRadius="lg"
//           p={5}
//           bg={boxBgColor}
//           boxShadow="md"
//         >
//           {/* Profile Photo */}
//           <Avatar
//             size="xl"
//             src={studentData.photoUrl}
//             name={studentData.name}
//             mb={4}
//             mx="auto"
//           />
//           {/* Student Info */}
//           <VStack spacing={3} align="flex-start">
//             <Text fontWeight="bold">Name: {studentData.name}</Text>
//             <Text>DOB: {studentData.dob}</Text>
//             <Text>ID: {studentData.id}</Text>
//             <Text>Number: {studentData.number}</Text>
//             <Text>Gender: {studentData.gender}</Text>
//             <Text>Address: {studentData.address}</Text>
//             <Text>Course: {studentData.course}</Text>
//           </VStack>

//           {/* Password Update Toggle */}
//           <FormControl display="flex" alignItems="center" mt={5}>
//             <FormLabel htmlFor="togglePassword" mb={0}>
//               Update Password
//             </FormLabel>
//             <Switch
//               id="togglePassword"
//               isChecked={isEditingPassword}
//               onChange={handlePasswordToggle}
//               ml={3}
//             />
//           </FormControl>

//           {/* Button to Open Password Update Modal */}
//           {isEditingPassword && (
//             <Button colorScheme="teal" mt={4} onClick={onOpen}>
//               Update Password
//             </Button>
//           )}
//         </Box>

//         {/* Modal for Password Update */}
//         <Modal isOpen={isOpen} onClose={onClose}>
//           <ModalOverlay />
//           <ModalContent>
//             <ModalHeader>Update Password</ModalHeader>
//             <ModalCloseButton />
//             <ModalBody>
//               <FormControl id="old-password" mb={4}>
//                 <FormLabel>Old Password</FormLabel>
//                 <Input type="password" placeholder="Enter your old password" />
//               </FormControl>
//               <FormControl id="new-password" mb={4}>
//                 <FormLabel>New Password</FormLabel>
//                 <Input type="password" placeholder="Enter your new password" />
//               </FormControl>
//               <FormControl id="confirm-password">
//                 <FormLabel>Confirm New Password</FormLabel>
//                 <Input
//                   type="password"
//                   placeholder="Confirm your new password"
//                 />
//               </FormControl>
//             </ModalBody>
//             <ModalFooter>
//               <Button colorScheme="teal" onClick={handlePasswordUpdate}>
//                 Save Changes
//               </Button>
//             </ModalFooter>
//           </ModalContent>
//         </Modal>
//       </VStack>
//     </Container>
//   );
// };

// export default StudentProfilePage;

import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Avatar,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useColorModeValue,
  Switch,
  SimpleGrid,
  Divider,
} from '@chakra-ui/react';

const StudentProfilePage = () => {
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const studentData = {
    name: 'John Doe',
    dob: '01/01/2000',
    id: 'S123456789',
    number: '123-456-7890',
    gender: 'Male',
    address: '1234 Elm Street, Springfield',
    course: 'Computer Science',
    admissionDate: '08/01/2020',
    fatherName: 'Michael Doe',
    motherName: 'Sarah Doe',
    email: 'johndoe@example.com',
    photoUrl: 'https://via.placeholder.com/150',
  };

  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  const handlePasswordToggle = () => {
    setIsEditingPassword(!isEditingPassword);
  };

  const handlePasswordUpdate = () => {
    alert('Password updated!');
    onClose();
  };

  const boxBgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={8} align="stretch">
        <Heading size={headingSize} textAlign="center" color="teal.500">
          Student Profile
        </Heading>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          p={6}
          bg={boxBgColor}
          boxShadow="md"
          textAlign="center"
          mb={6}  // Added margin-bottom for spacing below the profile box
        >
          <Avatar
            size="2xl"
            src={studentData.photoUrl}
            name={studentData.name}
            mb={6}
            mx="auto"
          />
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} textAlign="left">
            <VStack align="flex-start" spacing={3} textColor={textColor}>
              <Text fontWeight="bold" fontSize="lg">Name: {studentData.name}</Text>
              <Text>DOB: {studentData.dob}</Text>
              <Text>ID: {studentData.id}</Text>
              <Text>Phone: {studentData.number}</Text>
              <Text>Gender: {studentData.gender}</Text>
              <Text>Address: {studentData.address}</Text>
            </VStack>

            <VStack align="flex-start" spacing={3} textColor={textColor}>
              <Text>Course: {studentData.course}</Text>
              <Text>Admission Date: {studentData.admissionDate}</Text>
              <Text>Father’s Name: {studentData.fatherName}</Text>
              <Text>Mother’s Name: {studentData.motherName}</Text>
              <Text>Email: {studentData.email}</Text>
            </VStack>
          </SimpleGrid>

          <FormControl display="flex" alignItems="center" mt={4}>
            <FormLabel htmlFor="togglePassword" mb={0}>
              Update Password
            </FormLabel>
            <Switch
              id="togglePassword"
              isChecked={isEditingPassword}
              onChange={handlePasswordToggle}
              ml={3}
              colorScheme="teal"
            />
          </FormControl>

          {isEditingPassword && (
            <Button colorScheme="teal" mt={4} onClick={onOpen}>
              Update Password
            </Button>
          )}
        </Box>

        {/* Divider line below the profile section */}
        <Divider borderColor="gray.400" />

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Password</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <FormControl id="old-password">
                  <FormLabel>Old Password</FormLabel>
                  <Input type="password" placeholder="Enter your old password" />
                </FormControl>
                <FormControl id="new-password">
                  <FormLabel>New Password</FormLabel>
                  <Input type="password" placeholder="Enter your new password" />
                </FormControl>
                <FormControl id="confirm-password">
                  <FormLabel>Confirm New Password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Confirm your new password"
                  />
                </FormControl>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="teal" onClick={handlePasswordUpdate}>
                Save Changes
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </VStack>
    </Container>
  );
};

export default StudentProfilePage;
