/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
} from "recharts";

const colors = {
  primary: "#8881f8",
  secondary: "#cc82f0",
  background: "#eeefff",
  text: "#1f2937",
  lightText: "#c7cafe",
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200">
        <p className="font-bold text-gray-800">{label}</p>
        {payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {entry.name}: {entry.value}
          </p>
        ))}
      </div>
    );
  }
  return null;
};

const StockChart = ({ data, chartType }) => {
  const [hoveredBar, setHoveredBar] = useState(null);
  const ChartComponent = chartType === "bar" ? BarChart : LineChart;
  const DataComponent = chartType === "bar" ? Bar : Line;

  const gradientOffset = () => {
    const dataMax = Math.max(...data.map((i) => i.quantity));
    const dataMin = Math.min(...data.map((i) => i.quantity));
    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }
    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  return (
    <div className="bg-white p-6 rounded-xl shadow-lg">
      <ResponsiveContainer width="100%" height={400}>
        <ChartComponent
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <defs>
            <linearGradient id="colorQuantity" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colors.primary} stopOpacity={0.8} />
              <stop offset="95%" stopColor={colors.primary} stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke={colors.lightText} />
          <XAxis
            dataKey="name"
            stroke={colors.text}
            tick={{ fill: colors.text }}
          />
          <YAxis stroke={colors.text} tick={{ fill: colors.text }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {chartType === "line" && (
            <Area
              type="monotone"
              dataKey="quantity"
              stroke={colors.primary}
              fillOpacity={1}
              fill="url(#colorQuantity)"
            />
          )}
          <DataComponent
            type="monotone"
            dataKey="quantity"
            fill={colors.primary}
            stroke={colors.primary}
            name="Current Stock"
            animationDuration={500}
            onMouseEnter={(data, index) => setHoveredBar(index)}
            onMouseLeave={() => setHoveredBar(null)}
          >
            {data.map((entry, index) => (
              <motion.circle
                key={`dot-${index}`}
                cx={0}
                cy={0}
                r={hoveredBar === index ? 6 : 4}
                fill={colors.primary}
                initial={false}
                animate={{ scale: hoveredBar === index ? 1.2 : 1 }}
                transition={{ duration: 0.3 }}
              />
            ))}
          </DataComponent>
          <DataComponent
            type="monotone"
            dataKey="reorderLevel"
            fill={colors.secondary}
            stroke={colors.secondary}
            name="Reorder Level"
            animationDuration={500}
          />
        </ChartComponent>
      </ResponsiveContainer>
    </div>
  );
};

export default StockChart;
