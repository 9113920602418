import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  HStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";

const SyllabusStudents = () => {
  // Dummy syllabus file URL (replace with an actual URL or file path)
  const syllabusUrl = "https://example.com/syllabus.pdf";

  return (
    <Box
      bg="gray.100"
      minH="100vh"
      py={useBreakpointValue({ base: 6, md: 12 })}
      px={useBreakpointValue({ base: 4, md: 8 })}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <VStack
        spacing={6}
        bg="white"
        p={useBreakpointValue({ base: 6, md: 12 })}
        borderRadius="lg"
        boxShadow="lg"
        width="100%"
        maxW="lg"
      >
        <Heading as="h1" size="lg" color="teal.600">
          Student Syllabus
        </Heading>
        <Text fontSize="md" color="gray.600" textAlign="center">
          Download your syllabus to stay updated on the course content and
          schedule.
        </Text>
        <HStack spacing={4}>
          <Button
            colorScheme="teal"
            leftIcon={<DownloadIcon />}
            onClick={() => window.open(syllabusUrl, "_blank")}
          >
            Download Syllabus
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default SyllabusStudents;