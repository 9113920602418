/* eslint-disable react/prop-types */
import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';



export const StockModal = ({
  isOpen,
  onClose,
  currentItem,
  onSave,
  setCurrentItem,
}) => {
  if (!isOpen || !currentItem) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-lg p-6 max-w-md w-full"
      >
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium text-gray-900">
            {currentItem?.id ? "Edit Item" : "Add New Item"}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={onSave}>
          <div className="space-y-4">
            {[
              { id: 'name', label: 'Name', type: 'text' },
              { id: 'category', label: 'Category', type: 'text' },
              { id: 'quantity', label: 'Quantity', type: 'number' },
              { id: 'reorderLevel', label: 'Reorder Level', type: 'number' },
              { id: 'supplier', label: 'Supplier', type: 'text' },
              { id: 'price', label: 'Price', type: 'number', step: '0.01' }
            ].map((field) => (
              <div key={field.id}>
                <label
                  htmlFor={field.id}
                  className="block text-sm font-medium text-gray-700"
                >
                  {field.label}
                </label>
                <input
                  type={field.type}
                  id={field.id}
                  step={field.step}
                  value={currentItem[field.id]}
                  onChange={(e) =>
                    setCurrentItem({
                      ...currentItem,
                      [field.id]: field.type === 'number' ? 
                        (field.step ? parseFloat(e.target.value) : parseInt(e.target.value)) : 
                        e.target.value,
                    })
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default StockModal;