import React, { useState, useEffect } from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
} from "recharts";
import {
  Search,
  Filter,
  Plus,
  Download,
  Calendar,
  CreditCard,
  IndianRupee,
  TrendingUp,
  X,
} from "lucide-react";

const sampleData = [
  {
    id: "STD001",
    name: "Aditya Sharma",
    class: "11th",
    section: "A",
    contact: "+91 98765 43210",
    amount: 25000,
    date: "2024-03-15",
    purpose: "Monthly Tuition",
    mode: "UPI",
    status: "Paid",
    transactionId: "TXN123456",
    parentName: "Rajesh Sharma",
    lastPaymentMode: "Google Pay",
    pendingAmount: 0,
    stream: "Science",
  },
  {
    id: "STD002",
    name: "Priya Patel",
    class: "10th",
    section: "B",
    contact: "+91 98765 43211",
    amount: 30000,
    date: "2024-03-14",
    purpose: "Admission",
    mode: "Cash",
    status: "Pending",
    transactionId: "TXN123457",
    parentName: "Amit Patel",
    lastPaymentMode: "Cash",
    pendingAmount: 5000,
    stream: "Commerce",
  },
  {
    id: "STD003",
    name: "Mohit Verma",
    class: "9th",
    section: "A",
    contact: "+91 98765 43212",
    amount: 28000,
    date: "2024-03-13",
    purpose: "Quarterly Fee",
    mode: "Net Banking",
    status: "Paid",
    transactionId: "TXN123458",
    parentName: "Suresh Verma",
    lastPaymentMode: "NEFT",
    pendingAmount: 0,
    stream: "General",
  },
  {
    id: "STD004",
    name: "Sneha Gupta",
    class: "12th",
    section: "C",
    contact: "+91 98765 43213",
    amount: 35000,
    date: "2024-03-12",
    purpose: "Annual Fee",
    mode: "Credit Card",
    status: "Overdue",
    transactionId: "TXN123459",
    parentName: "Manoj Gupta",
    lastPaymentMode: "Credit Card",
    pendingAmount: 35000,
    stream: "Commerce",
  },
];

const monthlyData = [
  { name: "Jan", amount: 120000 },
  { name: "Feb", amount: 150000 },
  { name: "Mar", amount: 180000 },
  { name: "Apr", amount: 200000 },
  { name: "May", amount: 160000 },
  { name: "Jun", amount: 140000 },
];

const paymentModeData = [
  { name: "UPI", value: 45 },
  { name: "Cash", value: 20 },
  { name: "Net Banking", value: 15 },
  { name: "Credit Card", value: 20 },
];

const COLORS = ["#7e22ce", "#9333ea", "#a855f7", "#c084fc"];

const FeeDashboard = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("All");
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("All");
  const [filteredData, setFilteredData] = useState(sampleData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const totalCollection = sampleData.reduce(
    (sum, student) => sum + student.amount,
    0
  );
  const pendingAmount = sampleData.reduce(
    (sum, student) => sum + student.pendingAmount,
    0
  );

  const [newPayment, setNewPayment] = useState({
    studentId: "",
    amount: "",
    paymentMode: "",
    purpose: "",
    date: new Date().toISOString().split("T")[0],
  });

  useEffect(() => {
    let result = sampleData;

    // Search filter
    if (searchTerm) {
      result = result.filter(
        (student) =>
          student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
          student.parentName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    // Class filter
    if (selectedClass !== "All") {
      result = result.filter((student) => student.class === selectedClass);
    }

    // Payment mode filter
    if (selectedPaymentMode !== "All") {
      result = result.filter((student) => student.mode === selectedPaymentMode);
    }

    setFilteredData(result);
    setCurrentPage(1);
  }, [searchTerm, selectedClass, selectedPaymentMode]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Modal Handlers
  const handleModalClose = () => {
    setIsModalOpen(false);
    setNewPayment({
      studentId: "",
      amount: "",
      paymentMode: "",
      purpose: "",
      date: new Date().toISOString().split("T")[0],
    });
  };

  const handlePaymentSubmit = (e) => {
    e.preventDefault();
    if (
      !newPayment.studentId ||
      !newPayment.amount ||
      !newPayment.paymentMode ||
      !newPayment.purpose
    ) {
      alert("Please fill all required fields");
      return;
    }

    const studentData = sampleData.find((s) => s.id === newPayment.studentId);
    if (studentData) {
      const newPaymentRecord = {
        ...studentData,
        amount: parseFloat(newPayment.amount),
        mode: newPayment.paymentMode,
        purpose: newPayment.purpose,
        date: newPayment.date,
        status: "Paid",
        transactionId:
          "TXN" + Math.random().toString(36).substr(2, 6).toUpperCase(),
      };

      setFilteredData([newPaymentRecord, ...filteredData]);
    }

    handleModalClose();
  };

  // Export Logic
  const exportData = () => {
    const csvData = [
      [
        "Student ID",
        "Name",
        "Class",
        "Section",
        "Amount",
        "Purpose",
        "Payment Mode",
        "Status",
        "Date",
      ],
      ...filteredData.map((student) => [
        student.id,
        student.name,
        student.class,
        student.section,
        student.amount,
        student.purpose,
        student.mode,
        student.status,
        student.date,
      ]),
    ];

    const csvContent = csvData.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `fee_records_${new Date().toISOString().split("T")[0]}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="min-h-screen p-4 md:p-6">
      {/* Header */}
      <div className="mb-8">
        <div className="flex items-center gap-3 mb-2">
          <div className="h-8 w-8 bg-purple-600 rounded-lg flex items-center justify-center">
            <IndianRupee className="text-white" size={20} />
          </div>
          <h1 className="sm:text-3xl text-xl font-bold text-purple-900">
            Student Fee Dashboard
          </h1>
        </div>
        <p className="text-gray-600">
          Track, manage and analyze student fee collections
        </p>
      </div>

      {/* Stats Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="bg-gradient-to-br from-purple-600 to-purple-700 p-6 rounded-xl shadow-lg">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-purple-100 text-sm">Total Collection</p>
              <h3 className="text-2xl font-bold text-white mt-1">
                ₹{totalCollection.toLocaleString()}
              </h3>
            </div>
            <span className="bg-purple-500 p-2 rounded-lg">
              <TrendingUp className="text-white" size={20} />
            </span>
          </div>
          <div className="mt-4 flex items-center text-purple-100">
            <span className="text-green-300">↑ 12%</span>
            <span className="ml-2 text-sm">vs last month</span>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-purple-100">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-gray-600 text-sm">Pending Collection</p>
              <h3 className="text-2xl font-bold text-purple-900 mt-1">
                ₹{pendingAmount.toLocaleString()}
              </h3>
            </div>
            <span className="bg-purple-100 p-2 rounded-lg">
              <Calendar className="text-purple-600" size={20} />
            </span>
          </div>
          <div className="mt-4 flex items-center text-gray-600">
            <span className="text-red-500">↓ 5%</span>
            <span className="ml-2 text-sm">vs last month</span>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-purple-100">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-gray-600 text-sm">Online Payments</p>
              <h3 className="text-2xl font-bold text-purple-900 mt-1">75%</h3>
            </div>
            <span className="bg-purple-100 p-2 rounded-lg">
              <CreditCard className="text-purple-600" size={20} />
            </span>
          </div>
          <div className="mt-4 flex items-center text-gray-600">
            <span className="text-green-500">↑ 8%</span>
            <span className="ml-2 text-sm">increased adoption</span>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-purple-100">
          <div className="flex justify-between items-start">
            <div>
              <p className="text-gray-600 text-sm">Total Students</p>
              <h3 className="text-2xl font-bold text-purple-900 mt-1">250</h3>
            </div>
            <span className="bg-purple-100 p-2 rounded-lg">
              <Filter className="text-purple-600" size={20} />
            </span>
          </div>
          <div className="mt-4 flex items-center text-gray-600">
            <span className="text-green-500">↑ 2%</span>
            <span className="ml-2 text-sm">new admissions</span>
          </div>
        </div>
      </div>

      {/* Charts Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white p-6 rounded-xl shadow-sm border border-purple-100">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold text-purple-900">
              Fee Collection Trend
            </h3>
            <select className="text-sm border border-gray-200 rounded-lg px-3 py-1">
              <option>Last 6 months</option>
              <option>Last year</option>
            </select>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyData}>
              <XAxis dataKey="name" stroke="#6b7280" />
              <YAxis stroke="#6b7280" />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="amount"
                stroke="#7e22ce"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-6 rounded-xl shadow-sm border border-purple-100">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-semibold text-purple-900">
              Payment Mode Distribution
            </h3>
            <select className="text-sm border border-gray-200 rounded-lg px-3 py-1">
              <option>This Month</option>
              <option>Last Month</option>
            </select>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={paymentModeData}
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={90}
                fill="#8884d8"
                dataKey="value"
                label
              >
                {paymentModeData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Actions Bar */}
      <div className="bg-white p-6 rounded-xl shadow-sm border border-purple-100 mb-8">
        <div className="flex flex-wrap gap-4 justify-between items-center">
          <div className="flex gap-4 flex-1">
            <div className="relative flex-1">
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600"
                size={20}
              />
              <input
                type="text"
                placeholder="Search by student name, ID or class..."
                className="w-full !mb-0 !pl-10 pr-4 py-2.5 border border-gray-200 rounded-lg focus:outline-none focus:border-purple-500 placeholder:text-gray-600"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="flex gap-4">
              <select
                className="bg-white border border-gray-200 rounded-lg px-4 py-2.5 focus:outline-none focus:border-purple-500"
                value={selectedClass}
                onChange={(e) => setSelectedClass(e.target.value)}
              >
                <option>All Classes</option>
                <option>9th</option>
                <option>10th</option>
                <option>11th</option>
                <option>12th</option>
              </select>
              <select
                className="bg-white border border-gray-200 rounded-lg px-4 py-2.5 focus:outline-none focus:border-purple-500"
                value={selectedPaymentMode}
                onChange={(e) => setSelectedPaymentMode(e.target.value)}
              >
                <option>All Payment Modes</option>
                <option>UPI</option>
                <option>Cash</option>
                <option>Net Banking</option>
                <option>Credit Card</option>
              </select>
            </div>
          </div>
          <div className="flex gap-4">
            <button
              onClick={() => setIsModalOpen(true)}
              className="flex items-center gap-2 bg-purple-600 text-white px-6 py-2.5 rounded-lg hover:bg-purple-700 transition-colors"
            >
              <Plus size={20} />
              Add Payment
            </button>
            <button
              onClick={exportData}
              className="flex items-center gap-2 border border-purple-600 text-purple-600 px-6 py-2.5 rounded-lg hover:bg-purple-50 transition-colors"
            >
              <Download size={20} />
              Export
            </button>
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="bg-white rounded-xl shadow-sm border border-purple-100 overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-purple-50">
                <th className="px-6 py-4 text-left text-xs font-medium bg-gray-50 text-purple-800 uppercase tracking-wider">
                  Student Details
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium bg-gray-50 text-purple-800 uppercase tracking-wider">
                  Class Info
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium bg-gray-50 text-purple-800 uppercase tracking-wider">
                  Payment Details
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium bg-gray-50 text-purple-800 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium bg-gray-50 text-purple-800 uppercase tracking-wider">
                  Payment Mode
                </th>
                <th className="px-6 py-4 text-left text-xs font-medium bg-gray-50 text-purple-800 uppercase tracking-wider">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems.map((student) => (
                <tr
                  key={student.id}
                  className="hover:bg-purple-50 transition-colors"
                >
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10 bg-purple-100 rounded-full flex items-center justify-center">
                        <span className="text-purple-700 font-medium text-sm">
                          {student.name
                            .split(" ")
                            .map((n) => n[0])
                            .join("")}
                        </span>
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {student.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          ID: {student.id}
                        </div>
                        <div className="text-sm text-gray-500">
                          Parent: {student.parentName}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      Class {student.class}
                    </div>
                    <div className="text-sm text-gray-500">
                      Section {student.section}
                    </div>
                    <div className="text-sm text-gray-500">
                      {student.stream}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900">
                      ₹{student.amount.toLocaleString()}
                    </div>
                    <div className="text-sm text-gray-500">
                      {student.purpose}
                    </div>
                    {student.pendingAmount > 0 && (
                      <div className="text-sm text-red-500">
                        Pending: ₹{student.pendingAmount.toLocaleString()}
                      </div>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <span
                      className={`px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        student.status === "Paid"
                          ? "bg-green-100 text-green-800"
                          : student.status === "Pending"
                          ? "bg-yellow-100 text-yellow-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {student.status}
                    </span>
                    <div className="text-sm text-gray-500 mt-1">
                      TXN: {student.transactionId}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">{student.mode}</div>
                    <div className="text-sm text-gray-500">
                      Last: {student.lastPaymentMode}
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="text-sm text-gray-900">
                      {new Date(student.date).toLocaleDateString("en-IN", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}
                    </div>
                    <div className="text-sm text-gray-500">
                      {new Date(student.date).toLocaleTimeString("en-IN", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="bg-white px-6 py-4 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <span className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">{indexOfFirstItem + 1}</span> to{" "}
                <span className="font-medium">
                  {Math.min(indexOfLastItem, filteredData.length)}
                </span>{" "}
                of <span className="font-medium">{filteredData.length}</span>{" "}
                results
              </span>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className={`px-4 py-2 border rounded-lg text-sm font-medium ${
                  currentPage === 1
                    ? "border-gray-200 text-gray-400 cursor-not-allowed"
                    : "border-gray-300 text-gray-700 hover:bg-gray-50"
                }`}
              >
                Previous
              </button>
              <button
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
                disabled={currentPage === totalPages}
                className={`px-4 py-2 border rounded-lg text-sm font-medium ${
                  currentPage === totalPages
                    ? "border-gray-200 text-gray-400 cursor-not-allowed"
                    : "border-purple-600 bg-purple-600 text-white hover:bg-purple-700"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Quick Actions Modal - Can be triggered from Add Payment button */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-xl bg-white">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Add New Payment
              </h3>
              <button
                onClick={handleModalClose}
                className="text-gray-400 hover:text-gray-500"
              >
                <X size={20} />
              </button>
            </div>
            <form onSubmit={handlePaymentSubmit}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Student ID
                  </label>
                  <input
                    type="text"
                    required
                    value={newPayment.studentId}
                    onChange={(e) =>
                      setNewPayment({
                        ...newPayment,
                        studentId: e.target.value,
                      })
                    }
                    className="px-3 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:border-purple-500"
                    placeholder="Enter student ID"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Amount
                  </label>
                  <input
                    type="number"
                    required
                    value={newPayment.amount}
                    onChange={(e) =>
                      setNewPayment({ ...newPayment, amount: e.target.value })
                    }
                    className="px-3 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:border-purple-500"
                    placeholder="Enter amount"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Payment Mode
                  </label>
                  <select
                    required
                    value={newPayment.paymentMode}
                    onChange={(e) =>
                      setNewPayment({
                        ...newPayment,
                        paymentMode: e.target.value,
                      })
                    }
                    className="px-3 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:border-purple-500"
                  >
                    <option value="">Select Payment Mode</option>
                    <option value="UPI">UPI</option>
                    <option value="Cash">Cash</option>
                    <option value="Net Banking">Net Banking</option>
                    <option value="Credit Card">Credit Card</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Purpose
                  </label>
                  <select
                    required
                    value={newPayment.purpose}
                    onChange={(e) =>
                      setNewPayment({ ...newPayment, purpose: e.target.value })
                    }
                    className="px-3 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:border-purple-500"
                  >
                    <option value="">Select Purpose</option>
                    <option value="Admission Fee">Admission Fee</option>
                    <option value="Monthly Tuition">Monthly Tuition</option>
                    <option value="Quarterly Fee">Quarterly Fee</option>
                    <option value="Annual Fee">Annual Fee</option>
                  </select>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date
                  </label>
                  <input
                    type="date"
                    required
                    value={newPayment.date}
                    onChange={(e) =>
                      setNewPayment({ ...newPayment, date: e.target.value })
                    }
                    className="px-3 py-2 border border-gray-300 rounded-lg w-full focus:outline-none focus:border-purple-500"
                  />
                </div>
              </div>
              <div className="flex justify-end gap-2 mt-6">
                <button
                  type="button"
                  onClick={handleModalClose}
                  className="px-4 py-2 bg-gray-200 text-gray-800 text-base font-medium rounded-lg hover:bg-gray-300"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-purple-600 text-white text-base font-medium rounded-lg hover:bg-purple-700"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeeDashboard;
