import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  VStack,
  useToast,
  useColorModeValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import { CheckIcon, TimeIcon } from '@chakra-ui/icons';

const LibraryPage = () => {
  const toast = useToast();

  // Sample data for issued books and returned books
  const [issuedBooks, setIssuedBooks] = useState([
    {
      bookName: 'Mathematics for Engineers',
      issueDate: '2024-10-01',
      returnDate: null,
    },
    {
      bookName: 'Physics: Concepts and Applications',
      issueDate: '2024-10-05',
      returnDate: '2024-10-15',
    },
  ]);

  // Function to handle book return
  const handleReturnBook = (bookName) => {
    const updatedBooks = issuedBooks.map((book) =>
      book.bookName === bookName ? { ...book, returnDate: new Date().toISOString().split('T')[0] } : book
    );
    setIssuedBooks(updatedBooks);
    toast({
      title: 'Book Returned',
      description: `You have successfully returned the book "${bookName}".`, // Fixed string interpolation
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading size={headingSize} textAlign="center" color={useColorModeValue('teal.600', 'teal.300')}>
          Library Book Status
        </Heading>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="md"
          p={6}
          bg={useColorModeValue('white', 'gray.800')}
        >
          <VStack spacing={4} align="stretch">
            <Heading size="md" color={useColorModeValue('teal.600', 'teal.300')}>
              Issued Books
            </Heading>

            <Table variant="striped" colorScheme="teal">
              <Thead>
                <Tr>
                  <Th>Book Name</Th>
                  <Th>Issue Date</Th>
                  <Th>Return Date</Th>
                  <Th>Status</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {issuedBooks.map((book, index) => (
                  <Tr key={index}>
                    <Td>{book.bookName}</Td>
                    <Td>{book.issueDate}</Td>
                    <Td>{book.returnDate || 'Not Returned'}</Td>
                    <Td>
                      {book.returnDate ? (
                        <Text color="green.500" fontWeight="bold">
                          Returned
                        </Text>
                      ) : (
                        <Text color="orange.500" fontWeight="bold">
                          Issued
                        </Text>
                      )}
                    </Td>
                    <Td>
                      {!book.returnDate && (
                        <Button
                          colorScheme="teal"
                          size="sm"
                          leftIcon={<CheckIcon />}
                          onClick={() => handleReturnBook(book.bookName)}
                        >
                          Return Book
                        </Button>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </VStack>

          <HStack spacing={4} mt={8}>
            <Text fontSize="md" color={useColorModeValue('gray.700', 'gray.300')}>
              <TimeIcon boxSize={4} /> Books with status "Issued" are not yet returned.
            </Text>
          </HStack>
        </Box>
      </VStack>
    </Container>
  );
};

export default LibraryPage;
