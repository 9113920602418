/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  FaSearch,
  FaFilter,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaEye,
  FaTimes,
  FaEnvelope,
  FaPhone,
  FaCalendarAlt,
  FaUser,
  FaGraduationCap,
  FaMapMarkerAlt,
} from "react-icons/fa";

const TeacherList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("all");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });

  const teachers = [
    {
      id: 1,
      name: "Aarav Sharma",
      guardianName: "Rajesh Sharma",
      email: "aarav.sharma@example.com",
      phone: "9876543210",
      address: "123, Sector 12, Noida, Uttar Pradesh",
      pin: "201301",
      cityOrVillage: "Noida",
      state: "Uttar Pradesh",
      gender: "male",
      bloodGroup: "B+",
      department: "Mathematics",
      joiningDate: "2023-01-15",
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeOlwb6YnVU55H4BNqtLm7T8b7thI-HiQBrg&s",
      status: "Active",
    },
    {
      id: 2,
      name: "Isha Patel",
      guardianName: "Suman Patel",
      email: "isha.patel@example.com",
      phone: "9876543211",
      address: "45, Shivaji Nagar, Pune, Maharashtra",
      pin: "411030",
      cityOrVillage: "Pune",
      state: "Maharashtra",
      gender: "female",
      bloodGroup: "A-",
      department: "Science",
      joiningDate: "2022-11-22",
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeOlwb6YnVU55H4BNqtLm7T8b7thI-HiQBrg&s",
      status: "Active",
    },
    {
      id: 3,
      name: "Rohan Gupta",
      guardianName: "Anil Gupta",
      email: "rohan.gupta@example.com",
      phone: "9876543212",
      address: "89, Gandhi Road, Surat, Gujarat",
      pin: "395001",
      cityOrVillage: "Surat",
      state: "Gujarat",
      gender: "male",
      bloodGroup: "O+",
      department: "Computer Science",
      joiningDate: "2024-06-05",
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeOlwb6YnVU55H4BNqtLm7T8b7thI-HiQBrg&s",
      status: "Active",
    },
    {
      id: 4,
      name: "Sneha Reddy",
      guardianName: "Kiran Reddy",
      email: "sneha.reddy@example.com",
      phone: "9876543213",
      address: "22, Brigade Road, Bangalore, Karnataka",
      pin: "560001",
      cityOrVillage: "Bangalore",
      state: "Karnataka",
      gender: "female",
      bloodGroup: "AB+",
      department: "English",
      joiningDate: "2021-09-10",
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeOlwb6YnVU55H4BNqtLm7T8b7thI-HiQBrg&s",
      status: "On Leave",
    },
    {
      id: 5,
      name: "Ayaan Mehta",
      guardianName: "Pooja Mehta",
      email: "ayaan.mehta@example.com",
      phone: "9876543214",
      address: "67, MG Road, Jaipur, Rajasthan",
      pin: "302001",
      cityOrVillage: "Jaipur",
      state: "Rajasthan",
      gender: "male",
      bloodGroup: "A+",
      department: "History",
      joiningDate: "2023-08-30",
      photo:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeOlwb6YnVU55H4BNqtLm7T8b7thI-HiQBrg&s",
      status: "On Leave",
    },
  ];

  const departments = [
    "All Departments",
    "Mathematics",
    "Science",
    "English",
    "Computer Science",
    "Physics",
  ];

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const sortedTeachers = [...teachers]
    .filter((teacher) => {
      const matchesSearch =
        teacher.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        teacher.email.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesDepartment =
        selectedDepartment === "all" ||
        teacher.department === selectedDepartment;
      return matchesSearch && matchesDepartment;
    })
    .sort((a, b) => {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
      }
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    });

  const SortIcon = ({ column }) => {
    if (sortConfig.key !== column) return <FaSort className="text-gray-300" />;
    return sortConfig.direction === "asc" ? (
      <FaSortUp className="text-purple-500" />
    ) : (
      <FaSortDown className="text-purple-500" />
    );
  };

  const handleTeacherClick = (teacher) => {
    setSelectedTeacher(teacher);
  };

  const closeProfileModal = () => {
    setSelectedTeacher(null);
  };

  const getStatusColor = (status) => {
    return status === "Active"
      ? "bg-green-500"
      : status === "On Leave"
      ? "bg-yellow-500"
      : "bg-gray-500";
  };

  const TeacherProfileModal = ({ teacher, closeProfileModal }) => (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-40 p-4">
      <div className="relative bg-white rounded-2xl shadow-xl w-full max-w-2xl">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="sm:text-2xl text-lg font-semibold text-gray-800">
            {teacher.name}&apos;s Profile
          </h2>
          <button
            onClick={closeProfileModal}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <FaTimes className="w-5 h-5 text-gray-500" />
          </button>
        </div>

        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Left Column - Photo and Basic Info */}
            <div className="flex flex-col items-center space-y-4">
              <div className="relative">
                <img
                  src={teacher.photo}
                  alt={teacher.name}
                  className="w-32 h-32 rounded-full object-cover ring-4 ring-purple-100"
                />
                <div
                  className={`absolute bottom-2 right-2 w-4 h-4 rounded-full ${getStatusColor(
                    teacher.status
                  )} ring-2 ring-white`}
                  aria-label={`${teacher.status} status`}
                />
              </div>
              <h3 className="text-xl font-bold text-gray-800 text-center">
                {teacher.name}
              </h3>
              <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
                {teacher.department}
              </span>
            </div>

            {/* Middle Column - Contact Details */}
            <div className="space-y-4 md:col-span-2">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <FaEnvelope className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Email</p>
                      <p className="text-sm font-medium">{teacher.email}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaPhone className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Phone</p>
                      <p className="text-sm font-medium">{teacher.phone}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaCalendarAlt className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Joining Date</p>
                      <p className="text-sm font-medium">
                        {new Date(teacher.joiningDate).toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <FaUser className="text-gray-400 w-4 h-4" />

                    <div>
                      <p className="text-sm text-gray-500">State</p>
                      <p className="text-sm font-medium">{teacher.state}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaGraduationCap className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Blood Group</p>
                      <p className="text-sm font-medium">
                        {teacher.bloodGroup}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaMapMarkerAlt className="text-gray-400 w-4 h-4" />
                    <div>
                      <p className="text-sm text-gray-500">Address</p>
                      <p className="text-sm font-medium">
                        {teacher.address}, {teacher.cityOrVillage},{" "}
                        {teacher.state}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          <div className="flex items-center gap-4 mb-4 md:mb-0">
            <h1 className="sm:text-2xl text-lg font-bold text-gray-800">
              Teacher Directory
            </h1>
            <span className="px-3 py-1 bg-purple-100 text-purple-800 rounded-full text-nowrap text-sm">
              {sortedTeachers.length} Teachers
            </span>
          </div>

          {/* Search and Filter Section */}
          <div className="flex flex-col sm:flex-row gap-4 w-full md:w-auto">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <FaSearch className="text-gray-600" />
              </div>
              <input
                type="text"
                className="block w-full !mb-0 !pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:border-transparent placeholder:text-gray-700"
                required
                placeholder="Search Teacher..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            <div className="relative">
              <button
                onClick={() => setIsFilterOpen(!isFilterOpen)}
                className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                <FaFilter className="text-gray-500" />
                <span>Filter</span>
              </button>

              {isFilterOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 z-10">
                  {departments.map((dept, index) => (
                    <button
                      key={index}
                      className={`w-full text-center px-4 py-2 hover:bg-gray-50 ${
                        (dept === "All Departments" ? "all" : dept) ===
                        selectedDepartment
                          ? "bg-purple-50 text-purple-600"
                          : "text-gray-700"
                      }`}
                      onClick={() => {
                        setSelectedDepartment(
                          dept === "All Departments" ? "all" : dept
                        );
                        setIsFilterOpen(false);
                      }}
                    >
                      {dept}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Table Section */}
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider w-12">
                    Photo
                  </th>
                  <th
                    className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("name")}
                  >
                    <div className="flex items-center justify-center gap-2">
                      Name
                      <SortIcon column="name" />
                    </div>
                  </th>
                  <th
                    className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("department")}
                  >
                    <div className="flex items-center justify-center gap-2">
                      Department
                      <SortIcon column="department" />
                    </div>
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider">
                    Contact
                  </th>
                  <th
                    className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("joiningDate")}
                  >
                    <div className="flex items-center justify-center gap-2">
                      Joining Date
                      <SortIcon column="joiningDate" />
                    </div>
                  </th>
                  <th
                    className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("status")}
                  >
                    <div className="flex items-center justify-center gap-2">
                      Status
                      <SortIcon column="status" />
                    </div>
                  </th>
                  <th className="px-6 py-3 text-center text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {sortedTeachers.map((teacher) => (
                  <tr key={teacher.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <img
                        src={teacher.photo}
                        alt={teacher.name}
                        className="w-8 h-8 rounded-full"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {teacher.name}
                      </div>
                      <div className="text-sm text-gray-500">
                        {teacher.email}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-purple-100 text-purple-800">
                        {teacher.department}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {teacher.phone}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {new Date(teacher.joiningDate).toLocaleDateString()}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          teacher.status === "Active"
                            ? "bg-green-100 text-green-800"
                            : "bg-yellow-100 text-yellow-800"
                        }`}
                      >
                        {teacher.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                      <button
                        onClick={() => handleTeacherClick(teacher)}
                        className="text-purple-600 hover:text-purple-900"
                      >
                        <FaEye className="inline-block mr-1" /> View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Empty State */}
        {sortedTeachers.length === 0 && (
          <div className="text-center py-12 bg-white rounded-xl shadow-sm mt-4">
            <p className="text-gray-500 text-lg">
              No teachers found matching your search criteria
            </p>
          </div>
        )}
      </div>

      {selectedTeacher && (
        <TeacherProfileModal
          teacher={selectedTeacher}
          closeProfileModal={closeProfileModal}
        />
      )}
    </div>
  );
};

export default TeacherList;
