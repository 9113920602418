import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { format, addDays, startOfWeek, addHours, parseISO } from 'date-fns';
import { Calendar, Clock, Plus, Bus, GraduationCap, Palette, Users, Search, Filter, Edit2, Trash2, ChevronLeft, ChevronRight, Book } from 'lucide-react';
import { toast, Toaster } from 'react-hot-toast';
import ScheduleList from './Schedule/ScheduleList';

const SCHEDULE_TYPES = [
  { id: "class", label: "Classes", icon: GraduationCap, color: "bg-indigo-500" },
  { id: "bus", label: "Bus Routes", icon: Bus, color: "bg-green-500" },
  { id: "extra", label: "Extra Classes", icon: Users, color: "bg-purple-500" },
  { id: "activity", label: "Activities", icon: Palette, color: "bg-orange-500" },
];

const CLASSES = [
  { id: "9", name: "Class 9" },
  { id: "10", name: "Class 10" },
  { id: "11", name: "Class 11" },
  { id: "12", name: "Class 12" },
];

const SUBJECTS = [
  { id: "math", name: "Mathematics" },
  { id: "science", name: "Science" },
  { id: "english", name: "English" },
  { id: "history", name: "History" },
  { id: "geography", name: "Geography" },
  { id: "physics", name: "Physics" },
  { id: "chemistry", name: "Chemistry" },
  { id: "biology", name: "Biology" },
];

const HOURS = Array.from({ length: 14 }, (_, i) => i + 7); // 7 AM to 8 PM

const generateClassEvents = () => {
  let allEvents = [];
  CLASSES.forEach((classItem) => {
    SUBJECTS.forEach((subject, index) => {
      allEvents.push({
        id: `${classItem.id}-${subject.id}`,
        title: `${subject.name} - Class ${classItem.id}`,
        start: new Date().setHours(8 + index, 0),
        end: new Date().setHours(8 + index + 1, 0),
        type: "class",
        classId: classItem.id,
        subject: subject.id,
        location: `Room ${101 + index}`,
        description: `Regular ${subject.name} class for Class ${classItem.id}`,
      });
    });
  });
  return allEvents;
};

const INITIAL_EVENTS = [
  ...generateClassEvents(),
  {
    id: "bus-1",
    title: "School Bus Route A",
    start: new Date().setHours(7, 30),
    end: new Date().setHours(8, 30),
    type: "bus",
    location: "City Center to School",
    description: "Morning pickup",
  },
  {
    id: "activity-1",
    title: "Art Club",
    start: new Date().setHours(14, 0),
    end: new Date().setHours(15, 30),
    type: "activity",
    location: "Art Room",
    description: "Weekly art club meeting",
  },
];



const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">{title}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const Schedule = () => {
  const [events, setEvents] = useState(INITIAL_EVENTS);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalMode, setModalMode] = useState("create");
  const [filterType, setFilterType] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClass, setSelectedClass] = useState("all");
  const [activeTab, setActiveTab] = useState(0);

  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    type: "class",
    classId: "",
    subject: "",
    location: "",
    description: "",
  });


  const handleEventClick = (event) => {
    setModalMode("view");
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleSaveEvent = (e) => {
    e.preventDefault();

    if (modalMode === "create") {
      const eventToAdd = {
        id: Date.now().toString(),
        ...newEvent,
        start: parseISO(newEvent.start).getTime(),
        end: parseISO(newEvent.end).getTime(),
      };
      setEvents([...events, eventToAdd]);
      toast.success("Schedule created successfully!");
    } else if (modalMode === "edit") {
      setEvents(
        events.map((event) =>
          event.id === selectedEvent.id
            ? {
                ...event,
                title: newEvent.title,
                start: parseISO(newEvent.start).getTime(),
                end: parseISO(newEvent.end).getTime(),
                type: newEvent.type,
                classId: newEvent.classId,
                subject: newEvent.subject,
                location: newEvent.location,
                description: newEvent.description,
              }
            : event
        )
      );
      toast.success("Schedule updated successfully!");
    }
    setIsModalOpen(false);
  };

  const handleDeleteEvent = () => {
    setEvents(events.filter((event) => event.id !== selectedEvent.id));
    setIsModalOpen(false);
    toast.success("Schedule deleted successfully!");
  };

  const filteredEvents = events.filter((event) => {
    const matchesType = filterType === "all" || event.type === filterType;
    const matchesClass =
      selectedClass === "all" || event.classId === selectedClass;
    const matchesSearch =
      event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.description?.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesType && matchesClass && matchesSearch;
  });

  const counts = {
    class: events.filter((event) => event.type === "class").length,
    bus: events.filter((event) => event.type === "bus").length,
    extra: events.filter((event) => event.type === "extra").length,
    activity: events.filter((event) => event.type === "activity").length,
  };

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <Toaster position="top-center" />

      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="rounded-xl p-6 mb-6 bg-gradient-to-r from-blue-600 to-purple-600 text-white"
        >
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
            <div>
              <h1 className="text-2xl font-bold flex items-center gap-2">
                <Calendar className="w-6 h-6" />
                Schedule Management
              </h1>
              <p className="text-white text-opacity-90 mt-1">
                Manage all school schedules in one place
              </p>
            </div>

            <div className="flex flex-wrap gap-3">
              <div className="relative bg-white bg-opacity-20 rounded-md">
                <input
                  type="text"
                  placeholder="Search schedules..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 pr-4 py-2 bg-transparent border-none text-white placeholder-white placeholder-opacity-70 focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 rounded-md"
                />
                <Search className="absolute left-3 top-2.5 w-5 h-5 text-white opacity-70" />
              </div>

              <div className="relative bg-white bg-opacity-20 rounded-md">
                <select
                  value={filterType}
                  onChange={(e) => setFilterType(e.target.value)}
                  className="pl-4 pr-8 py-2 bg-transparent border-none text-white appearance-none focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 rounded-md"
                >
                  <option value="all">All Types</option>
                  {SCHEDULE_TYPES.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  ))}
                </select>
                <Filter className="absolute right-2 top-2.5 w-5 h-5 text-white opacity-70 pointer-events-none" />
              </div>

              {filterType === "class" && (
                <select
                  value={selectedClass}
                  onChange={(e) => setSelectedClass(e.target.value)}
                  className="pl-4 pr-8 py-2 bg-white bg-opacity-20 border-none text-white appearance-none focus:outline-none focus:ring-2 focus:ring-white focus:ring-opacity-50 rounded-md"
                >
                  <option value="all">All Classes</option>
                  {CLASSES.map((cls) => (
                    <option key={cls.id} value={cls.id}>
                      {cls.name}
                    </option>
                  ))}
                </select>
              )}

              <button
                onClick={() => {
                  setModalMode("create");
                  setNewEvent({
                    title: "",
                    start: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
                    end: format(addHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
                    type: "class",
                    classId: "",
                    subject: "",
                    location: "",
                    description: "",
                  });
                  setIsModalOpen(true);
                }}
                className="flex items-center gap-2 bg-white text-purple-600 px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors"
              >
                <Plus className="w-5 h-5" />
                Add Schedule
              </button>
            </div>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-6">
            {SCHEDULE_TYPES.map((type) => (
              <motion.div
                key={type.id}
                whileHover={{ scale: 1.05 }}
                className="bg-white bg-opacity-20 rounded-lg p-4 cursor-pointer hover:bg-opacity-30 transition-all"
                onClick={() => setFilterType(type.id)}
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 rounded-lg bg-white bg-opacity-20">
                    <type.icon className="w-5 h-5 text-white" />
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="font-medium text-white truncate">{type.label}</div>
                    <div className="text-sm text-white text-opacity-90 truncate">
                      {counts[type.id]} scheduled
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <div className="mb-6">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              <button
                className={`w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                  activeTab === 0
                    ? 'border-purple-500 text-purple-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => setActiveTab(0)}
              >
                Weekly View
              </button>
              <button
                className={`w-1/2 py-4 px-1 text-center border-b-2 font-medium text-sm ${
                  activeTab === 1
                    ? 'border-purple-500 text-purple-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => setActiveTab(1)}
              >
                Class Schedule
              </button>
            </nav>
          </div>
        </div>

        <AnimatePresence mode="wait">
          {activeTab === 0 && (
            <motion.div
              key="weekly-view"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="bg-white rounded-xl shadow-lg p-6 overflow-x-auto"
            >
              <ScheduleList
                events={filteredEvents}
                onEventClick={handleEventClick}
              />
            </motion.div>
          )}

          {activeTab === 1 && (
            <motion.div
              key="class-schedule"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              {CLASSES.map((cls) => (
                <motion.div
                  key={cls.id}
                  whileHover={{ scale: 1.02 }}
                  className="bg-white rounded-xl shadow-lg p-6"
                >
                  <div className="flex items-center gap-3 mb-4">
                    <div className="p-2 rounded-lg bg-purple-100">
                      <Book className="w-6 h-6 text-purple-500" />
                    </div>
                    <h2 className="text-lg font-semibold">{cls.name}</h2>
                  </div>

                  <div className="space-y-3">
                    {SUBJECTS.map((subject) => {
                      const subjectEvents = events.filter(
                        (event) =>
                          event.classId === cls.id &&
                          event.subject === subject.id &&
                          event.type === "class"
                      );

                      return (
                        <div
                          key={subject.id}
                          className="p-3 bg-gray-50 rounded-md hover:bg-gray-100 transition-colors"
                        >
                          <h3 className="font-medium">{subject.name}</h3>
                          {subjectEvents.map((event) => (
                            <p key={event.id} className="text-sm text-gray-600">
                              {format(event.start, "h:mm a")} -{" "}
                              {format(event.end, "h:mm a")}
                              {event.location && ` • ${event.location}`}
                            </p>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={
          modalMode === "create"
            ? "Add New Schedule"
            : modalMode === "edit"
            ? "Edit Schedule"
            : "Schedule Details"
        }
      >
        {modalMode === "view" ? (
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-semibold">{selectedEvent.title}</h3>
              <p className="text-sm text-gray-500">
                {format(selectedEvent.start, "PPP")} at{" "}
                {format(selectedEvent.start, "p")} -{" "}
                {format(selectedEvent.end, "p")}
              </p>
            </div>

            <div className="flex items-center gap-2">
              <Clock className="w-5 h-5 text-gray-400" />
              <p className="text-sm text-gray-600">
                Duration:{" "}
                {Math.round(
                  (selectedEvent.end - selectedEvent.start) / (1000 * 60)
                )}{" "}
                minutes
              </p>
            </div>

            {selectedEvent.location && (
              <p className="text-sm text-gray-600">
                📍 {selectedEvent.location}
              </p>
            )}

            {selectedEvent.description && (
              <p className="text-sm text-gray-600">{selectedEvent.description}</p>
            )}

            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => {
                  setModalMode("edit");
                  setNewEvent({
                    title: selectedEvent.title,
                    start: format(selectedEvent.start, "yyyy-MM-dd'T'HH:mm"),
                    end: format(selectedEvent.end, "yyyy-MM-dd'T'HH:mm"),
                    type: selectedEvent.type,
                    classId: selectedEvent.classId,
                    subject: selectedEvent.subject,
                    location: selectedEvent.location,
                    description: selectedEvent.description,
                  });
                }}
                className="flex items-center gap-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
              >
                <Edit2 className="w-4 h-4" />
                Edit
              </button>
              <button
                onClick={handleDeleteEvent}
                className="flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors"
              >
                <Trash2 className="w-4 h-4" />
                Delete
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSaveEvent} className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <input
                type="text"
                id="title"
                value={newEvent.title}
                onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                required
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="start" className="block text-sm font-medium text-gray-700">
                  Start Time
                </label>
                <input
                  type="datetime-local"
                  id="start"
                  value={newEvent.start}
                  onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
                  required
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                />
              </div>

              <div>
                <label htmlFor="end" className="block text-sm font-medium text-gray-700">
                  End Time
                </label>
                <input
                  type="datetime-local"
                  id="end"
                  value={newEvent.end}
                  onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
                  required
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                  Type
                </label>
                <select
                  id="type"
                  value={newEvent.type}
                  onChange={(e) => setNewEvent({ ...newEvent, type: e.target.value })}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                >
                  {SCHEDULE_TYPES.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              {newEvent.type === "class" && (
                <div>
                  <label htmlFor="class" className="block text-sm font-medium text-gray-700">
                    Class
                  </label>
                  <select
                    id="class"
                    value={newEvent.classId}
                    onChange={(e) => setNewEvent({ ...newEvent, classId: e.target.value })}
                    required={newEvent.type === "class"}
                    className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                  >
                    <option value="">Select Class</option>
                    {CLASSES.map((cls) => (
                      <option key={cls.id} value={cls.id}>
                        {cls.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            {newEvent.type === "class" && (
              <div>
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                  Subject
                </label>
                <select
                  id="subject"
                  value={newEvent.subject}
                  onChange={(e) => setNewEvent({ ...newEvent, subject: e.target.value })}
                  required={newEvent.type === "class"}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                >
                  <option value="">Select Subject</option>
                  {SUBJECTS.map((subject) => (
                    <option key={subject.id} value={subject.id}>
                      {subject.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <input
                type="text"
                id="location"
                value={newEvent.location}
                onChange={(e) => setNewEvent({ ...newEvent, location: e.target.value })}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={newEvent.description}
                onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
                rows={3}
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
              ></textarea>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
              >
                {modalMode === "create" ? "Create Schedule" : "Save Changes"}
              </button>
            </div>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default Schedule;