import React, { useState } from 'react';
import "../cssTeacher/LibraryTeachers.css"; 

function LibraryTeachers() {

  const [bookDetails, setBookDetails] = useState({
    title: '',
    author: '',
    borrowDate: '',
    returnDate: '',
  });


  const [borrowedBooks, setBorrowedBooks] = useState([]);
  

  const [totalBooks, setTotalBooks] = useState(0);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };


  const handleAddBook = (e) => {
    e.preventDefault();
    if (!bookDetails.title || !bookDetails.author || !bookDetails.borrowDate || !bookDetails.returnDate) {
      alert('Please fill out all fields');
      return;
    }
    setBorrowedBooks([...borrowedBooks, bookDetails]);
    setBookDetails({ title: '', author: '', borrowDate: '', returnDate: '' });
    setTotalBooks(totalBooks + 1);
  };

  return (
    <div className="library-teachers-container">
      <h2>Library Management</h2>
      <h3>Total Books Borrowed: {totalBooks}</h3>

 
      <form className="book-form" onSubmit={handleAddBook}>
        <div className="form-group">
          <label>Book Title:</label>
          <input
            type="text"
            name="title"
            value={bookDetails.title}
            onChange={handleInputChange}
            placeholder="Enter book title"
          />
        </div>

        <div className="form-group">
          <label>Author:</label>
          <input
            type="text"
            name="author"
            value={bookDetails.author}
            onChange={handleInputChange}
            placeholder="Enter author's name"
          />
        </div>

        <div className="form-group">
          <label>Borrow Date:</label>
          <input
            type="date"
            name="borrowDate"
            value={bookDetails.borrowDate}
            onChange={handleInputChange}
          />
        </div>

        <div className="form-group">
          <label>Expected Return Date:</label>
          <input
            type="date"
            name="returnDate"
            value={bookDetails.returnDate}
            onChange={handleInputChange}
          />
        </div>

        <button className="buttonBook"type="submit">Add Book</button>
      </form>

    
      <table className="borrowed-books-table">
        <thead>
          <tr>
            <th>Book Title</th>
            <th>Author</th>
            <th>Borrow Date</th>
            <th>Expected Return Date</th>
          </tr>
        </thead>
        <tbody>
          {borrowedBooks.map((book, index) => (
            <tr key={index}>
              <td>{book.title}</td>
              <td>{book.author}</td>
              <td>{book.borrowDate}</td>
              <td>{book.returnDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default LibraryTeachers;
