import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Book,
  Search,
  Plus,
  Edit,
  Trash2,
  X,
  BookOpen,
  Users,
  BarChart3,
  BookCopy,
  Clock,
  AlertCircle,
  Check,
  Upload,
  Eye,
} from "lucide-react";
import { format, isAfter } from "date-fns";
import toast, { Toaster } from "react-hot-toast";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

// Sample data (unchanged)
const initialBooks = [
  {
    id: "B001",
    title: "The Design of Everyday Things",
    author: "Don Norman",
    isbn: "978-0465050659",
    category: "Design",
    status: "Available",
    totalCopies: 5,
    availableCopies: 3,
    location: "Section A, Shelf 2",
    coverImage:
      "https://images.unsplash.com/photo-1544716278-ca5e3f4abd8c?ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80",
    description:
      "A powerful primer on how design serves as the interface between objects and users.",
    publisher: "Basic Books",
    publishYear: 2013,
    language: "English",
    pages: 368,
  },
  // Add more sample books...
];

const initialBorrowings = [
  {
    id: "BR001",
    bookId: "B001",
    userId: "U001",
    userName: "John Smith",
    userType: "Student",
    borrowDate: "2024-03-01",
    dueDate: "2024-03-15",
    returnDate: null,
    status: "Borrowed",
  },
  // Add more sample borrowings...
];

export default function Library() {
  const [books, setBooks] = useState(initialBooks);
  const [borrowings, setBorrowings] = useState(initialBorrowings);
  const [activeTab, setActiveTab] = useState("books");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "ascending",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("view");
  const [selectedItem, setSelectedItem] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);
  const fileInputRef = useRef(null);

  // Analytics data (unchanged)
  const borrowingStats = {
    total: borrowings.length,
    active: borrowings.filter((b) => b.status === "Borrowed").length,
    overdue: borrowings.filter(
      (b) => b.status === "Borrowed" && isAfter(new Date(), new Date(b.dueDate))
    ).length,
    returned: borrowings.filter((b) => b.status === "Returned").length,
  };

  const categoryData = books.reduce((acc, book) => {
    acc[book.category] = (acc[book.category] || 0) + 1;
    return acc;
  }, {});

  const pieChartData = Object.entries(categoryData).map(([name, value]) => ({
    name,
    value,
  }));

  const COLORS = ["#4F46E5", "#7C3AED", "#EC4899", "#F59E0B", "#10B981"];

  // Handlers
  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const handleAddBook = () => {
    setModalMode("create");
    setSelectedItem({
      id: `B${books.length + 1}`.padStart(4, "0"),
      title: "",
      author: "",
      isbn: "",
      category: "",
      status: "Available",
      totalCopies: 1,
      availableCopies: 1,
      location: "",
      coverImage: "",
      description: "",
      publisher: "",
      publishYear: new Date().getFullYear(),
      language: "English",
      pages: 0,
    });
    setShowModal(true);
  };

  const handleEditBook = (book) => {
    setModalMode("edit");
    setSelectedItem(book);
    setShowModal(true);
  };

  const handleDeleteBook = (book) => {
    setConfirmAction({
      type: "delete",
      item: book,
      message: `Are you sure you want to delete "${book.title}"?`,
    });
    setShowConfirmModal(true);
  };

  const handleViewBookDetails = (book) => {
    setModalMode("view");
    setSelectedItem(book);
    setShowModal(true);
  };

  const confirmActionHandler = () => {
    if (confirmAction.type === "delete") {
      setBooks(books.filter((b) => b.id !== confirmAction.item.id));
      toast.success("Book deleted successfully");
    }
    setShowConfirmModal(false);
  };

  const handleSaveBook = (bookData) => {
    if (modalMode === "create") {
      setBooks([...books, bookData]);
      toast.success("Book added successfully");
    } else if (modalMode === "edit") {
      setBooks(books.map((b) => (b.id === bookData.id ? bookData : b)));
      toast.success("Book updated successfully");
    }
    setShowModal(false);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedItem({ ...selectedItem, coverImage: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <Toaster position="top-center" />

      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="bg-gradient-to-r from-indigo-600 to-purple-600 rounded-2xl p-6 mb-8 shadow-lg"
      >
        <div className="flex flex-col lg:flex-row lg:items-center justify-between gap-6">
          <div className="text-white">
            <h1 className="text-xl sm:text-3xl font-bold flex items-center gap-2">
              <BookOpen className="w-8 h-8" />
              Library Management
            </h1>
            <p className="mt-2 text-indigo-100">
              Manage books, track borrowings, and monitor library activities
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="flex items-center gap-2 bg-white/10 px-4 py-2 rounded-lg">
              <Book className="w-5 h-5 text-white" />
              <div>
                <p className="text-xs text-indigo-100">Total Books</p>
                <p className="text-lg font-semibold text-white">
                  {books.length}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2 bg-white/10 px-4 py-2 rounded-lg">
              <Users className="w-5 h-5 text-white" />
              <div>
                <p className="text-xs text-indigo-100">Active Borrowers</p>
                <p className="text-lg font-semibold text-white">
                  {borrowingStats.active}
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2 bg-white/10 px-4 py-2 rounded-lg">
              <AlertCircle className="w-5 h-5 text-white" />
              <div>
                <p className="text-xs text-indigo-100">Overdue</p>
                <p className="text-lg font-semibold text-white">
                  {borrowingStats.overdue}
                </p>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Tabs */}
      <div className="flex space-x-4 mb-6 overflow-x-auto">
        <button
          onClick={() => setActiveTab("books")}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === "books"
              ? "bg-indigo-600 text-white hover:bg-indigo-700"
              : "bg-white text-gray-600 hover:bg-gray-50"
          }`}
        >
          <BookCopy className="w-5 h-5" />
          Books
        </button>
        <button
          onClick={() => setActiveTab("borrowings")}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === "borrowings"
              ? "bg-indigo-600 text-white hover:bg-indigo-700"
              : "bg-white text-gray-600 hover:bg-gray-50"
          }`}
        >
          <Clock className="w-5 h-5" />
          Borrowings
        </button>
        <button
          onClick={() => setActiveTab("analytics")}
          className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
            activeTab === "analytics"
              ? "bg-indigo-600 text-white hover:bg-indigo-700"
              : "bg-white text-gray-600 hover:bg-gray-50"
          }`}
        >
          <BarChart3 className="w-5 h-5" />
          Analytics
        </button>
      </div>

      {/* Content */}
      <div className="bg-white rounded-2xl shadow-lg p-6">
        {activeTab === "books" && (
          <div>
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-6">
              <div className="flex-1 flex flex-col md:flex-row items-center gap-4">
                <div className="relative flex-1 w-full">
                  <input
                    type="text"
                    placeholder="Search books..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full !pl-10 !mb-0 pr-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-300"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="w-full md:w-auto px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-300"
                >
                  <option value="All">All Categories</option>
                  {Array.from(new Set(books.map((b) => b.category))).map(
                    (category) => (
                      <option key={category} value={category}>
                        {category}
                      </option>
                    )
                  )}
                </select>
                <select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="w-full md:w-auto px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-300"
                >
                  <option value="All">All Status</option>
                  <option value="Available">Available</option>
                  <option value="Borrowed">Borrowed</option>
                </select>
              </div>
              <button
                onClick={handleAddBook}
                className="w-full md:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors duration-300"
              >
                <Plus className="w-5 h-5" />
                Add Book
              </button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {books
                .filter(
                  (book) =>
                    (selectedCategory === "All" ||
                      book.category === selectedCategory) &&
                    (selectedStatus === "All" ||
                      (selectedStatus === "Available" &&
                        book.availableCopies > 0) ||
                      (selectedStatus === "Borrowed" &&
                        book.availableCopies < book.totalCopies)) &&
                    (book.title
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                      book.author
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                      book.isbn.includes(searchTerm))
                )
                .map((book) => (
                  <motion.div
                    key={book.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="bg-white rounded-xl shadow-md overflow-hidden border border-gray-200"
                  >
                    <div className="h-48 overflow-hidden">
                      <img
                        src={book.coverImage}
                        alt={book.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="p-4">
                      <h3 className="text-lg font-semibold text-gray-900 mb-1">
                        {book.title}
                      </h3>
                      <p className="text-gray-600 text-sm mb-2">
                        by {book.author}
                      </p>
                      <div className="flex items-center gap-2 mb-3">
                        <span className="px-2 py-1 text-xs rounded-full bg-indigo-100 text-indigo-800">
                          {book.category}
                        </span>
                        <span
                          className={`px-2 py-1 text-xs rounded-full ${
                            book.availableCopies > 0
                              ? "bg-green-100 text-green-800"
                              : "bg-red-100 text-red-800"
                          }`}
                        >
                          {book.availableCopies} of {book.totalCopies} available
                        </span>
                      </div>
                      <p className="text-sm text-gray-500 mb-4">
                        {book.description.substring(0, 100)}...
                      </p>
                      <div className="flex justify-between items-center">
                        <div className="flex space-x-2">
                          <button
                            onClick={() => handleViewBookDetails(book)}
                            className="p-2 text-gray-600 hover:text-indigo-600 transition-colors"
                          >
                            <Eye className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleEditBook(book)}
                            className="p-2 text-gray-600 hover:text-indigo-600 transition-colors"
                          >
                            <Edit className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDeleteBook(book)}
                            className="p-2 text-gray-600 hover:text-red-600 transition-colors"
                          >
                            <Trash2 className="w-5 h-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
            </div>
          </div>
        )}

        {activeTab === "borrowings" && (
          <div>
            <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-6">
              <div className="flex-1 flex flex-col md:flex-row items-center gap-4">
                <div className="relative flex-1 w-full">
                  <input
                    type="text"
                    placeholder="Search borrowings..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full !pl-10 !mb-0 pr-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-300"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
                <select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="w-full md:w-auto px-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-300"
                >
                  <option value="All">All Status</option>
                  <option value="Borrowed">Borrowed</option>
                  <option value="Returned">Returned</option>
                  <option value="Overdue">Overdue</option>
                </select>
              </div>
            </div>

            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {[
                      "Book",
                      "Borrower",
                      "Borrow Date",
                      "Due Date",
                      "Status",
                    ].map((header) => (
                      <th
                        key={header}
                        className="px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {borrowings
                    .filter(
                      (borrowing) =>
                        (selectedStatus === "All" ||
                          (selectedStatus === "Overdue" &&
                            isAfter(new Date(), new Date(borrowing.dueDate))) ||
                          borrowing.status === selectedStatus) &&
                        (borrowing.userName
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                          books
                            .find((b) => b.id === borrowing.bookId)
                            ?.title.toLowerCase()
                            .includes(searchTerm.toLowerCase()))
                    )
                    .map((borrowing) => {
                      const book = books.find((b) => b.id === borrowing.bookId);
                      const isOverdue = isAfter(
                        new Date(),
                        new Date(borrowing.dueDate)
                      );

                      return (
                        <tr key={borrowing.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-10 w-10 flex-shrink-0">
                                <img
                                  className="h-10 w-10 rounded-full object-cover"
                                  src={book?.coverImage}
                                  alt=""
                                />
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {book?.title}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {book?.author}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {borrowing.userName}
                            </div>
                            <div className="text-sm text-gray-500">
                              {borrowing.userType}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {format(
                              new Date(borrowing.borrowDate),
                              "MMM d, yyyy"
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {format(new Date(borrowing.dueDate), "MMM d, yyyy")}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-2 py-1 text-xs rounded-full ${
                                borrowing.status === "Returned"
                                  ? "bg-green-100 text-green-800"
                                  : isOverdue
                                  ? "bg-red-100 text-red-800"
                                  : "bg-yellow-100 text-yellow-800"
                              }`}
                            >
                              {borrowing.status === "Returned"
                                ? "Returned"
                                : isOverdue
                                ? "Overdue"
                                : "Borrowed"}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {activeTab === "analytics" && (
          <div className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              <div className="bg-gradient-to-br from-indigo-500 to-indigo-600 rounded-xl p-6 text-white">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-indigo-100">Total Books</p>
                    <h3 className="text-3xl font-bold mt-1">{books.length}</h3>
                  </div>
                  <Book className="w-12 h-12 opacity-50" />
                </div>
              </div>
              <div className="bg-gradient-to-br from-purple-500 to-purple-600 rounded-xl p-6 text-white">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-purple-100">Active Borrowings</p>
                    <h3 className="text-3xl font-bold mt-1">
                      {borrowingStats.active}
                    </h3>
                  </div>
                  <Users className="w-12 h-12 opacity-50" />
                </div>
              </div>
              <div className="bg-gradient-to-br from-pink-500 to-pink-600 rounded-xl p-6 text-white">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-pink-100">Overdue Books</p>
                    <h3 className="text-3xl font-bold mt-1">
                      {borrowingStats.overdue}
                    </h3>
                  </div>
                  <AlertCircle className="w-12 h-12 opacity-50" />
                </div>
              </div>
              <div className="bg-gradient-to-br from-green-500 to-green-600 rounded-xl p-6 text-white">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="text-green-100">Returned Books</p>
                    <h3 className="text-3xl font-bold mt-1">
                      {borrowingStats.returned}
                    </h3>
                  </div>
                  <Check className="w-12 h-12 opacity-50" />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-white rounded-xl shadow p-6">
                <h3 className="text-lg font-semibold mb-4">
                  Books by Category
                </h3>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={({ name, percent }) =>
                          `${name} (${(percent * 100).toFixed(0)}%)`
                        }
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="bg-white rounded-xl shadow p-6">
                <h3 className="text-lg font-semibold mb-4">
                  Monthly Borrowings
                </h3>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      data={[
                        { month: "Jan", borrowings: 12 },
                        { month: "Feb", borrowings: 19 },
                        { month: "Mar", borrowings: 15 },
                        { month: "Apr", borrowings: 21 },
                        { month: "May", borrowings: 18 },
                        { month: "Jun", borrowings: 24 },
                      ]}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="borrowings" fill="#4F46E5" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Modals */}
      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto"
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h3 className="text-2xl font-bold text-gray-900">
                    {modalMode === "create"
                      ? "Add New Book"
                      : modalMode === "edit"
                      ? "Edit Book"
                      : "Book Details"}
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>

                {modalMode === "view" ? (
                  <div className="space-y-4">
                    <div className="flex items-center space-x-4">
                      <img
                        src={selectedItem.coverImage}
                        alt={selectedItem.title}
                        className="w-32 h-48 object-cover rounded-lg"
                      />
                      <div>
                        <h4 className="text-xl font-semibold">
                          {selectedItem.title}
                        </h4>
                        <p className="text-gray-600">
                          by {selectedItem.author}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          ISBN
                        </p>
                        <p>{selectedItem.isbn}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Category
                        </p>
                        <p>{selectedItem.category}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Total Copies
                        </p>
                        <p>{selectedItem.totalCopies}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Available Copies
                        </p>
                        <p>{selectedItem.availableCopies}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Location
                        </p>
                        <p>{selectedItem.location}</p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Language
                        </p>
                        <p>{selectedItem.language}</p>
                      </div>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Description
                      </p>
                      <p className="mt-1">{selectedItem.description}</p>
                    </div>
                  </div>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSaveBook({
                        ...selectedItem,
                        title: e.target.title.value,
                        author: e.target.author.value,
                        isbn: e.target.isbn.value,
                        category: e.target.category.value,
                        totalCopies: parseInt(e.target.totalCopies.value),
                        availableCopies: parseInt(
                          e.target.availableCopies.value
                        ),
                        location: e.target.location.value,
                        description: e.target.description.value,
                        publisher: e.target.publisher.value,
                        publishYear: parseInt(e.target.publishYear.value),
                        language: e.target.language.value,
                        pages: parseInt(e.target.pages.value),
                      });
                    }}
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="md:col-span-2">
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Cover Image
                        </label>
                        <div className="flex items-center space-x-4">
                          <img
                            src={
                              selectedItem.coverImage ||
                              "https://via.placeholder.com/100?text=Image+Not+Available"
                            }
                            alt="Book cover"
                            className="w-24 h-36 object-cover rounded-lg"
                          />
                          <div>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleFileUpload}
                              ref={fileInputRef}
                              className="hidden"
                            />
                            <button
                              type="button"
                              onClick={() => fileInputRef.current.click()}
                              className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors"
                            >
                              <Upload className="w-5 h-5 inline-block mr-2" />
                              Upload Image
                            </button>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="title"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          defaultValue={selectedItem?.title}
                          required
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="author"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Author
                        </label>
                        <input
                          type="text"
                          id="author"
                          name="author"
                          defaultValue={selectedItem?.author}
                          required
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="isbn"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ISBN
                        </label>
                        <input
                          type="text"
                          id="isbn"
                          name="isbn"
                          defaultValue={selectedItem?.isbn}
                          required
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="category"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Category
                        </label>
                        <input
                          type="text"
                          id="category"
                          name="category"
                          defaultValue={selectedItem?.category}
                          required
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="totalCopies"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Total Copies
                        </label>
                        <input
                          type="number"
                          id="totalCopies"
                          name="totalCopies"
                          defaultValue={selectedItem?.totalCopies}
                          required
                          min="1"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="availableCopies"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Available Copies
                        </label>
                        <input
                          type="number"
                          id="availableCopies"
                          name="availableCopies"
                          defaultValue={selectedItem?.availableCopies}
                          required
                          min="0"
                          max={selectedItem?.totalCopies}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="location"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Location
                        </label>
                        <input
                          type="text"
                          id="location"
                          name="location"
                          defaultValue={selectedItem?.location}
                          required
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div className="md:col-span-2">
                        <label
                          htmlFor="description"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Description
                        </label>
                        <textarea
                          id="description"
                          name="description"
                          rows={3}
                          defaultValue={selectedItem?.description}
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="publisher"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Publisher
                        </label>
                        <input
                          type="text"
                          id="publisher"
                          name="publisher"
                          defaultValue={selectedItem?.publisher}
                          required
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="publishYear"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Publish Year
                        </label>
                        <input
                          type="number"
                          id="publishYear"
                          name="publishYear"
                          defaultValue={selectedItem?.publishYear}
                          required
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="language"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Language
                        </label>
                        <input
                          type="text"
                          id="language"
                          name="language"
                          defaultValue={selectedItem?.language}
                          required
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="pages"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Pages
                        </label>
                        <input
                          type="number"
                          id="pages"
                          name="pages"
                          defaultValue={selectedItem?.pages}
                          required
                          min="1"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                    <div className="flex justify-end space-x-3 mt-6">
                      <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
                      >
                        {modalMode === "create" ? "Add Book" : "Save Changes"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Confirmation Modal */}
      <AnimatePresence>
        {showConfirmModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-lg p-6 max-w-sm w-full"
            >
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Confirm Action
              </h3>
              <p className="text-sm text-gray-500 mb-4">
                {confirmAction?.message}
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowConfirmModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmActionHandler}
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
                >
                  Confirm
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
