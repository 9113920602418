import React from 'react';
import "../cssTeacher/ActivitiofTeachers.css";

function ActivitiofTeachers() {

  const activities = [
    { type: 'Sports', name: 'Annual Sports Meet', date: '15th Nov 2024', role: 'Event Coordinator' },
    { type: 'Science', name: 'Science Fair Judging', date: '20th Nov 2024', role: 'Judge' },
    { type: 'Exam', name: 'Mid-Term Examinations', date: '25th Nov 2024', role: 'Invigilator' },
    { type: 'Test', name: 'Monthly Test Supervision', date: '30th Nov 2024', role: 'Supervisor' },
  ];

  return (
    <div className="activities-container">
      <h2>Teacher Activities</h2>
      <div className="activity-list">
        {activities.map((activity, index) => (
          <div key={index} className={`activity-card ${activity.type.toLowerCase()}`}>
            <h3>{activity.type}</h3>
            <p><strong>Activity:</strong> {activity.name}</p>
            <p><strong>Date:</strong> {activity.date}</p>
            <p><strong>Role:</strong> {activity.role}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ActivitiofTeachers;
