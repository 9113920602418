import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaBook, FaClipboardCheck, FaCalendar, FaClipboardList, FaMoneyBillAlt, FaBell, FaGraduationCap, FaUserTie, FaChartBar, FaMoneyCheckAlt, FaUserClock, FaTasks, FaCalendarAlt, FaClipboard } from 'react-icons/fa';
import '../Teacher/cssTeacher/SidebarTeacher.css';

function SidebarTeacher() {
  const navigate = useNavigate();
  
 
  const [isAttendanceSubmenuOpen, setIsAttendanceSubmenuOpen] = useState(false);
  const [isFeesSubmenuOpen, setIsFeesSubmenuOpen] = useState(false);
  const [isHRSubmenuOpen, setIsHRSubmenuOpen] = useState(false);


  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleAttendanceSubmenu = () => setIsAttendanceSubmenuOpen(!isAttendanceSubmenuOpen);
  const toggleFeesSubmenu = () => setIsFeesSubmenuOpen(!isFeesSubmenuOpen);
  const toggleHRSubmenu = () => setIsHRSubmenuOpen(!isHRSubmenuOpen);

  return (
    <div className="sidebar  bg-white text-gray-800 p-4 h-auto">
      <h2 className="text-xl font-bold mb-6 sidebar-title">Teacher Dashboard</h2>
      <ul className="space-y-4">
 
        <li onClick={() => handleNavigation('/ProfileTeacher')} className="cursor-pointer flex items-center">
          <FaUser className="icon mr-2" />
          <span className="sidebar-text">Profile</span>
        </li>
        <li onClick={() => handleNavigation('/SpecialClassofTeachers')} className="cursor-pointer flex items-center">
          <FaTasks className="icon mr-2" />
          <span className="sidebar-text">Special Class</span>
        </li>
        <li onClick={() => handleNavigation('/AccountTeachers')} className="cursor-pointer flex items-center">
          <FaUserTie className="icon mr-2" />
          <span className="sidebar-text">Account</span>
        </li>
        <li onClick={() => handleNavigation('/ActivitiofTeachers')} className="cursor-pointer flex items-center">
          <FaClipboardList className="icon mr-2" />
          <span className="sidebar-text">Activities</span>
        </li>
        <li onClick={() => handleNavigation('/AssinmentsTeacher')} className="cursor-pointer flex items-center">
          <FaClipboardCheck className="icon mr-2" />
          <span className="sidebar-text">Assignment</span>
        </li>
        
      
        <li className="cursor-pointer flex items-center" onClick={toggleAttendanceSubmenu}>
          <FaCalendarAlt className="icon mr-2" />
          <span className="sidebar-text">Attendance</span>
        </li>
        
        {isAttendanceSubmenuOpen && (
          <ul className="ml-6 space-y-2">
            <li onClick={() => handleNavigation('/AttendanceTeacher/teacher')} className="cursor-pointer flex items-center">
              <FaClipboardCheck className="icon mr-2" />
              <span className="sidebar-text">Self Attendance</span>
            </li>
            <li onClick={() => handleNavigation('/AttendanceTeacher/student')} className="cursor-pointer flex items-center">
              <FaClipboardList className="icon mr-2" />
              <span className="sidebar-text">Student Attendance</span>
            </li>
          </ul>
        )}

 
        <li className="cursor-pointer flex items-center" onClick={toggleFeesSubmenu}>
          <FaMoneyBillAlt className="icon mr-2" />
          <span className="sidebar-text">Fees</span>
        </li>
        
        {isFeesSubmenuOpen && (
          <ul className="ml-6 space-y-2">
            <li onClick={() => handleNavigation('/Fees/online')} className="cursor-pointer flex items-center">
              <FaMoneyCheckAlt className="icon mr-2" />
              <span className="sidebar-text">Online Fees</span>
            </li>
            <li onClick={() => handleNavigation('/Fees/offline')} className="cursor-pointer flex items-center">
              <FaMoneyCheckAlt className="icon mr-2" />
              <span className="sidebar-text">Offline Fees</span>
            </li>
          </ul>
        )}

     
        <li onClick={() => handleNavigation('/StaffManagementforteachers')} className="cursor-pointer flex items-center">
          <FaUser className="icon mr-2" />
          <span className="sidebar-text">Staff Management</span>
        </li>
        <li onClick={() => handleNavigation('/Reportsofteachers')} className="cursor-pointer flex items-center">
          <FaChartBar className="icon mr-2" />
          <span className="sidebar-text">Reports</span>
        </li>
        

        <li className="cursor-pointer flex items-center" onClick={toggleHRSubmenu}>
          <FaUserTie className="icon mr-2" />
          <span className="sidebar-text">HR</span>
        </li>
        
        {isHRSubmenuOpen && (
          <ul className="ml-6 space-y-2">
            <li onClick={() => handleNavigation('/HR/Salary')} className="cursor-pointer flex items-center">
              <FaMoneyCheckAlt className="icon mr-2" />
              <span className="sidebar-text">Salary (Teacher)</span>
            </li>
            <li onClick={() => handleNavigation('/HR/Leave')} className="cursor-pointer flex items-center">
              <FaUserClock className="icon mr-2" />
              <span className="sidebar-text">Leave</span>
            </li>
            <li onClick={() => handleNavigation('/HR/Present')} className="cursor-pointer flex items-center">
              <FaClipboardCheck className="icon mr-2" />
              <span className="sidebar-text">Present</span>
            </li>
            <li onClick={() => handleNavigation('/HR/SpecialClasses')} className="cursor-pointer flex items-center">
              <FaTasks className="icon mr-2" />
              <span className="sidebar-text">Special Classes</span>
            </li>
            <li onClick={() => handleNavigation('/HR/Schedule')} className="cursor-pointer flex items-center">
              <FaCalendarAlt className="icon mr-2" />
              <span className="sidebar-text">Schedule</span>
            </li>
            <li onClick={() => handleNavigation('/HR/Assessment')} className="cursor-pointer flex items-center">
              <FaClipboard className="icon mr-2" />
              <span className="sidebar-text">Assessment</span>
            </li>
            <li onClick={() => handleNavigation('/HR/Join')} className="cursor-pointer flex items-center">
              <FaGraduationCap className="icon mr-2" />
              <span className="sidebar-text">Join</span>
            </li>
          </ul>
        )}

      
        <li onClick={() => handleNavigation('/LibraryTeachers')} className="cursor-pointer flex items-center">
          <FaBook className="icon mr-2" />
          <span className="sidebar-text">Library</span>
        </li>
      
        <li onClick={() => handleNavigation('/calendar')} className="cursor-pointer flex items-center">
          <FaCalendarAlt className="icon mr-2" />
          <span className="sidebar-text">Calendar</span>
        </li>
     
        <li onClick={() => handleNavigation('/ExamTeachers')} className="cursor-pointer flex items-center">
          <FaGraduationCap className="icon mr-2" />
          <span className="sidebar-text">Exam</span>
        </li>
      </ul>
    </div>
  );
}

export default SidebarTeacher;
