'use client'

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { format } from 'date-fns'
import { toast, Toaster } from 'react-hot-toast'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { UserPlus, Search, Edit, Trash2, Download, ChevronDown, ChevronUp, Camera, X } from 'lucide-react'
import { initialAdmins } from './initialData'
import ImageUpload from './ImageUpload'

const roles = ['Super Admin', 'Admin', 'Manager', 'Coordinator']
const departments = ['Administration', 'Academic Affairs', 'Finance', 'HR', 'IT']
const statuses = ['Active', 'Inactive', 'Pending']

export default function AdminRegistration() {
  const [admins, setAdmins] = useState(initialAdmins)
  const [searchTerm, setSearchTerm] = useState('')
  const [filterRole, setFilterRole] = useState('All')
  const [filterStatus, setFilterStatus] = useState('All')
  const [filterDepartment, setFilterDepartment] = useState('All')
  const [sortConfig, setSortConfig] = useState({ key: 'joinDate', direction: 'desc' })
  const [showModal, setShowModal] = useState(false)
  const [currentAdmin, setCurrentAdmin] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [adminToDelete, setAdminToDelete] = useState(null)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [showImageCrop, setShowImageCrop] = useState(false)
  const [tempImage, setTempImage] = useState(null)

  const filteredAdmins = admins.filter((admin) => {
    const matchesSearch =
      admin.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      admin.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      admin.id.toLowerCase().includes(searchTerm.toLowerCase())
    const matchesRole = filterRole === 'All' || admin.role === filterRole
    const matchesStatus = filterStatus === 'All' || admin.status === filterStatus
    const matchesDepartment = filterDepartment === 'All' || admin.department === filterDepartment
    return matchesSearch && matchesRole && matchesStatus && matchesDepartment
  })

  const sortedAdmins = [...filteredAdmins].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1
    }
    return 0
  })

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const handleAddAdmin = () => {
    const newAdmin = {
      id: `ADM${(admins.length + 1).toString().padStart(3, '0')}`,
      name: '',
      email: '',
      phone: '',
      role: 'Admin',
      department: '',
      joinDate: format(new Date(), 'yyyy-MM-dd'),
      status: 'Pending',
      profileImage: null,
    }
    setCurrentAdmin(newAdmin)
    setShowModal(true)
  }

  const handleEditAdmin = (admin) => {
    setCurrentAdmin({ ...admin })
    setShowModal(true)
  }

  const handleDeleteAdmin = (admin) => {
    setAdminToDelete(admin)
    setShowDeleteModal(true)
  }

  const validateForm = () => {
    const newErrors = {}
    if (!currentAdmin.name) newErrors.name = 'Name is required'
    if (!currentAdmin.email) newErrors.email = 'Email is required'
    if (!currentAdmin.phone) newErrors.phone = 'Phone is required'
    if (!currentAdmin.role) newErrors.role = 'Role is required'
    if (!currentAdmin.department) newErrors.department = 'Department is required'
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSaveAdmin = () => {
    if (!validateForm()) return
    
    setIsLoading(true)
    
    // Simulate API call
    setTimeout(() => {
      const updatedAdmins = currentAdmin.id
        ? admins.map((admin) => (admin.id === currentAdmin.id ? currentAdmin : admin))
        : [...admins, currentAdmin]
      
      setAdmins(updatedAdmins)
      setShowModal(false)
      setIsLoading(false)
      toast.success(currentAdmin.id ? 'Admin updated successfully' : 'Admin added successfully')
    }, 1000)
  }

  const confirmDeleteAdmin = () => {
    setAdmins(admins.filter((admin) => admin.id !== adminToDelete.id))
    setShowDeleteModal(false)
    toast.success('Admin deleted successfully')
  }

  const handleImageUpload = (e) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (event) => {
        setTempImage(event.target.result)
        setShowImageCrop(true)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleCroppedImage = (croppedImage) => {
    setCurrentAdmin({ ...currentAdmin, profileImage: croppedImage })
    setShowImageCrop(false)
    setTempImage(null)
  }

  const generatePDF = () => {
    const doc = new jsPDF()
    doc.text('Admin List', 20, 10)
    const tableColumn = ['ID', 'Name', 'Email', 'Role', 'Department', 'Status']
    const tableRows = sortedAdmins.map((admin) => [
      admin.id,
      admin.name,
      admin.email,
      admin.role,
      admin.department,
      admin.status,
    ])
    doc.autoTable(tableColumn, tableRows, { startY: 20 })
    doc.save('admin_list.pdf')
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Toaster position="top-center" reverseOrder={false} />
      
      {/* Header */}
      <div className="bg-gradient-to-r from-blue-600 via-purple-600 to-purple-700">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h1 className="text-xl sm:text-4xl text-center sm:text-left font-bold text-white mb-2">Admin Management</h1>
          <p className="text-sm sm:text-base text-blue-100">Manage and oversee administrative accounts</p>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-white rounded-2xl shadow-sm p-4 sm:p-6">
          {/* Search and Filters */}
          <div className="flex flex-col md:flex-row gap-4 mb-8">
            <div className="relative flex-1">
              <input
                type="text"
                placeholder="Search admins..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full !pl-10 !mb-0 pr-4 py-2 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
              <select
                value={filterRole}
                onChange={(e) => setFilterRole(e.target.value)}
                className="px-4 py-2 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="All">All Roles</option>
                {roles.map((role) => (
                  <option key={role} value={role}>{role}</option>
                ))}
              </select>
              
              <select
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
                className="px-4 py-2 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="All">All Statuses</option>
                {statuses.map((status) => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
              
              <select
                value={filterDepartment}
                onChange={(e) => setFilterDepartment(e.target.value)}
                className="px-4 py-2 rounded-xl border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="All">All Departments</option>
                {departments.map((dept) => (
                  <option key={dept} value={dept}>{dept}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Admin List */}
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {['Name', 'Email', 'Role', 'Department', 'Join Date', 'Status', 'Actions'].map((header) => (
                    <th
                      key={header}
                      onClick={() => handleSort(header.toLowerCase())}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    >
                      <div className="flex items-center">
                        {header}
                        {sortConfig.key === header.toLowerCase() && (
                          sortConfig.direction === 'asc' ? (
                            <ChevronUp className="w-4 h-4 ml-1" />
                          ) : (
                            <ChevronDown className="w-4 h-4 ml-1" />
                          )
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {sortedAdmins.map((admin) => (
                  <motion.tr
                    key={admin.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="h-10 w-10 flex-shrink-0">
                          {admin.profileImage ? (
                            <img
                              className="h-10 w-10 rounded-full object-cover"
                              src={admin.profileImage}
                              alt={admin.name}
                            />
                          ) : (
                            <div className="h-10 w-10 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-white font-semibold">
                              {admin.name.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{admin.name}</div>
                          <div className="text-sm text-gray-500">{admin.id}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{admin.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{admin.role}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{admin.department}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {format(new Date(admin.joinDate), 'dd MMM yyyy')}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          admin.status === 'Active'
                            ? 'bg-green-100 text-green-800'
                            : admin.status === 'Inactive'
                            ? 'bg-red-100 text-red-800'
                            : 'bg-yellow-100 text-yellow-800'
                        }`}
                      >
                        {admin.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleEditAdmin(admin)}
                        className="text-blue-600 hover:text-blue-900 mr-3"
                      >
                        <Edit className="w-5 h-5" />
                      </button>
                      <button
                        onClick={() => handleDeleteAdmin(admin)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="w-5 h-5" />
                      </button>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Action Buttons */}
          <div className="mt-6 flex justify-between">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleAddAdmin}
              className="sm:text-base text-xs text-nowrap inline-flex items-center px-4 py-2 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-xl hover:from-blue-700 hover:to-purple-700"
            >
              <UserPlus className="w-5 h-5 mr-2" />
              Add Admin
            </motion.button>
            
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={generatePDF}
              className="sm:text-base text-xs text-nowrap inline-flex items-center px-4 py-2 bg-gray-800 text-white rounded-xl hover:bg-gray-900"
            >
              <Download className="w-5 h-5 mr-2" />
              Export PDF
            </motion.button>
          </div>
        </div>
      </div>

      {/* Add/Edit Modal */}
      <AnimatePresence>
        {showModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-2xl p-6 w-full max-w-md max-h-[90vh] overflow-y-auto"
            >
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold">
                  {currentAdmin.id ? 'Edit Admin' : 'Add Admin'}
                </h2>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>

              <form onSubmit={(e) => { e.preventDefault(); handleSaveAdmin() }}>
                <div className="space-y-6">
                  {/* Profile Image */}
                  <div className="flex items-center space-x-4">
                    <div className="relative w-24 h-24">
                      <div className="w-24 h-24 rounded-2xl overflow-hidden bg-gradient-to-r from-blue-100 to-purple-100 flex items-center justify-center group">
                        {currentAdmin.profileImage ? (
                          <img
                            src={currentAdmin.profileImage}
                            alt="Profile"
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <Camera className="w-8 h-8 text-gray-400" />
                        )}
                        <label className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-all cursor-pointer">
                          <input
                            type="file"
                            className="hidden"
                            accept="image/*"
                            onChange={handleImageUpload}
                          />
                          <Camera className="w-6 h-6 text-white opacity-0 group-hover:opacity-100 transition-opacity" />
                        </label>
                      </div>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Profile Image
                      </label>
                      <p className="text-sm text-gray-500">
                        Upload a profile picture
                      </p>
                    </div>
                  </div>

                  {/* Form Fields */}
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Name
                      </label>
                      <input
                        type="text"
                        value={currentAdmin.name}
                        onChange={(e) =>
                          setCurrentAdmin({ ...currentAdmin, name: e.target.value })
                        }
                        className="mt-1 block w-full rounded-xl border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                      {errors.name && (
                        <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Email
                      </label>
                      <input
                        type="email"
                        value={currentAdmin.email}
                        onChange={(e) =>
                          setCurrentAdmin({ ...currentAdmin, email: e.target.value })
                        }
                        className="mt-1 block w-full rounded-xl border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                      {errors.email && (
                        <p className="mt-1 text-sm text-red-600">{errors.email}</p>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Phone
                      </label>
                      <input
                        type="tel"
                        value={currentAdmin.phone}
                        onChange={(e) =>
                          setCurrentAdmin({ ...currentAdmin, phone: e.target.value })
                        }
                        className="mt-1 block w-full rounded-xl border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                      {errors.phone && (
                        <p className="mt-1 text-sm text-red-600">{errors.phone}</p>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Role
                      </label>
                      <select
                        value={currentAdmin.role}
                        onChange={(e) =>
                          setCurrentAdmin({ ...currentAdmin, role: e.target.value })
                        }
                        className="mt-1 block w-full rounded-xl border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        {roles.map((role) => (
                          <option key={role} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Department
                      </label>
                      <select
                        value={currentAdmin.department}
                        onChange={(e) =>
                          setCurrentAdmin({ ...currentAdmin, department: e.target.value })
                        }
                        className="mt-1 block w-full rounded-xl border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option value="">Select Department</option>
                        {departments.map((dept) => (
                          <option key={dept} value={dept}>
                            {dept}
                          </option>
                        ))}
                      </select>
                      {errors.department && (
                        <p className="mt-1 text-sm text-red-600">{errors.department}</p>
                      )}
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Status
                      </label>
                      <select
                        value={currentAdmin.status}
                        onChange={(e) =>
                          setCurrentAdmin({ ...currentAdmin, status: e.target.value })
                        }
                        className="mt-1 block w-full rounded-xl border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      >
                        {statuses.map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Join Date
                      </label>
                      <input
                        type="date"
                        value={currentAdmin.joinDate}
                        onChange={(e) =>
                          setCurrentAdmin({ ...currentAdmin, joinDate: e.target.value })
                        }
                        className="mt-1 block w-full rounded-xl border-gray-200 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-purple-600 rounded-xl hover:from-blue-700 hover:to-purple-700"
                  >
                    {isLoading ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </form>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Delete Confirmation Modal */}
      <AnimatePresence>
        {showDeleteModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-2xl p-6 w-full max-w-sm"
            >
              <h3 className="text-lg font-medium text-gray-900 mb-3">
                Confirm Delete
              </h3>
              <p className="text-sm text-gray-500 mb-4">
                Are you sure you want to delete {adminToDelete?.name}? This action cannot be undone.
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowDeleteModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-xl hover:bg-gray-200"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmDeleteAdmin}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-xl hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Image Crop Modal */}
      <AnimatePresence>
        {showImageCrop && (
          <ImageUpload
            image={tempImage}
            onCrop={handleCroppedImage}
            onCancel={() => {
              setShowImageCrop(false)
              setTempImage(null)
            }}
          />
        )}
      </AnimatePresence>
    </div>
  )
}