/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  LayoutDashboard,
  UserPlus,
  Users,
  School,
  BookOpen,
  Calendar,
  ClipboardList,
  ClipboardCheck,
  GraduationCap,
  DollarSign,
  ChevronDown,
  Menu,
  ChevronLeft,
  CreditCard,
  Presentation,
  Building2,
  CalendarDays,
  Library,
  MonitorPlay,
  Home,
  UserCheck,
} from "lucide-react";

const MENU_ITEMS = [
  { icon: LayoutDashboard, title: "Dashboard", path: "/dashboard" },
  {
    icon: UserPlus,
    title: "Registration",
    path: "/registration",
    subItems: [
      { title: "Teacher Registration", path: "/TeacherRegistration" },
      { title: "Student Registration", path: "/StudentRegistration" },
    ],
  },
  { icon: Users, title: "Students", path: "/StudentList" },
  { icon: School, title: "Teachers", path: "/TeacherList" },
  { icon: BookOpen, title: "Admission", path: "/AdmissionDetails" },
  { icon: Calendar, title: "Schedule", path: "/schedule" },
  {
    icon: ClipboardList,
    title: "Attendance",
    path: "/attendance",
    subItems: [
      { title: "Student", path: "/StudentAttendance" },
      { title: "Teacher", path: "/TeacherAttendance" },
    ],
  },
  { icon: ClipboardCheck, title: "Assignment", path: "/assignments" },
  { icon: CalendarDays, title: "TimeTable", path: "/timetable" },
  { icon: BookOpen, title: "Syllabus", path: "/syllabus" },
  { icon: Presentation, title: "Special Class", path: "/special-classes" },
  { icon: CreditCard, title: "Accounts", path: "/accounts" },
  { icon: Library, title: "Library", path: "/library" },
  { icon: GraduationCap, title: "Exam", path: "/AllExamLists" },
  { icon: BookOpen, title: "Courses", path: "/courses" },
  { icon: Building2, title: "Stock", path: "/stock" },
  { icon: MonitorPlay, title: "Activities", path: "/extra-activities" },
  { icon: DollarSign, title: "Fees", path: "/AllFeesLists" },
  { icon: UserCheck, title: "Admin Registration", path: "/admin-registration" },
];

const SidebarToggleButton = ({ onClick, isExpanded }) => (
  <motion.button
    onClick={onClick}
    className="absolute -right-3 top-6 bg-white border rounded-full p-1.5 z-[1] hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
  >
    <ChevronLeft
      className={`w-4 h-4 text-gray-600 transition-transform duration-300 ${
        !isExpanded ? "rotate-180" : ""
      }`}
    />
  </motion.button>
);

const SidebarHeader = ({ isExpanded, toggleSidebar }) => (
  <div
    className={`flex items-center h-16 px-4 border-b flex-shrink-0 ${
      isExpanded ? "" : "justify-center"
    }`}
  >
    {isExpanded ? (
      <Link to="/" className="group flex items-center gap-2">
        <Home className="w-5 h-5 text-gray-600 hover:text-gray-800 group-hover:scale-110 transition-transform" />
        <h2 className="text-lg font-bold">Admin Dashboard</h2>
      </Link>
    ) : (
      <button
        onClick={toggleSidebar}
        className="p-2 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
      >
        <Menu className="w-6 h-6 text-gray-600" />
      </button>
    )}
  </div>
);

const MenuItemComponent = ({
  icon: Icon,
  title,
  path,
  subItems,
  isExpanded,
  onLinkClick,
}) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const location = useLocation();
  const isActive = location.pathname === path;

  const handleClick = (e) => {
    if (subItems) {
      e.preventDefault();
      setIsSubMenuOpen((prev) => !prev);
    } else {
      onLinkClick();
    }
  };

  return (
    <div className="relative group">
      <motion.div
        className={`flex items-center py-2.5 rounded-lg cursor-pointer transition-all duration-200 ${
          isActive
            ? "bg-indigo-50 text-indigo-600"
            : "text-gray-600 hover:bg-gray-50"
        } ${!isExpanded ? "justify-center px-2 sm:px-1" : "justify-between px-3"}`}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
      >
        <Link
          to={path}
          className="flex items-center gap-3 w-full"
          onClick={handleClick}
        >
          <Icon
            className={`w-5 h-5 ${
              isActive ? "text-indigo-600" : "text-gray-500"
            }`}
          />
          {isExpanded && <span className="text-sm font-medium">{title}</span>}
        </Link>
        {isExpanded && subItems && (
          <ChevronDown
            className={`w-4 h-4 transition-transform duration-200 ${
              isSubMenuOpen ? "rotate-180" : ""
            }`}
          />
        )}
      </motion.div>

      <AnimatePresence>
        {!isExpanded && (
          <motion.div
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -10 }}
            className="absolute left-full top-0 ml-2 p-2 bg-gray-800 text-white text-sm rounded opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 z-50 whitespace-nowrap"
          >
            {title}
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isExpanded && isSubMenuOpen && subItems && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            className="ml-7 mt-1 space-y-1 overflow-hidden"
          >
            {subItems.map((subItem, index) => (
              <motion.div
                key={index}
                className={`px-3 py-2 text-sm rounded-lg cursor-pointer transition-colors duration-200 ${
                  location.pathname === subItem.path
                    ? "bg-indigo-50 text-indigo-600"
                    : "text-gray-600 hover:bg-gray-50"
                }`}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <Link
                  to={subItem.path}
                  className="block w-full"
                  onClick={onLinkClick}
                >
                  {subItem.title}
                </Link>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 768);
      if (width < 768) {
        setIsExpanded(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleLinkClick = (path) => {
    setIsExpanded(false);
    navigate(path);
  };

  return (
    <>
      <motion.div
        className="fixed top-0 left-0 h-screen z-40"
        initial={false}
        animate={{ width: isExpanded ? "18rem" : "4rem" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="relative h-full bg-white border-r flex flex-col shadow-lg">
          <SidebarToggleButton
            onClick={toggleSidebar}
            isExpanded={isExpanded}
          />
          <SidebarHeader
            isExpanded={isExpanded}
            toggleSidebar={toggleSidebar}
          />

          <motion.div
            className="flex-1 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-200 hover:scrollbar-thumb-gray-300"
            initial={false}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className="p-3 space-y-1">
              {MENU_ITEMS.map((item, index) => (
                <MenuItemComponent
                  key={index}
                  {...item}
                  isExpanded={isExpanded}
                  onLinkClick={() => handleLinkClick(item.path)}
                />
              ))}
            </div>
          </motion.div>
        </div>
      </motion.div>

      {isMobile && isExpanded && (
        <motion.div
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-30"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          onClick={toggleSidebar}
        />
      )}
    </>
  );
}
