"use client";

import React, { useState, useEffect } from "react";
import {
  Search,
  Plus,
  Edit,
  Trash2,
  ChevronDown,
  ChevronUp,
  X,
  Calendar,
  Clock,
  Users,
  BookOpen,
  MoreVertical,
} from "lucide-react";

// Sample course data (unchanged)
const initialCourses = [
  {
    id: "C001",
    name: "Advanced Mathematics",
    code: "MATH401",
    instructor: "Dr. Alan Turing",
    department: "Mathematics",
    credits: 4,
    capacity: 30,
    enrolled: 28,
    schedule: "MWF 10:00 AM - 11:30 AM",
    room: "Science Building 301",
    description:
      "An in-depth study of advanced mathematical concepts including complex analysis and abstract algebra.",
    prerequisites: ["MATH301", "MATH302"],
    status: "Active",
  },
  {
    id: "C002",
    name: "Introduction to Artificial Intelligence",
    code: "CS301",
    instructor: "Prof. Ada Lovelace",
    department: "Computer Science",
    credits: 3,
    capacity: 50,
    enrolled: 48,
    schedule: "TTh 2:00 PM - 3:30 PM",
    room: "Tech Center 105",
    description:
      "Fundamental concepts and techniques in AI, including search algorithms, knowledge representation, and machine learning.",
    prerequisites: ["CS201", "MATH201"],
    status: "Active",
  },
  {
    id: "C003",
    name: "World Literature",
    code: "LIT202",
    instructor: "Dr. Jane Austen",
    department: "Literature",
    credits: 3,
    capacity: 40,
    enrolled: 35,
    schedule: "MWF 1:00 PM - 2:00 PM",
    room: "Humanities Hall 201",
    description:
      "A survey of major works of world literature from ancient times to the present.",
    prerequisites: ["LIT101"],
    status: "Active",
  },
];

const CoursesPanel = () => {
  const [courses, setCourses] = useState(initialCourses);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("All");
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "ascending",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState("view");
  const [selectedCourse, setSelectedCourse] = useState(null);

  const departments = [
    "All",
    ...new Set(courses.map((course) => course.department)),
  ];

  const filteredCourses = courses.filter((course) => {
    const matchesSearch =
      course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      course.code.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDepartment =
      selectedDepartment === "All" || course.department === selectedDepartment;
    return matchesSearch && matchesDepartment;
  });

  const sortedCourses = [...filteredCourses].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === "ascending" ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "ascending"
          ? "descending"
          : "ascending",
    }));
  };

  const handleCreateCourse = () => {
    setModalMode("create");
    setSelectedCourse({
      id: `C${courses.length + 1}`.padStart(4, "0"),
      name: "",
      code: "",
      instructor: "",
      department: "",
      credits: 0,
      capacity: 0,
      enrolled: 0,
      schedule: "",
      room: "",
      description: "",
      prerequisites: [],
      status: "Active",
    });
    setShowModal(true);
  };

  const handleEditCourse = (course) => {
    setModalMode("edit");
    setSelectedCourse(course);
    setShowModal(true);
  };

  const handleDeleteCourse = (id) => {
    setCourses(courses.filter((course) => course.id !== id));
  };

  const handleSaveCourse = (updatedCourse) => {
    if (modalMode === "create") {
      setCourses([...courses, updatedCourse]);
    } else {
      setCourses(
        courses.map((course) =>
          course.id === updatedCourse.id ? updatedCourse : course
        )
      );
    }
    setShowModal(false);
  };

  return (
    <div className="min-h-screen p-4 md:p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-6">
          <div className="bg-gradient-to-r from-purple-600 to-indigo-600 p-6">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <div className="text-white">
                <h1 className="text-2xl md:text-3xl font-bold">
                  Course Management
                </h1>
                <p className="mt-1 text-purple-100">
                  Manage and organize academic courses
                </p>
              </div>

              <div className="flex flex-col md:flex-row items-center gap-4 w-full md:w-auto">
                <div className="relative w-full md:w-64">
                  <input
                    type="text"
                    placeholder="Search courses..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full !pl-10 !mb-0 pr-4 py-2 rounded-lg bg-white/10 text-white placeholder-purple-200 border border-purple-400 focus:outline-none focus:ring-2 focus:ring-white"
                  />
                  <Search className="absolute left-3 top-2.5 h-5 w-5 text-purple-200" />
                </div>

                <select
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                  className="w-full md:w-auto px-4 py-2 rounded-lg bg-white/10 text-white border border-purple-400 focus:outline-none focus:ring-2 focus:ring-white"
                >
                  {departments.map((dept) => (
                    <option key={dept} value={dept} className="text-gray-900">
                      {dept}
                    </option>
                  ))}
                </select>

                <button
                  onClick={handleCreateCourse}
                  className="w-full md:w-auto px-4 py-2 bg-white text-purple-600 rounded-lg hover:bg-purple-50 transition-colors flex items-center justify-center"
                >
                  <Plus className="w-5 h-5 mr-2" />
                  Add Course
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Course List */}
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50">
                <tr>
                  {[
                    "Name",
                    "Code",
                    "Instructor",
                    "Department",
                    "Enrolled",
                    "Status",
                    "Actions",
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium bg-gray-50 text-gray-500 uppercase tracking-wider cursor-pointer"
                      onClick={() => handleSort(header.toLowerCase())}
                    >
                      <div className="flex items-center">
                        {header}
                        {sortConfig.key === header.toLowerCase() &&
                          (sortConfig.direction === "ascending" ? (
                            <ChevronUp className="w-4 h-4 ml-1" />
                          ) : (
                            <ChevronDown className="w-4 h-4 ml-1" />
                          ))}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {sortedCourses.map((course) => (
                  <tr key={course.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {course.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">{course.code}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {course.instructor}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {course.department}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {course.enrolled}/{course.capacity}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          course.status === "Active"
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {course.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button
                        onClick={() => {
                          setModalMode("view");
                          setSelectedCourse(course);
                          setShowModal(true);
                        }}
                        className="text-indigo-600 hover:text-indigo-900 mr-4"
                      >
                        View
                      </button>
                      <button
                        onClick={() => handleEditCourse(course)}
                        className="text-blue-600 hover:text-blue-900 mr-4"
                      >
                        <Edit className="w-4 h-4" />
                      </button>
                      <button
                        onClick={() => handleDeleteCourse(course.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Course Modal */}
        {showModal && selectedCourse && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto">
              <div className="p-6 space-y-6">
                <div className="flex justify-between items-start">
                  <h3 className="text-xl font-bold text-gray-900">
                    {modalMode === "create"
                      ? "Create New Course"
                      : modalMode === "edit"
                      ? "Edit Course"
                      : selectedCourse.name}
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>

                {modalMode === "view" ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="space-y-4">
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Course Code
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedCourse.code}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Instructor
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedCourse.instructor}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Department
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedCourse.department}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Credits
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedCourse.credits}
                        </p>
                      </div>
                    </div>
                    <div className="space-y-4">
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Schedule
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedCourse.schedule}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Room
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedCourse.room}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Enrollment
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedCourse.enrolled}/{selectedCourse.capacity}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-medium text-gray-500">
                          Status
                        </p>
                        <p className="mt-1 text-sm text-gray-900">
                          {selectedCourse.status}
                        </p>
                      </div>
                    </div>
                    <div className="md:col-span-2">
                      <p className="text-sm font-medium text-gray-500">
                        Description
                      </p>
                      <p className="mt-1 text-sm text-gray-900">
                        {selectedCourse.description}
                      </p>
                    </div>
                    <div className="md:col-span-2">
                      <p className="text-sm font-medium text-gray-500">
                        Prerequisites
                      </p>
                      <p className="mt-1 text-sm text-gray-900">
                        {selectedCourse.prerequisites.join(", ")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSaveCourse(selectedCourse);
                    }}
                    className="space-y-4"
                  >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Course Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          value={selectedCourse.name}
                          onChange={(e) =>
                            setSelectedCourse({
                              ...selectedCourse,
                              name: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Course Code
                        </label>
                        <input
                          type="text"
                          id="code"
                          value={selectedCourse.code}
                          onChange={(e) =>
                            setSelectedCourse({
                              ...selectedCourse,
                              code: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="instructor"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Instructor
                        </label>
                        <input
                          type="text"
                          id="instructor"
                          value={selectedCourse.instructor}
                          onChange={(e) =>
                            setSelectedCourse({
                              ...selectedCourse,
                              instructor: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="department"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Department
                        </label>
                        <input
                          type="text"
                          id="department"
                          value={selectedCourse.department}
                          onChange={(e) =>
                            setSelectedCourse({
                              ...selectedCourse,
                              department: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div>
                        <label
                          htmlFor="credits"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Credits
                        </label>
                        <input
                          type="number"
                          id="credits"
                          value={selectedCourse.credits}
                          onChange={(e) =>
                            setSelectedCourse({
                              ...selectedCourse,
                              credits: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="capacity"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Capacity
                        </label>
                        <input
                          type="number"
                          id="capacity"
                          value={selectedCourse.capacity}
                          onChange={(e) =>
                            setSelectedCourse({
                              ...selectedCourse,
                              capacity: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="enrolled"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Enrolled
                        </label>
                        <input
                          type="number"
                          id="enrolled"
                          value={selectedCourse.enrolled}
                          onChange={(e) =>
                            setSelectedCourse({
                              ...selectedCourse,
                              enrolled: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="schedule"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Schedule
                      </label>
                      <input
                        type="text"
                        id="schedule"
                        value={selectedCourse.schedule}
                        onChange={(e) =>
                          setSelectedCourse({
                            ...selectedCourse,
                            schedule: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="room"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Room
                      </label>
                      <input
                        type="text"
                        id="room"
                        value={selectedCourse.room}
                        onChange={(e) =>
                          setSelectedCourse({
                            ...selectedCourse,
                            room: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <textarea
                        id="description"
                        rows={3}
                        value={selectedCourse.description}
                        onChange={(e) =>
                          setSelectedCourse({
                            ...selectedCourse,
                            description: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="prerequisites"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prerequisites (comma-separated)
                      </label>
                      <input
                        type="text"
                        id="prerequisites"
                        value={selectedCourse.prerequisites.join(", ")}
                        onChange={(e) =>
                          setSelectedCourse({
                            ...selectedCourse,
                            prerequisites: e.target.value
                              .split(",")
                              .map((item) => item.trim()),
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="status"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Status
                      </label>
                      <select
                        id="status"
                        value={selectedCourse.status}
                        onChange={(e) =>
                          setSelectedCourse({
                            ...selectedCourse,
                            status: e.target.value,
                          })
                        }
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </select>
                    </div>
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        onClick={() => setShowModal(false)}
                        className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        {modalMode === "create"
                          ? "Create Course"
                          : "Save Changes"}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CoursesPanel;
