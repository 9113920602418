import SidebarStudent from "../dashboard/Student/SidebarStudent";
import TimeTableStudents from "../dashboard/Student/StudentDashbord/TimeTableStudents";
import ReportStudents from "../dashboard/Student/StudentDashbord/ReportStudents";
import AttendanceStudents from "../dashboard/Student/StudentDashbord/AttendanceStudents";
import FeesStudents from "../dashboard/Student/StudentDashbord/FeesStudents";
import LibarryStudents from "../dashboard/Student/StudentDashbord/LibarryStudents";
import ExamStudents from "../dashboard/Student/StudentDashbord/ExamStudents";
import ProfileStudents from "../dashboard/Student/StudentDashbord/ProfileStudents";
import ResultStudents from "../dashboard/Student/StudentDashbord/ResultStudents";
import TransportStudents from "../dashboard/Student/StudentDashbord/TransportStudents";
import SyllabusStudents from "../dashboard/Student/StudentDashbord/SyllabusStudents";
import AssignmentStudents from "../dashboard/Student/StudentDashbord/AssignmentStudents";
import SportStudents from "../dashboard/Student/StudentDashbord/SportStudents";

const StudentLayout = ({ children }) => (
  <div className="flex">
    <SidebarStudent />
    <div className="ml-59 p-9 w-full">{children}</div>
  </div>
);

export const studentRoutes = [
  {
    path: "/login/student/student-dashboard",
    element: (
      <StudentLayout>
        <ProfileStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/AttendanceStudents",
    element: (
      <StudentLayout>
        <AttendanceStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/TimeTableStudents",
    element: (
      <StudentLayout>
        <TimeTableStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/FeesStudents",
    element: (
      <StudentLayout>
        <FeesStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/ReportStudents",
    element: (
      <StudentLayout>
        <ReportStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/TransportStudents",
    element: (
      <StudentLayout>
        <TransportStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/ResultStudents",
    element: (
      <StudentLayout>
        <ResultStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/syllabus",
    element: (
      <StudentLayout>
        <SyllabusStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/LibarryStudents",
    element: (
      <StudentLayout>
        <LibarryStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/Assignments",
    element: (
      <StudentLayout>
        <AssignmentStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/SportStudents",
    element: (
      <StudentLayout>
        <SportStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/Studentexam",
    element: (
      <StudentLayout>
        <ExamStudents />
      </StudentLayout>
    ),
  },
  {
    path: "/ProfileStudents",
    element: (
      <StudentLayout>
        <ProfileStudents />
      </StudentLayout>
    ),
  },
];