import React, { useState } from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  useBreakpointValue,
  useColorModeValue,
  Badge,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Select,
} from '@chakra-ui/react';

const transportData = [
  {
    routeNo: 'B1',
    busName: 'Bus A',
    arrivalTime: '7:30 AM',
    departureTime: '7:45 AM',
  },
  {
    routeNo: 'B2',
    busName: 'Bus B',
    arrivalTime: '8:00 AM',
    departureTime: '8:15 AM',
  },
  {
    routeNo: 'B3',
    busName: 'Bus C',
    arrivalTime: '8:30 AM',
    departureTime: '8:45 AM',
  },
  {
    routeNo: 'B4',
    busName: 'Bus D',
    arrivalTime: '9:00 AM',
    departureTime: '9:15 AM',
  },
];

const TransportPage = () => {
  const [selectedRoute, setSelectedRoute] = useState('');
  const headingSize = useBreakpointValue({ base: 'lg', md: 'xl' });

  const handleRouteChange = (e) => {
    setSelectedRoute(e.target.value);
  };

  const tableBgColor = useColorModeValue('gray.100', 'gray.700');
  const headerColor = useColorModeValue('teal.600', 'teal.300');
  
  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={5} align="stretch">
        <Heading size={headingSize} textAlign="center" color={headerColor}>
          Student Transport Schedule
        </Heading>

        {/* Filter by Route */}
        <Box
          borderWidth="1px"
          borderRadius="lg"
          p={5}
          bg={useColorModeValue('white', 'gray.800')}
          boxShadow="md"
        >
          <Select
            value={selectedRoute}
            onChange={handleRouteChange}
            mb={4}
            colorScheme="teal"
            variant="outline"
          >
            <option value="">Select Bus Route</option>
            {transportData.map((bus, index) => (
              <option key={index} value={bus.routeNo}>
                {bus.routeNo} - {bus.busName}
              </option>
            ))}
          </Select>

          {/* Table of Timetable */}
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th color={headerColor}>Route No.</Th>
                <Th color={headerColor}>Bus Name</Th>
                <Th color={headerColor}>Arrival Time</Th>
                <Th color={headerColor}>Departure Time</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transportData
                .filter((bus) =>
                  selectedRoute ? bus.routeNo === selectedRoute : true
                )
                .map((bus, index) => (
                  <Tr key={index}>
                    <Td>{bus.routeNo}</Td>
                    <Td>{bus.busName}</Td>
                    <Td>{bus.arrivalTime}</Td>
                    <Td>{bus.departureTime}</Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Box>
      </VStack>
    </Container>
  );
};

export default TransportPage;
