import React from 'react';
import "../../cssTeacher/feesOfteachers.css" 

function OfflineFees() {
  return (
    <div className="offline-fees-container">
      <h2 className="page-heading">Offline Fees Payment</h2>
      <p className="offline-message">
        Please visit the school office with the necessary documents for offline fee payment.
      </p>
    </div>
  );
}

export default OfflineFees;
