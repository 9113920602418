import React from "react";
import { useLocation } from "react-router-dom";
import Header from "../Components/Navbar";
import Footer from "../Components/Footer";

const DASHBOARD_PATHS = [
  // Admin Dashboard Routes
  "/dashboard",
  "/Teacher",
  "/Student",
  "/AllExamLists",
  "/AllFeesLists",
  "/assignments",
  "/AdmissionDetails",
  "/schedule",
  "/timetable",
  "/syllabus",
  "/library",
  "/courses",
  "/stock",
  "/accounts",
  "/special-classes",
  "/extra-activities",
  "/admin-registration",

  // Teacher Dashboard Routes
  "/teachersdashboard",
  "/ProfileTeacher",
  "/SpecialClassofTeachers",
  "/AssinmentsTeacher",
  "/AttendanceTeacher",
  "/ExamTeachers",
  "/ScheduleTeachers",
  "/LibraryTeachers",
  "/ActivitiofTeachers",
  "/AccountTeachers",
  "/Fees/online",
  "/Fees/offline",
  "/Reportsofteachers",
  "/StaffManagementforteachers",
  "/HR/Salary",
  "/HR/Leave",
  "/HR/Present",
  "/HR/SpecialClasses",
  "/HR/Assessment",
  "/HR/Join",
  "/HR/Schedule",
  "/chatbot",

  // Student Dashboard Routes
  "/StudentDashbord",
  "/ExamStudents",
  "/FeesStudents",
  "/ResultStudents",
  "/LibarryStudents",
  "/TransportStudents",
  "/SportStudents",
  "/AssignmentStudents",
  "/TimeTableStudents",
  "/AttendanceStudents",
  "/ReportStudents",
  "/ProfileStudents",
  "/studenttest",
];

export const MainLayout = ({ children }) => {
  const location = useLocation();

  const isDashboardRoute =
    DASHBOARD_PATHS.some((path) => location.pathname.startsWith(path)) ||
    location.pathname.startsWith("/dashboard") ||
    location.pathname.startsWith("/Teacher") ||
    location.pathname.startsWith("/Student");

  return (
    <div>
      {!isDashboardRoute && <Header />}
      {children}
      {!isDashboardRoute && <Footer />}
    </div>
  );
};
